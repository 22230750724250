import type { WebinarRecord } from '../types';
import michaelLedouxIllustration from '../assets/images/webinar/michael-ledoux.png';
import moundyrGainouIllustration from '../assets/images/webinar/moundyr-gainou.png';
import kotrynaMazeikyteIllustration from '../assets/images/webinar/kotryna-mazeikyte.png';

const WEBINARS: WebinarRecord = {
  france: {
    presentations: [
      {
        context: 'male',
        name: 'Michael Ledoux',
        illustration: michaelLedouxIllustration,
        title: 'Le fondateur de Transakauto',

        theme: 'Comment utiliser efficacement les réseaux sociaux pour stimuler les ventes ?',
      },
      {
        context: 'male',
        name: 'Moundyr Gainou',
        illustration: moundyrGainouIllustration,
        title: 'Directeur carVertical France',
        theme:
          'La situation du marché français des véhicules d’occasion et l’importance des données internationales pour les concessionnaires.',
      },
      {
        context: 'female',
        name: 'Kotryna Mažeikytė',
        illustration: kotrynaMazeikyteIllustration,
        title: 'Représentante commerciale France',
        theme:
          'Comment l’historique d’une voiture contribue-t-il à sécuriser les ventes ? Cas concrets en France.',
      },
    ],
    successTitle: 'Merci !',
    successDescription: 'Votre demande a été enregistrée avec succès.',
  },
};

export { WEBINARS };
