import { WHITESPACE_REGEXP } from '../constants';

const formatNumber = (number: number, locale: string) => {
  return new Intl.NumberFormat(locale).format(number);
};

const trimWhitespaces = (value?: string) => {
  return value?.replace(WHITESPACE_REGEXP, '');
};

export { formatNumber, trimWhitespaces };
