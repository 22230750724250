import {
  Stack,
  StatusMessage,
  Text,
  TextField,
  Button,
  type ButtonProps,
  TextFieldButton,
} from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';
import { MailIconXS } from '@carvertical/icons/react';
import useUserFields from 'components/CheckoutPage/hooks/useUserFields';
import { FormField } from 'components/common/ui/forms';
import AuthContainer from 'containers/AuthContainer';
import { useRouteData } from 'context/RouteDataProvider';
import withContainers from 'hoc/withContainers';
import { useEmailSubscription } from 'modules/subscription/hooks';
import type { SubscribeFormFields } from './schemas';
import styles from './SubscribeForm.module.scss';

type SubscribeFormProps = {
  containers: {
    auth: AuthContainer;
  };
  inverted?: boolean;
  className?: string;
};

const SubscribeForm = ({
  containers: { auth },
  className,
  inverted = false,
}: SubscribeFormProps) => {
  const { t } = useTranslation();
  const { locale, market } = useRouteData();
  const { subscribe, subscribing, subscribed, reset: resetSubscription } = useEmailSubscription();

  const {
    handleSubmit,
    trigger,
    formState: { errors },
    reset: resetForm,
  } = useFormContext<SubscribeFormFields>();

  const submit = ({ email }: SubscribeFormFields) =>
    subscribe({ email, locale, market: market.id, source: 'Footer Subscribe Form' });

  const {
    checkMisspelledEmail,
    checkingEmail,
    enteredInvalidEmail,
    emailSuggestionShown,
    emailSuggestion,
    acceptSuggestedEmail,
  } = useUserFields({ auth });

  const closeSuccessMessage = () => {
    resetForm();
    resetSubscription();
  };

  const disabled = subscribing || subscribed || checkingEmail;
  const emailError = errors.email?.message;

  const commonButtonProps: ButtonProps = {
    type: 'submit',
    variant: 'grey',
    disabled,
    loading: subscribing,
  };

  const commonButtonLabel = t(`subscribeForm.${disabled ? 'pendingMessage' : 'action'}`);

  const Field = FormField<SubscribeFormFields>;

  return (
    <Stack
      as="form"
      // @ts-expect-error TS(2322)
      noValidate
      crossAxisAlign="stretch"
      onSubmit={handleSubmit(submit)}
      className={className}
    >
      {subscribed ? (
        <StatusMessage
          closeButtonShown
          onCloseButtonClick={closeSuccessMessage}
          title={t('footer.subscribedNewsletterTitle')}
          variant="success"
        >
          {t('footer.subscribedNewsletterSubtitle')}
        </StatusMessage>
      ) : (
        <>
          <Field
            name="email"
            options={{
              onBlur: checkMisspelledEmail,
              ...(enteredInvalidEmail && {
                onChange: () => {
                  trigger('email');
                },
              }),
            }}
          >
            <TextField
              label={t('subscribeForm.placeholder')}
              labelHidden
              placeholder={t('footer.emailPlaceholder')}
              type="email"
              fullWidth
              disabled={disabled}
              inverted={inverted}
              leadingAddon={
                <MailIconXS
                  className={cn('translate-y-0.125 text-white', emailError && 'text-red')}
                />
              }
              trailingAddon={
                <TextFieldButton {...commonButtonProps} className="hideUntilTablet">
                  {commonButtonLabel}
                </TextFieldButton>
              }
            />
          </Field>

          <Button {...commonButtonProps} className="hideFromTablet">
            {commonButtonLabel}
          </Button>

          {emailSuggestionShown && (
            <Text variant="s" textColor="light">
              {t('emailForm.didYouMeanLabel')}{' '}
              <button
                className={styles.misspelledEmailButton}
                type="button"
                onClick={acceptSuggestedEmail}
              >
                {emailSuggestion}
              </button>
              ?
            </Text>
          )}
        </>
      )}
    </Stack>
  );
};

const WrappedSubscribeForm = withContainers({
  auth: AuthContainer,
})(SubscribeForm);

export { WrappedSubscribeForm as SubscribeForm };
export type { SubscribeFormProps };
