import type { OptionalMarketIdRecord } from 'types/market';
import type { Package, PackageId } from 'types/pricing';

const MULTI_PACKAGE_MAX_DISCOUNT = 50;

const PACKAGES_BY_SIZE: Record<number, PackageId> = {
  1: 'standard',
  2: 'double',
  3: 'special',
};

const STANDARD: PackageId = 'standard';
const BUSINESS: PackageId = 'bizmd';

const PACKAGE_ORDER: OptionalMarketIdRecord<Package[]> & { other: Package[] } = {
  romania: [{ id: 'special', preselected: true }, { id: 'standard' }, { id: 'double' }],
  hungary: [{ id: 'standard' }, { id: 'double', preselected: true }, { id: 'special' }],
  lithuania: [{ id: 'standard' }, { id: 'double', preselected: true }, { id: 'special' }],
  poland: [{ id: 'standard' }, { id: 'double', preselected: true }, { id: 'special' }],
  other: [{ id: 'double' }, { id: 'special' }, { id: 'standard' }],
};

export { PACKAGES_BY_SIZE, MULTI_PACKAGE_MAX_DISCOUNT, PACKAGE_ORDER, STANDARD, BUSINESS };
