import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as _ } from "./chunks/clsx.0d472180.js";
import { forwardRef as k } from "react";
import { c as x } from "./chunks/cn.2ea4f521.js";
import { Icon as B } from "./icon.mjs";
import { v as L } from "./chunks/warnings.8f6035a3.js";
import { s as e } from "./chunks/styles.294d3f14.js";
import { AnchorOrButton as V } from "./anchorOrButton.mjs";
const m = {
  s: "xs",
  m: "m",
  l: "m"
}, i = k(
  ({
    variant: l = "black",
    size: n = "s",
    leadingIcon: t,
    trailingIcon: r,
    className: c,
    children: u,
    underline: p = !0,
    ...d
  }, f) => {
    const y = !!(t || r), I = m[n], s = (a) => {
      if (typeof a == "string")
        return /* @__PURE__ */ o.jsx(B, { name: a, size: I, className: e.icon });
      const N = a;
      return L({
        iconName: a.displayName,
        componentSize: n,
        sizeMap: m,
        componentName: i.displayName
      }), /* @__PURE__ */ o.jsx(
        N,
        {
          className: x(
            "inline-flex",
            {
              "size-2": n === "s",
              "size-3": n === "m" || n === "l"
            },
            e.icon
          )
        }
      );
    };
    return /* @__PURE__ */ o.jsxs(
      V,
      {
        ...d,
        ref: f,
        className: _(
          e.root,
          e[l],
          e[n],
          y && e.hasIcon,
          p && e.underline,
          c
        ),
        children: [
          t && s(t),
          u,
          r && s(r)
        ]
      }
    );
  }
);
try {
  i.displayName = "LinkButton", i.__docgenInfo = { description: "", displayName: "LinkButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, variant: { defaultValue: { value: "black" }, description: "", name: "variant", required: !1, type: { name: "LinkButtonVariant" } }, size: { defaultValue: { value: "s" }, description: "", name: "size", required: !1, type: { name: "LinkButtonSize" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, underline: { defaultValue: { value: !0 }, description: "", name: "underline", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  i as LinkButton
};
