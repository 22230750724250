import cx from 'classnames';
import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion, type Transition, type Variant } from 'framer-motion';
import { useKeyPressEvent } from 'react-use';
import { useState } from 'react';
import { XMarkIconM } from '@carvertical/icons/react';
import styles from './NonModalDialog.module.scss';

type NonModalDialogPosition = 'topLeft' | 'topRight' | 'bottomRight' | 'bottomLeft';

type NonModalDialogProps = React.PropsWithChildren<{
  closeButtonClassName?: string;
  closeButtonLabel?: string;
  closeButtonShown?: boolean;
  contentClassName?: string;
  offset?: number;
  onClose?: () => void;
  open?: boolean;
  position?: NonModalDialogPosition;
}>;

enum DialogAnimationVariant {
  Hidden = 'hidden',
  Visible = 'visible',
  Exit = 'exit',
}

const DIALOG_ANIMATION_VARIANTS: Record<DialogAnimationVariant, Variant> = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const SPRING_TRANSITION_CONFIG: Transition = {
  duration: 0.5,
  type: 'spring',
  bounce: 0,
};

// TODO: Move to design system (@carvertical/ui)
const NonModalDialog = ({
  children,
  closeButtonClassName,
  closeButtonLabel: closeButtonLabelProp,
  closeButtonShown = true,
  contentClassName,
  onClose,
  open,
  position = 'bottomLeft',
}: NonModalDialogProps) => {
  const { t } = useTranslation();
  const [focusTrapped, setFocusTrapped] = useState(false);
  const closeButtonLabel = closeButtonLabelProp || t('general.closeAction');

  const { context, refs } = useFloating({
    open,
    onOpenChange: (willBeOpened) => {
      if (!willBeOpened) {
        onClose?.();
      }
    },
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePress: false });
  const { getFloatingProps, getReferenceProps } = useInteractions([click, role, dismiss]);

  useKeyPressEvent('Tab', () => {
    if (!focusTrapped) {
      setFocusTrapped(true);
    }
  });

  return (
    <FloatingPortal>
      <AnimatePresence>
        {open && (
          <FloatingFocusManager context={context} disabled={!focusTrapped} returnFocus>
            <motion.div
              {...getFloatingProps()}
              ref={refs.setFloating}
              variants={DIALOG_ANIMATION_VARIANTS}
              transition={SPRING_TRANSITION_CONFIG}
              initial={DialogAnimationVariant.Hidden}
              animate={DialogAnimationVariant.Visible}
              exit={DialogAnimationVariant.Exit}
              className={cx(styles.root, styles[position], contentClassName)}
              role="dialog"
            >
              {closeButtonShown && (
                <button
                  {...getReferenceProps()}
                  className={cx(styles.closeButton, closeButtonClassName)}
                  title={closeButtonLabel}
                  ref={refs.setReference}
                  type="button"
                >
                  <XMarkIconM />
                </button>
              )}

              {children}
            </motion.div>
          </FloatingFocusManager>
        )}
      </AnimatePresence>
    </FloatingPortal>
  );
};

export { NonModalDialog };
export type { NonModalDialogPosition, NonModalDialogProps };
