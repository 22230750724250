import { AnimatePresence, motion, type Variants } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { Heading, Stack, Text } from '@carvertical/ui';
import { range } from 'lodash';
import cx from 'classnames';
import styles from './SwipeableSections.module.scss';

type Step = {
  title?: string;
  description?: string;
  subtitle?: string;
  image?: string;
  Component?: React.ReactNode;
};

type Direction = 'next' | 'prev';

type SwipeableSectionsProps = {
  activeIndex?: number;
  className?: string;
  fullWidth?: boolean;
  steps: Step[];
  onChange?: (index: number) => void;
  variant?: 'light' | 'dark';
  swipeInterval?: number;
  animationVariants?: Variants;
};

const VARIANTS = {
  initial: {
    opacity: 0,
    x: -48,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    x: 48,
    transition: {
      ease: 'easeOut',
    },
  },
};

const DESCRIPTION_VARIANTS = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
  },
  exit: {
    height: 0,
    opacity: 0,
  },
};

const DESCRIPTION_TRANSITION = {
  duration: 0.3,
  ease: 'easeInOut',
};

const DRAG_THRESHOLD = 50;

const SwipeableSections = ({
  activeIndex: defaultActiveIndex = 0,
  className,
  fullWidth = false,
  steps,
  onChange,
  variant = 'dark',
  swipeInterval,
  animationVariants,
}: SwipeableSectionsProps) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const [manualSwipe, setManualSwipe] = useState(false);

  const stepsCount = steps.length;

  const paginate = useCallback(
    (paginationDirection: Direction) => {
      setActiveIndex((prevIndex) => {
        return paginationDirection === 'next'
          ? (prevIndex + 1) % stepsCount
          : (prevIndex - 1 + stepsCount) % stepsCount;
      });
    },
    [stepsCount],
  );

  useEffect(() => {
    if (!swipeInterval || manualSwipe) {
      return undefined;
    }

    const timer = setInterval(() => {
      paginate('next');
    }, swipeInterval);

    return () => clearInterval(timer);
  }, [paginate, swipeInterval, manualSwipe]);

  useEffect(() => {
    onChange?.(activeIndex);
  }, [activeIndex, onChange]);

  return (
    <Stack crossAxisAlign={fullWidth ? 'stretch' : 'center'} gap={3} className={className}>
      <AnimatePresence mode="wait" initial={false}>
        {steps.map(
          ({ Component, description, image, subtitle, title }, index) =>
            index === activeIndex && (
              <motion.div
                variants={animationVariants || VARIANTS}
                initial="initial"
                animate="animate"
                exit="exit"
                key={title || index}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                whileDrag={{ cursor: 'grabbing', opacity: 0.5 }}
                dragElastic={0.1}
                onDragEnd={(_, { offset: { x, y } }) => {
                  if (Math.abs(x) < Math.abs(y)) {
                    return;
                  }

                  setManualSwipe(true);

                  if (x > DRAG_THRESHOLD) {
                    paginate('prev');
                  } else if (x < DRAG_THRESHOLD * -1) {
                    paginate('next');
                  }
                }}
              >
                <Stack type="vertical" crossAxisAlign="center" gap={3}>
                  {Component}

                  {image && (
                    <Image src={image} width={330} height={234} alt="" className={styles.image} />
                  )}

                  <Stack crossAxisAlign="center" type="vertical" gap={1}>
                    {subtitle && (
                      <Text textColor="highlighted" variant="m+">
                        {subtitle}
                      </Text>
                    )}

                    <Heading align="center" variant="s" as="h3">
                      {title}
                    </Heading>

                    <motion.div
                      variants={DESCRIPTION_VARIANTS}
                      transition={DESCRIPTION_TRANSITION}
                      className={styles.description}
                    >
                      <Text align="center" variant="l">
                        {description}
                      </Text>
                    </motion.div>
                  </Stack>
                </Stack>
              </motion.div>
            ),
        )}
      </AnimatePresence>

      <Stack role="tablist" type="horizontal" aria-label="Navigation" mainAxisAlign="center">
        {range(stepsCount).map((index) => {
          const active = activeIndex === index;

          return (
            <motion.button
              disabled={active}
              key={index}
              role="tab"
              aria-selected={active ? 'true' : 'false'}
              aria-controls={`tabpanel-${index}`}
              className={cx(
                styles.dot,
                active && styles.dotActive,
                variant === 'light' && styles.dotLight,
              )}
              onClick={() => {
                setManualSwipe(true);
                setActiveIndex(index);
              }}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.8 }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export { SwipeableSections };
export type { Step };
