import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as p, a as m } from "./chunks/TagGroup.7ec11991.js";
import { c as u } from "./chunks/cn.2ea4f521.js";
import { T as f } from "./chunks/TagGroupContext.b8226d23.js";
import "react";
import "./chunks/index.69d44069.js";
import "./chunks/utils.f8c6b750.js";
import "./chunks/clsx.0d472180.js";
import "react-dom";
import "./chunks/useFocusable.f923b0dc.js";
import "./chunks/Collection.6227ba77.js";
const t = ({
  children: l,
  size: e = "m",
  variant: r = "outline",
  selectionMode: i = "single",
  className: n,
  onChange: o,
  listClassName: s,
  ...d
}) => /* @__PURE__ */ a.jsx(f, { value: { size: e, variant: r }, children: /* @__PURE__ */ a.jsx(
  p,
  {
    ...d,
    className: n,
    selectionMode: i,
    onSelectionChange: o,
    children: /* @__PURE__ */ a.jsx(
      m,
      {
        className: u(
          "flex flex-wrap",
          {
            "gap-0.75": e === "m",
            "gap-1": e === "l"
          },
          s
        ),
        children: l
      }
    )
  }
) });
try {
  t.displayName = "TagGroup", t.__docgenInfo = { description: "", displayName: "TagGroup", props: { className: { defaultValue: null, description: "", name: "className", required: !1, type: { name: "string" } }, listClassName: { defaultValue: null, description: "", name: "listClassName", required: !1, type: { name: "string" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "TagSize" } }, variant: { defaultValue: { value: "outline" }, description: "", name: "variant", required: !1, type: { name: "TagVariant" } }, selectionMode: { defaultValue: { value: "single" }, description: "", name: "selectionMode", required: !1, type: { name: "SelectionMode" } }, selectedKeys: { defaultValue: null, description: "", name: "selectedKeys", required: !1, type: { name: '"all" | Iterable<Key>' } }, defaultSelectedKeys: { defaultValue: null, description: "", name: "defaultSelectedKeys", required: !1, type: { name: '"all" | Iterable<Key>' } }, onChange: { defaultValue: null, description: "", name: "onChange", required: !1, type: { name: "(keys: Selection) => void" } }, onRemove: { defaultValue: null, description: "", name: "onRemove", required: !1, type: { name: "(keys: Set<Key>) => void" } } } };
} catch {
}
export {
  t as TagGroup
};
