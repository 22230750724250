import { useQuery } from '@tanstack/react-query';
import type { ReportProduct } from 'modules/reports';
import { getUserQuotas } from '../api';

type UseUserQuotasProps = {
  productId: ReportProduct;
};

const USER_QUOTAS_QUERY_KEY = {
  shared: ['quotas'],
  personal: (productId: number) => ['quotas', productId],
};

const useUserQuotas = ({ productId }: UseUserQuotasProps) => {
  const { data, isPending } = useQuery({
    queryKey: USER_QUOTAS_QUERY_KEY.personal(productId),
    queryFn: () => getUserQuotas(productId),
  });

  const quotas = data?.quotas[productId] || 0;

  return {
    data: quotas,
    quotasPending: isPending,
  };
};

export { useUserQuotas, USER_QUOTAS_QUERY_KEY };
export type { UseUserQuotasProps };
