import { Stack } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import AuthContainer from 'containers/AuthContainer';
import UserContainer from 'containers/UserContainer';
import withContainers from 'hoc/withContainers';
import { AuthButton } from 'components/common/AuthButton';
import { UserMenu } from 'modules/user';
import { MenuPopover } from './MenuPopover';
import styles from './UserActions.module.scss';

type UserActionsProps = {
  containers: {
    auth: AuthContainer;
    user: UserContainer;
  };
};

const UserActions = ({ containers: { auth } }: UserActionsProps) => {
  const { t } = useTranslation();

  const {
    state: { authenticated, unauthenticated },
  } = auth;

  return (
    <>
      {unauthenticated && (
        <Stack gap={0} type="horizontal">
          <AuthButton size="m" className={cx(styles.link, styles.loginLink)}>
            {t('auth.logInAction')}
          </AuthButton>

          <AuthButton mode="signup" size="m" className={styles.link}>
            {t('auth.signUpAction')}
          </AuthButton>
        </Stack>
      )}

      {authenticated && (
        <MenuPopover
          anchorType="button"
          anchorIcon="user"
          title={t('auth.myAccountLabel')}
          className="max-h-full max-w-[360px]"
        >
          <UserMenu className="min-w-40 pt-2" counterClassName="justify-center" variant="s" />
        </MenuPopover>
      )}
    </>
  );
};

const WrappedUserActions = withContainers({
  auth: AuthContainer,
  user: UserContainer,
})(UserActions);

export { WrappedUserActions as UserActions };
export type { UserActionsProps };
