import { Button, type ButtonProps } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { Link } from './Link';

type RefillButtonProps = {
  buttonProps?: ButtonProps;
  individual?: boolean;
  children?: React.ReactNode;
};

const RefillButton = ({ buttonProps, individual, children }: RefillButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="blue"
      as={Link}
      // @ts-expect-error TS(2322)
      to={individual ? '/pricing' : '/business#pricing'}
      {...buttonProps}
    >
      {children || t('usage.getMoreAction')}
    </Button>
  );
};

export { RefillButton };
