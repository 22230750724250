import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as a } from "react";
import { Icon as s } from "./icon.mjs";
import { k as i } from "./chunks/styles.294d3f14.js";
import { O as c } from "./chunks/OptionBase.aba49d92.js";
import "./chunks/clsx.0d472180.js";
import "./text.mjs";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.fa6e5999.js";
const t = a((e, r) => /* @__PURE__ */ o.jsx(
  c,
  {
    ref: r,
    type: "checkbox",
    selectionBoxClassName: i.selectionBox,
    selectionIndicator: /* @__PURE__ */ o.jsx(s, { name: "check-thick", size: "xs" }),
    ...e
  }
));
try {
  t.displayName = "Checkbox", t.__docgenInfo = { description: "", displayName: "Checkbox", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } } } };
} catch {
}
export {
  t as Checkbox
};
