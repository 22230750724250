const VIN = 'vin';
const LP = 'lp';
const UNIVERSAL = 'universal';

module.exports = {
  VIN,
  LP,
  UNIVERSAL,
  IDENTIFIER_TYPE: { VIN, LP, UNIVERSAL },

  VIN_PATTERN:
    '^\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*$',

  LP_PATTERN: '^\\s*([A-Z0-9][-\\s]*){1,9}\\s*$',
  LP_PLACEHOLDER_BRITAIN: 'AA00 AAA',
  LP_PLACEHOLDER_CROATIA: 'AA 000-AA',
  LP_PLACEHOLDER_CZECHIA: '0A0 0000',
  LP_PLACEHOLDER_DENMARK: 'AA 00 000',
  LP_PLACEHOLDER_FINLAND: 'AAA-000',
  LP_PLACEHOLDER_FRANCE: 'AA-000-AA',
  LP_PLACEHOLDER_HUNGARY: 'AAA-000',
  LP_PLACEHOLDER_ITALY: 'AA 000AA',
  LP_PLACEHOLDER_LATVIA: 'AA-0000',
  LP_PLACEHOLDER_LITHUANIA: 'AAA 000',
  LP_PLACEHOLDER_MEXICO: 'AAA-000-A',
  LP_PLACEHOLDER_POLAND: 'AA 00000',
  LP_PLACEHOLDER_PORTUGAL: 'AA-00-AA',
  LP_PLACEHOLDER_SERBIA: 'AA 000-AA',
  LP_PLACEHOLDER_SLOVAKIA: 'AA-000AA',
  LP_PLACEHOLDER_SWEDEN: 'AAA 00A',
  LP_PLACEHOLDER_UKRAINE: 'AA 0000 AA',
};
