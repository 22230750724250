import { forwardRef, useEffect, useId, useState, type OptionHTMLAttributes } from 'react';
import cx from 'classnames';
import { AnimationVariant, SCALE_ANIMATION_VARIANTS, Stack, Text } from '@carvertical/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDownIconXS, ExclamationTriangleIconXS } from '@carvertical/icons/react';
import type { SelectProps as BaseSelectProps } from 'types/component';
import styles from './Select.module.scss';

// `lightV2` variant is used to unify UI form components design until
// @carvertical/ui is `Select` component is released
type Color = 'transparent' | 'light' | 'lightV2';

type TextColor = 'dark' | 'light';

type SelectProps = {
  label: string | JSX.Element;
  options: OptionHTMLAttributes<HTMLOptionElement>[];
  labelHidden?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  color?: Color;
  textColor?: TextColor;
  status?: string;
  requiredIndicatorShown?: boolean;
} & BaseSelectProps;

const MESSAGE_ANIMATION_VARIANTS = {
  [AnimationVariant.Hidden]: { opacity: 0, height: 0 },
  [AnimationVariant.Visible]: { opacity: 1, height: 'auto' },
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      color = 'transparent',
      disabled,
      error,
      fullWidth = false,
      label,
      options,
      status: _,
      requiredIndicatorShown,
      textColor = 'dark',
      labelHidden,
      ...otherProps
    },
    ref,
  ) => {
    const messageId = useId();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(true);
    }, []);

    return (
      <label
        className={cx(
          styles.root,
          styles[color],
          fullWidth && styles.fullWidth,
          styles[`text-${textColor}`],
          className,
        )}
      >
        <span
          className={cx(styles.label, labelHidden && 'hidden')}
        >{`${requiredIndicatorShown ? '* ' : ''}${label}`}</span>
        <div
          className={cx(styles.inputWrapper, disabled && styles.disabled, error && styles.error)}
        >
          <select ref={ref} className={styles.select} {...otherProps}>
            {options.map((option) => (
              <option
                key={option.label}
                value={option.value}
                {...(option.selected ? { selected: true } : {})}
              >
                {option.label}
              </option>
            ))}
          </select>

          <ChevronDownIconXS className="absolute right-2" />
        </div>

        <AnimatePresence mode="wait">
          {error && (
            <Stack
              as={motion.div}
              type="horizontal"
              crossAxisAlign="center"
              // @ts-expect-error - polymorphic props not supported
              variants={MESSAGE_ANIMATION_VARIANTS}
              animate={AnimationVariant.Visible}
              initial={mounted ? AnimationVariant.Hidden : AnimationVariant.Visible}
              exit={AnimationVariant.Hidden}
            >
              <Stack type="horizontal" crossAxisAlign="center" className={styles.message}>
                <motion.div
                  variants={SCALE_ANIMATION_VARIANTS}
                  key="error-icon"
                  transition={{ delay: 0.125 }}
                >
                  <ExclamationTriangleIconXS className="flex" />
                </motion.div>

                <Text
                  variant="s"
                  textColor="inherited"
                  id={messageId}
                  as={motion.p}
                  // @ts-expect-error - polymorphic props not supported
                  layout="position"
                >
                  {error}
                </Text>
              </Stack>
            </Stack>
          )}
        </AnimatePresence>
      </label>
    );
  },
);

export { Select };
export type { SelectProps };
