import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { Text } from '@carvertical/ui';
import { ChevronDownIconXS } from '@carvertical/icons/react';
import { STATES_AUSTRALIA } from 'constants/states';
import type { IdentifierFormFields } from '../schemas';
import { StateIcon } from './StateIcon';

type StateKey = keyof typeof STATES_AUSTRALIA;

type AustralianStateSelectorProps = {
  disabled?: boolean;
};

const CONTENT_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1, transition: { delay: 0.2 } },
};

const AustralianStateSelector = ({ disabled }: AustralianStateSelectorProps) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<IdentifierFormFields>();
  const countryState = watch('countryState');

  useEffect(() => {
    if (!countryState) {
      setValue('countryState', Object.keys(STATES_AUSTRALIA)[0]);
    }
  }, [countryState, setValue]);

  return (
    <label className="flex h-full flex-col items-center justify-center bg-blue outline-white focus:outline-2">
      <span className="sr-only">{t('vinForm.state')}</span>

      <select
        className="absolute bottom-0 left-0 right-0 top-0 z-10 cursor-pointer appearance-none opacity-0"
        value={countryState}
        disabled={disabled}
        onChange={(event) => setValue('countryState', event.target.value as StateKey)}
      >
        {Object.keys(STATES_AUSTRALIA).map((key) => (
          <option key={key} value={key}>
            {`${key} - ${STATES_AUSTRALIA[key as StateKey]}`}
          </option>
        ))}
      </select>

      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="flex flex-col items-center gap-0.5"
        variants={CONTENT_ANIMATION_VARIANTS}
      >
        <StateIcon state={countryState as StateKey} className="h-4 w-4" />

        <Text
          align="center"
          variant="2xs+"
          textColor="light"
          className="flex flex-row items-center gap-0.25"
        >
          {countryState} <ChevronDownIconXS />
        </Text>
      </motion.div>
    </label>
  );
};

export { AustralianStateSelector };
