import { useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Stack, Text, IconButton, Container } from '@carvertical/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { toLower } from 'lodash';
import { XMarkIconM } from '@carvertical/icons/react';
import { createInstance } from 'services/store';
import { useDiscount } from 'context/DiscountProvider';
import { useRouteData } from 'context/RouteDataProvider';
import PercentageRibbonIcon from 'assets/icons/percentage-ribbon.svg';
import styles from './VoucherBar.module.scss';

type Discount = {
  voucher: string;
  percentage: number;
  silent: boolean;
};
type StringMap = Record<string, string>;
type PartnerLabel = Record<string, StringMap | string>;

const ANIMATION_VARIANTS = {
  hidden: { y: '-100%', opacity: 0 },
  visible: { y: '0%', opacity: 1 },
};

const PARTNER_LABEL: PartnerLabel = {
  radon3: {
    verificare: 'verificare-vin.ro',
    historicocarro: 'historicocarro.pt',
    istorijevozila: 'istorijevozila.rs',
    autonhistoria: 'autonhistoria.fi',
    vindecoderpro: 'vindecoderpro.com',
    verifiervoiture: 'verifiervoiture.fr',
    consultarvehiculo: 'consultarvehiculo.es',
    vinpropl: 'vinpro.pl',
    vinprolt: 'vinpro.lt',
    vinprocz: 'vinpro.cz',
    vinprohr: 'vinpro.hr',
    car30: 'istorijevozila.rs',
    get20: 'bestautocheck.nl',
    vozila: 'istorijevozila.rs',
  },
  CARVIN: 'CARVIN',
  sprawdzanievin: 'VIN-Sprawdzenie.pl',
  DISCOUNTCHECKRO: 'verificareistoricauto.ro',
  REDUCERE20: 'aboutcars.ro',
  AUTOVIN: 'AUTOVIN',
  voucher1: 'staging',
};

const STORAGE_KEY = 'inCooperationWith';

const store = createInstance('session');
let wasDismissed = false;

const VoucherBar = () => {
  const { query } = useRouter();
  const { preventVoucherBar } = useRouteData();
  const { t } = useTranslation('common');
  const [ready, setReady] = useState(false);
  const [dismissed, setDismissed] = useState(wasDismissed);
  const { percentage, silent, voucher }: Discount = useDiscount();

  useEffect(() => {
    if (voucher) {
      setReady(!silent);
    }
  }, [voucher, silent]);

  const [affiliate, setAffiliate] = useState(store.get(STORAGE_KEY));

  useEffect(() => {
    const aff = query.a;
    if (!aff) {
      return;
    }
    setAffiliate(aff);
  }, [query.a]);

  useEffect(() => {
    if (dismissed) {
      wasDismissed = true;
    }
  }, [dismissed]);

  const getPartnerLabel = (mainKey: string, subKey?: string) => {
    const label = PARTNER_LABEL[toLower(mainKey)];

    if (!label) {
      return null;
    }

    if (subKey && typeof label === 'object') {
      return label[toLower(subKey)] || null;
    }

    return label;
  };

  const partner =
    getPartnerLabel(affiliate, voucher) || getPartnerLabel(voucher) || getPartnerLabel(affiliate);

  const renderText = useCallback(() => {
    if (!ready || !percentage) {
      return null;
    }

    return partner ? (
      <Trans
        t={t}
        i18nKey="pricing.discountBarPartnerLabel"
        values={{ percentage, partner }}
        components={{
          2: <Text as="span" variant="s+" className={styles.discount} />,
          5: <strong />,
        }}
      >
        {
          'You’ve received an additional <2><0>{{percentage}}</0>%</2> discount from <5><0>{{partner}}</0></5>'
        }
      </Trans>
    ) : (
      <Trans
        t={t}
        i18nKey="pricing.discountBarLabel"
        values={{ percentage }}
        components={{
          2: <Text as="span" variant="s+" className={styles.discount} />,
        }}
      >
        {'You’ve received an additional <2><0>{{percentage}}</0>% discount</2>'}
      </Trans>
    );
  }, [ready, percentage, partner, t]);

  const text = !dismissed && renderText();

  if (preventVoucherBar) {
    return null;
  }

  return (
    <AnimatePresence>
      {text && (
        <motion.div
          className={styles.root}
          variants={ANIMATION_VARIANTS}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Container>
            <Stack
              mainAxisAlign="center"
              crossAxisAlign="center"
              className={styles.wrapper}
              type={{
                mobileUp: 'vertical',
                tabletPortraitUp: 'horizontal',
              }}
            >
              <Stack
                crossAxisAlign="center"
                type={{
                  mobileUp: 'vertical',
                  tabletPortraitUp: 'horizontal',
                }}
                gap={{ mobileUp: 1, tabletPortraitUp: 2 }}
              >
                <PercentageRibbonIcon className={styles.icon} />
                <Text align="center" variant="s">
                  {text}
                </Text>
              </Stack>

              <IconButton
                label={t('general.closeAction')}
                className={styles.closeButton}
                onClick={() => setDismissed(true)}
                icon={XMarkIconM}
              />
            </Stack>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { VoucherBar };
