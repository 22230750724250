import type { CampaignPartnerIdRecord, CampaignContent, CampaignType } from '../types';
import lautoIllustration from '../assets/images/lauto-campaign.jpg';
import lautoSuccessIllustration from '../assets/images/lauto-campaign-success.jpg';
import tomasiAutoIllustration from '../assets/images/tomasi-auto-campaign.jpg';
import tomasiAutoSuccessIllustration from '../assets/images/tomasi-auto-campaign-success.jpg';
import simplicicarIllustration from '../assets/images/simplicar-campaign.jpg';
import simplicicarSuccessIllustration from '../assets/images/simplicicar-campaign-success.jpg';
import jeanlainheritageIllustration from '../assets/images/jeanlainheritage-campaign.jpg';
import jeanlainheritageSuccessIllustration from '../assets/images/jeanlainheritage-campaign-success.jpg';
import aaaautoIllustration from '../assets/images/aaaauto-campaign.jpg';
import aaaautoSuccessIllustration from '../assets/images/aaaauto-campaign-success.jpg';
import excelcarIllustration from '../assets/images/excelcar-campaign.jpg';
import excelcarSuccessIllustration from '../assets/images/excelcar-campaign-success.jpg';

const CAMPAIGN_PARTNERS: CampaignPartnerIdRecord<string> = {
  lauto: "L'Auto SPA",
  tomasiauto: 'Tomasi Auto',
  simplicicar: 'Simplicicar',
  jeanlainheritage: 'Jean Lain Heritage',
  aaaauto: 'AAA auto',
  excelcar: 'Excel Car',
};

const FR_CAMPAIGN_TITLE = "Obtenez jusqu'à 20 rapports gratuits";
const FR_CAMPAIGN_SUCCESS_TITLE = 'Merci!';
const FR_CAMPAIGN_SUCCESS_DESCRIPTION =
  'Votre demande a été enregistrée avec succès. Veuillez vérifier votre e-mail pour des rapports gratuits.';
const IT_CAMPAIGN_TITLE = 'Ottieni fino a 20 report gratuiti';
const IT_CAMPAIGN_SUCCESS_TITLE = 'Grazie!';
const IT_CAMPAIGN_SUCCESS_DESCRIPTION =
  'La tua richiesta è stata registrata con successo. Controlla la tua email per il pacchetto di report gratuiti.';
const PL_CAMPAIGN_CTA = 'Otrzymaj do 20 raportów za darmo';
const PL_CAMPAIGN_SUCCESS_TITLE = 'Dziękujemy!';
const PL_CAMPAIGN_SUCCESS_DESCRIPTION =
  'Twoje zgłoszenie zostało pomyślnie zarejestrowane. Sprawdź swoją pocztę, aby otrzymać pakiet darmowych raportów.';

const CAMPAIGN_CONTENT: CampaignType<CampaignContent> = {
  britain: {
    text: {
      title: 'Boost your dealership business',
      description: 'Get up to 20 free reports and witness our product benefits',
      cta: 'Get up to 20 reports for free',
      successTitle: 'Thank You!',
      successDescription:
        'Your request has been successfully registered. Please check your email for the free report bundle.',
    },
  },
  poland: {
    text: {
      title: 'Wzmocnij swój biznes dealerski',
      description: 'Uzyskaj do 20 darmowych raportów i przekonaj się o zaletach naszego produktu.',
      cta: PL_CAMPAIGN_CTA,
      successTitle: PL_CAMPAIGN_SUCCESS_TITLE,
      successDescription: PL_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
  },
  france: {
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        'De nombreux concessionnaires français utilisent déjà les rapports carVertical, essayez-les gratuitement en remplissant ce formulaire.',
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
  },
  italy: {
    text: {
      title: IT_CAMPAIGN_TITLE,
      description:
        'Molte concessionarie italiane utilizzano già i report di carVertical; provali gratuitamente compilando questo modulo.',
      cta: IT_CAMPAIGN_TITLE,
      successTitle: IT_CAMPAIGN_SUCCESS_TITLE,
      successDescription: IT_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
  },
  lauto: {
    partner: CAMPAIGN_PARTNERS.lauto,
    text: {
      title: IT_CAMPAIGN_TITLE,
      description: "L'Auto SPA ha evitato perdite inaspettate utilizzando carVertical",
      cta: IT_CAMPAIGN_TITLE,
      successTitle: IT_CAMPAIGN_SUCCESS_TITLE,
      successDescription: IT_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: lautoIllustration,
      success: lautoSuccessIllustration,
      badges: [
        {
          className: 'bottom-[5%] right-[15%] sm:right-[30%] md:bottom-[15%]',
          partner: CAMPAIGN_PARTNERS.lauto,
          foundedIn: '1969',
          vehiclesSold: '150 000',
        },
      ],
    },
  },
  tomasiauto: {
    partner: CAMPAIGN_PARTNERS.tomasiauto,
    text: {
      title: IT_CAMPAIGN_TITLE,
      description: 'Tomasi Auto ha aumentato le sue vendite del 21% grazie a carVertical.',
      cta: IT_CAMPAIGN_TITLE,
      successTitle: IT_CAMPAIGN_SUCCESS_TITLE,
      successDescription: IT_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: tomasiAutoIllustration,
      success: tomasiAutoSuccessIllustration,
      className: 'object-center md:object-[50%_80%]',
      successClassName: 'object-[20%_25%] md:object-[30%_50%]',
      badges: [
        {
          className:
            'bottom-[25%] left-[10%] sm:left-[20%] md:bottom-[20%] md:left-[15%] lg:left-[25%]',
          partner: 'Andrea Pirillo',
          followers: '687K',
          platform: 'youtube',
        },
        {
          className: 'bottom-[2%] right-[15%] sm:right-[22%] md:bottom-[5%]',
          partner: CAMPAIGN_PARTNERS.tomasiauto,
          foundedIn: '1997',
          vehiclesSold: '300 000',
        },
      ],
    },
  },
  simplicicar: {
    partner: CAMPAIGN_PARTNERS.simplicicar,
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        'Simplicicar a augmenté ses ventes de voitures de 21 % en utilisant carVertical.',
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: simplicicarIllustration,
      success: simplicicarSuccessIllustration,
      className: 'md:object-bottom',
      badges: [
        {
          className: 'bottom-[25%] left-[5%] md:bottom-[20%] md:left-[15%]',
          partner: '@Akramjunior',
          followers: '1.1M',
          platform: 'instagram',
        },
        {
          className: 'bottom-1 right-[10%] md:bottom-[5%] md:right-[10%]',
          partner: CAMPAIGN_PARTNERS.simplicicar,
          foundedIn: '2011',
          vehiclesSold: '156 000',
        },
      ],
    },
  },
  jeanlainheritage: {
    partner: CAMPAIGN_PARTNERS.jeanlainheritage,
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        "Jean Lain Heritage a économisé des milliers d'euros en coûts imprévus grâce à carVertical.",
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: jeanlainheritageIllustration,
      success: jeanlainheritageSuccessIllustration,
      className: 'md:object-bottom',
      successClassName: 'object-[50%_30%] md:object-center',
      badges: [
        {
          className: 'bottom-[5%] right-[10%] sm:right-[30%] md:bottom-[15%] md:right-[30%]',
          partner: CAMPAIGN_PARTNERS.jeanlainheritage,
          foundedIn: '2018',
          vehiclesSold: '1000+',
          luxury: true,
        },
      ],
    },
  },
  aaaauto: {
    partner: CAMPAIGN_PARTNERS.aaaauto,
    text: {
      title: 'Uzyskaj do 20 darmowych raportów',
      description: 'AAA Auto ocenia stan samochodu 2 razy szybciej z carVertical.',
      cta: PL_CAMPAIGN_CTA,
      successTitle: PL_CAMPAIGN_SUCCESS_TITLE,
      successDescription: PL_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: aaaautoIllustration,
      success: aaaautoSuccessIllustration,
      className: 'object-[50%_60%]',
      successClassName: 'object-[50%_60%] md:object-bottom',
      badges: [
        {
          className: 'bottom-[5%] right-[10%] sm:right-[30%] md:bottom-[10%] md:right-[25%]',
          partner: CAMPAIGN_PARTNERS.aaaauto,
          foundedIn: '1992',
          vehiclesSold: '1 600 000',
        },
      ],
    },
  },
  excelcar: {
    partner: CAMPAIGN_PARTNERS.excelcar,
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        "EXCEL CAR a accéléré son processus d'achat de véhicules en utilisant carVertical.",
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: excelcarIllustration,
      success: excelcarSuccessIllustration,
      className: 'object-center md:object-[50%_60%]',
      successClassName: 'object-[30%_30%] md:object-[30%_50%]',
      badges: [
        {
          className: 'bottom-[5%] right-[15%] sm:right-[30%] md:bottom-[10%] md:right-[30%]',
          partner: CAMPAIGN_PARTNERS.excelcar,
          foundedIn: '2007',
          vehiclesSold: '3 000',
        },
      ],
    },
  },
};

export { CAMPAIGN_CONTENT };
