import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type ModalId =
  | 'account'
  | 'hamburger'
  | 'logout'
  | 'notEnoughCredits'
  | 'marketSelection'
  | 'suggestCheckout'
  | 'languageSelection'
  | 'refundPolicy'
  | 'cancelSubscription';

type ModalState = { open: boolean };

type ModalStore = {
  modals: Record<ModalId, ModalState>;
  actions: {
    updateSection: (id: ModalId, modalState: ModalState) => void;
    openModal: (id: ModalId) => void;
    closeModal: (id: ModalId) => void;
  };
};

const useModalStore = create(
  immer<ModalStore>((set) => {
    const updateSection = (id: ModalId, modalProps: ModalState) => {
      set(({ modals }) => {
        const modal = modals[id];

        if (modal) {
          modals[id] = { ...modal, ...modalProps };
        }
      });
    };

    const toggleModal = (id: ModalId, open: boolean) => updateSection(id, { open });

    return {
      modals: {
        logout: { open: false },
        account: { open: false },
        hamburger: { open: false },
        notEnoughCredits: { open: false },
        marketSelection: { open: false },
        suggestCheckout: { open: false },
        languageSelection: { open: false },
        refundPolicy: { open: false },
        cancelSubscription: { open: false },
      },
      actions: {
        updateSection,
        openModal: (id) => toggleModal(id, true),
        closeModal: (id) => toggleModal(id, false),
      },
    };
  }),
);

const useModals = () => useModalStore((state) => state.modals);
const useModalActions = () => useModalStore((state) => state.actions);
const useIsModalOpen = (id: ModalId) => useModalStore(({ modals }) => modals[id]?.open ?? false);

export { useModals, useIsModalOpen, useModalActions };
export type { ModalState, ModalId };
