import { useEffect, useState } from 'react';
import { AnimatePresence, type AnimationProps, motion } from 'framer-motion';
import { Stack, Button } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useMeasure } from 'react-use';
import { ChevronRightIconM } from '@carvertical/icons/react';
import { AuthButton } from 'components/common/AuthButton';
import withContainers from 'hoc/withContainers';
import AuthContainer from 'containers/AuthContainer';
import { UserHeader } from 'modules/user';
import { useHeaderMenu } from '../hooks';
import { useModal } from '../../../hooks';
import { MarketSwitcher, MarketSwitcherModal } from '../../MarketSwitcher';
import { HamburgerToggle } from './HamburgerToggle';
import { SecondaryNavigation } from './SecondaryNavigation';
import { ITEMS_WRAPPER_ANIMATION_PROPS, ITEM_ANIMATION_PROPS } from './animation';
import { Item } from './Item';
import styles from './VerticalMenu.module.scss';

type VerticalMenuProps = {
  marketSwitcherShown?: boolean;
  containers: {
    auth: AuthContainer;
  };
  onOpen?: () => void;
};

const INTERACTION_PAUSE = 300;

const TRANSITION = {
  y: { type: 'spring', damping: 100, stiffness: 1200 },
};
const DIALOG_ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: { y: '-100%' },
    visible: ({ height }) => ({ y: 0, height }),
  },
  transition: TRANSITION,
};
const CONTENT_ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ...TRANSITION,
        delay: 0.1,
      },
    },
  },
  transition: TRANSITION,
};
const BACKDROP_ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
  },
};

const VerticalMenu = ({
  marketSwitcherShown = true,
  containers: { auth },
  onOpen,
}: VerticalMenuProps) => {
  const { t } = useTranslation(['common', 'auth']);
  const {
    state: { authenticated },
  } = auth;
  const { menuGroups, setActiveMenuGroupId, activeMenuGroup } = useHeaderMenu();
  const {
    open: openHamburgerDialog,
    close: closeHamburgerDialog,
    isModalOpen: hamburgerDialogOpen,
  } = useModal('hamburger');
  const [disabledInteraction, setDisabledInteraction] = useState(false);
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const router = useRouter();
  const { open: openLogoutModal } = useModal('logout');

  const temporaryDisableInteraction = () => {
    setDisabledInteraction(true);

    setTimeout(() => {
      setDisabledInteraction(false);
    }, INTERACTION_PAUSE);
  };

  const logout = () => {
    closeHamburgerDialog();
    openLogoutModal();
  };

  const toggleVisibility = () => {
    if (hamburgerDialogOpen) {
      closeHamburgerDialog();
      return;
    }

    onOpen?.();
    openHamburgerDialog();
  };

  useEffect(() => {
    router.events.on('routeChangeStart', closeHamburgerDialog);

    return () => {
      router.events.off('routeChangeStart', closeHamburgerDialog);
    };
  }, [router.events, closeHamburgerDialog]);

  return (
    <>
      <HamburgerToggle open={hamburgerDialogOpen} onClick={toggleVisibility} />

      <AnimatePresence>
        {hamburgerDialogOpen && (
          <motion.div
            className={styles.backdrop}
            onClick={toggleVisibility}
            role="presentation"
            key="backdrop"
            {...BACKDROP_ANIMATION_PROPS}
          />
        )}

        {hamburgerDialogOpen && (
          <motion.div
            key="dialog"
            role="dialog"
            aria-modal="true"
            className={styles.dialog}
            {...DIALOG_ANIMATION_PROPS}
            custom={{ height }}
          >
            <motion.div
              ref={ref}
              className={cx(styles.content, disabledInteraction && styles.disabledInteraction)}
              {...CONTENT_ANIMATION_PROPS}
            >
              <AnimatePresence mode="popLayout" initial={false}>
                {!activeMenuGroup && (
                  <motion.ul
                    key="items"
                    className={cx(styles.items, 'unstyledList')}
                    {...ITEMS_WRAPPER_ANIMATION_PROPS}
                    onAnimationStart={temporaryDisableInteraction}
                  >
                    {menuGroups.map(({ group, item }) => (
                      <>
                        {group && (
                          <motion.li {...ITEM_ANIMATION_PROPS} id={group.id} key={group.id}>
                            <Item
                              onClick={() => {
                                setActiveMenuGroupId(group.id);
                              }}
                            >
                              {group.title}

                              <ChevronRightIconM />
                            </Item>
                          </motion.li>
                        )}

                        {item && (
                          <motion.li {...ITEM_ANIMATION_PROPS} id={item.id} key={item.id}>
                            <Item href={item.href} to={item.path} active={item.active}>
                              {item.title}
                            </Item>
                          </motion.li>
                        )}
                      </>
                    ))}

                    <>
                      {authenticated ? (
                        <>
                          <motion.li>
                            <div className="my-2 h-[1px] w-full bg-grey-200" />
                          </motion.li>

                          <motion.li {...ITEM_ANIMATION_PROPS} className="my-1">
                            <Item to="/user/reports">
                              <UserHeader usernameShown />
                            </Item>
                          </motion.li>

                          <motion.li {...ITEM_ANIMATION_PROPS} className="mb-4">
                            <Button fullWidth variant="outlined" size="l" onClick={logout}>
                              {t('auth.logOutAction')}
                            </Button>
                          </motion.li>
                        </>
                      ) : (
                        <Stack
                          gap={2}
                          as={motion.ul}
                          {...ITEM_ANIMATION_PROPS}
                          className={cx(styles.loginActions, 'unstyledList')}
                          crossAxisAlign="stretch"
                        >
                          <li>
                            <AuthButton mode="login" size="l" variant="outlined" fullWidth>
                              {t('auth.logInAction')}
                            </AuthButton>
                          </li>

                          <li>
                            <AuthButton mode="signup" size="l" fullWidth>
                              {t('auth.signUpAction')}
                            </AuthButton>
                          </li>
                        </Stack>
                      )}
                    </>

                    {marketSwitcherShown && (
                      <motion.li {...ITEM_ANIMATION_PROPS} className={styles.marketSwitcherWrapper}>
                        <MarketSwitcher buttonClassName="w-fit my-0 mx-auto" />
                      </motion.li>
                    )}
                  </motion.ul>
                )}

                {activeMenuGroup && (
                  <SecondaryNavigation
                    group={activeMenuGroup}
                    onBack={() => setActiveMenuGroupId(null)}
                    className={styles.items}
                  />
                )}
              </AnimatePresence>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {hamburgerDialogOpen && <MarketSwitcherModal />}
    </>
  );
};

const WrappedVerticalMenu = withContainers({ auth: AuthContainer })(VerticalMenu);

export { WrappedVerticalMenu as VerticalMenu };
export type { VerticalMenuProps };
