import { useTranslation } from 'next-i18next';
import { Tab, TabList, Tabs } from '@carvertical/ui';
import { usePathname } from 'next/navigation';
import { Link } from 'components/common/Link';
import { removeBasePath } from 'utils/router';

const UserSettingsTabs = () => {
  const { t } = useTranslation();

  const pathname = removeBasePath(usePathname());

  return (
    <Tabs selectedKey={pathname} className="mb-4">
      <TabList>
        <Tab id="/user/settings">
          <Link to="/user/settings">{t('settingsPage.title')}</Link>
        </Tab>

        <Tab id="/user/settings/my-subscription">
          <Link to="/user/settings/my-subscription">{t('settingsPage.mySubscriptionTitle')}</Link>
        </Tab>

        <Tab id="/user/settings/my-cards">
          <Link to="/user/settings/my-cards">{t('settingsPage.myCardsTitle')}</Link>
        </Tab>
      </TabList>
    </Tabs>
  );
};

export { UserSettingsTabs };
