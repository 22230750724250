import { type RefinementCtx, z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { emailSchema, phoneSchema } from 'modules/shared/schemas';
import { UserType } from 'types/user';
import { validateVatCode } from 'modules/shared/utils';
import { PaymentMethod } from 'types/payment';

type FieldsData = Record<string, string | undefined>;

const USER_FIELDS = ['firstName', 'lastName', 'companyName', 'entityCode', 'address'];
const CARD_FIELDS = [
  'encryptedCardNumber',
  'encryptedExpiryDate',
  'encryptedSecurityCode',
  'holderName',
];

const validateUserFields = (data: FieldsData, refinementContext: RefinementCtx) => {
  USER_FIELDS.forEach((field) => {
    const { userType } = data;
    const fieldValue = data[field];
    const expectedUserType =
      field === 'firstName' || field === 'lastName' ? UserType.Individual : UserType.Company;
    const invalid = !fieldValue && userType === expectedUserType;

    if (invalid) {
      refinementContext.addIssue({
        path: [field],
        code: z.ZodIssueCode.custom,
        message: ERROR_TRANSLATION_KEYS.required,
      });
    }
  });
};

const validateCardFields = (data: FieldsData, refinementContext: RefinementCtx) => {
  CARD_FIELDS.forEach((field) => {
    if (data.method === 'card' && !data[field] && field !== 'holderName') {
      refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: ERROR_TRANSLATION_KEYS.required,
        path: [field],
      });
    }
  });
};

const userSettingsFormSchema = z
  .object({
    userType: z.nativeEnum(UserType).optional(),
    email: emailSchema.optional(),
    phone: phoneSchema.optional(),
    firstName: z.string().trim().optional(), // optional, since values are validating inside `superRefine`
    lastName: z.string().trim().optional(),
    username: z.string().optional(),
    companyName: z.string().trim().optional(),
    entityCode: z.string().trim().optional(),
    vatCode: z.string().optional().refine(validateVatCode, {
      message: ERROR_TRANSLATION_KEYS.vatCode,
    }),
    address: z.string().trim().optional(),
    dealerManagementSystem: z.string().optional(),
    businessCategory: z
      .union([
        z.literal('select'),
        z.literal('car-dealer'),
        z.literal('insurance'),
        z.literal('car-inspection'),
        z.literal('financing'),
        z.literal('other'),
      ])
      .optional(),
  })
  .superRefine((data, refinementContext) => validateUserFields(data, refinementContext))
  .refine(({ vatCode, address }) => !vatCode || address, {
    message: ERROR_TRANSLATION_KEYS.required,
    path: ['address'],
  })
  .refine(
    ({ businessCategory, userType }) =>
      businessCategory !== 'select' || userType === UserType.Individual,
    {
      message: ERROR_TRANSLATION_KEYS.required,
      path: ['businessCategory'],
    },
  );

const addNewCardFormSchema = z
  .object({
    encryptedCardNumber: z.string().optional(),
    encryptedSecurityCode: z.string().optional(),
    encryptedExpiryDate: z.string().optional(),
    encryptedExpiryMonth: z.string().optional(),
    encryptedExpiryYear: z.string().optional(),
    holderName: z.string().optional(),
    fingerprint: z.string().optional(),
    brand: z.string().optional(),
    method: z.nativeEnum(PaymentMethod).optional(),
  })
  .superRefine((data, refinementContext) => validateCardFields(data, refinementContext));

type UserSettingsFormFields = z.infer<typeof userSettingsFormSchema>;
type AddNewCardFormFields = z.infer<typeof addNewCardFormSchema>;

export { userSettingsFormSchema, addNewCardFormSchema };
export type { UserSettingsFormFields, AddNewCardFormFields };
