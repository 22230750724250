import { Grid, Stack, Text } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { motion, type Variants } from 'framer-motion';
import { includes, without } from 'lodash';
import { useTranslation } from 'next-i18next';
import { Fragment, useState } from 'react';
import { ChevronUpIconXS, ChevronDownIconXS } from '@carvertical/icons/react';
import { createSubset, getLocale } from 'services/site';
import { useRouteData } from 'context/RouteDataProvider';
import { DECODERS_GROUP, MAIN_LINK_GROUPS, SECTION_GRID_PROPS } from './constants';
import { DecoderSection } from './DecodersSection';
import { Divider } from './Divider';
import { FooterLink } from './FooterLink';
import type { Group, GroupLink } from './types';
import styles from './FooterSections.module.scss';

const ANIMATION_VARIANTS = {
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
} satisfies Variants;

const LOCALE_OVERRIDES: Record<string, string> = {
  gb: 'uk',
};

const FooterSections = () => {
  const { t } = useTranslation();
  const { pages, market } = useRouteData();

  const [expandedBlocks, setBlocksExpanded] = useState(['']);

  const decodersSectionShown = DECODERS_GROUP[0].locale?.includes(getLocale());

  return (
    <div className={styles.root}>
      <Grid {...SECTION_GRID_PROPS}>
        {MAIN_LINK_GROUPS.map((group) =>
          // TODO: createSubset should be fully typed by refactoring site.js to TS
          (createSubset(group) as Group[]).map(({ id, labelKey: titleKey, links }) => {
            const expanded = includes(expandedBlocks, id);

            const toggleExpandedState = () =>
              setBlocksExpanded(expanded ? without(expandedBlocks, id) : expandedBlocks.concat(id));

            const nonDecoderLinksExist = id === 'decoders';

            const linksExist = createSubset(links).length > 0;
            if (!linksExist) {
              return null;
            }

            return (
              <motion.div key={id}>
                <Stack
                  gap={0}
                  className={cn(
                    expanded && styles.blockExpanded,
                    nonDecoderLinksExist && 'hideFromTablet',
                  )}
                >
                  <button
                    type="button"
                    className={styles.subtitleButton}
                    onClick={toggleExpandedState}
                  >
                    <Text
                      as="h2"
                      variant="s"
                      textColor={expanded ? 'darkSecondary' : 'light'}
                      className={styles.subtitle}
                    >
                      {t(titleKey)}
                    </Text>

                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={{ rotate: expanded ? 360 : 0 }}
                      transition={{ type: 'easeInOut', duration: 0.3 }}
                    >
                      {expanded ? (
                        <ChevronUpIconXS className="hideFromTablet text-grey-600" />
                      ) : (
                        <ChevronDownIconXS className="hideFromTablet text-white" />
                      )}
                    </motion.div>
                  </button>

                  <Grid
                    as="ul"
                    columnCount={4}
                    rowGap={{ mobileUp: 2, tabletPortraitUp: 1.5 }}
                    className={styles.links}
                  >
                    {(createSubset(links) as GroupLink[]).map(
                      ({ label, labelKey, page, pageId, url, hasEndLocale }) => {
                        const pageInfo = page || pages[pageId];

                        if (!pageInfo && !url) {
                          return null;
                        }

                        const footerLinkProps = {
                          label: labelKey ? t(labelKey) : label,
                          pageId,
                          pageInfo,
                          url: hasEndLocale
                            ? `${url}/${LOCALE_OVERRIDES[market.countryCode] || market.countryCode}`
                            : url,
                          className: styles.link,
                        };

                        return (
                          <Fragment key={pageId}>
                            <li className="hideUntilTablet">
                              <FooterLink {...footerLinkProps} />
                            </li>

                            <motion.li
                              exit="hidden"
                              initial="hidden"
                              animate={expanded ? 'visible' : 'hidden'}
                              variants={ANIMATION_VARIANTS}
                              className="hideFromTablet"
                            >
                              <FooterLink {...footerLinkProps} />
                            </motion.li>
                          </Fragment>
                        );
                      },
                    )}
                  </Grid>
                </Stack>
              </motion.div>
            );
          }),
        )}
      </Grid>

      {decodersSectionShown && (
        <>
          <Divider spaceSize="s" />

          <DecoderSection />
        </>
      )}
    </div>
  );
};

export { FooterSections };
