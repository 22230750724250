import { Badge, SkeletonWrapper, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { cn } from '@carvertical/utils/styling';
import { useRouteData } from 'context/RouteDataProvider';
import { useUserQuotas } from '../hooks';

type UsageCounterProps = {
  fullWidth?: boolean;
  className?: string;
};

const UsageCounter = ({ fullWidth = true, className }: UsageCounterProps) => {
  const { t } = useTranslation();
  const { market } = useRouteData();
  const { data: quotas, quotasPending } = useUserQuotas({ productId: market.products.report });

  return (
    <div className={cn('flex items-center gap-1', fullWidth && 'w-full', className)}>
      <Text variant="m">{t('usage.creditsRemainingLabel')}</Text>

      <SkeletonWrapper loading={quotasPending} className="rounded-full">
        <Badge
          size="s"
          variant={quotas ? 'greenLight' : 'yellowLight'}
          className="min-w-4 justify-center rounded-full text-center text-black"
        >
          <Text variant="m+">{quotas}</Text>
        </Badge>
      </SkeletonWrapper>
    </div>
  );
};

export { UsageCounter };
export type { UsageCounterProps };
