import { omit, pick, mapValues, isNil, omitBy, size } from 'lodash';
import { Container } from 'unstated';
import { captureError } from 'services/errors';
import { getLocale } from '../services/site';
import { getRequest, putRequest } from '../services/request';
import * as dataLayer from '../services/dataLayer';
import { setTrackingEmail } from '../services/recordEvent';
import * as userSegmentService from '../services/userSegment';

const DEFAULT_API_USER_LOCALE = 'en';

function deserialize(user) {
  return mapValues(user, (value) => (isNil(value) ? '' : value));
}

// FIXME: Get quotas from `useUserQuotas` hook after UserContainer refactor
async function fetchQuota(productId) {
  const {
    data: { quotas },
  } = await getRequest('/user/quotas', {
    params: { productId },
  });
  return quotas[productId] || 0;
}

export default class UserContainer extends Container {
  state = {
    loading: true,
  };

  get loading() {
    return this.state.loading;
  }

  get id() {
    return this.state.id;
  }

  get creditCount() {
    return Number(this.state.quota);
  }

  get hasCredits() {
    return this.creditCount > 0;
  }

  get level() {
    return this.state.userLevel;
  }

  get details() {
    return this.createDetailsSubsetFrom(this.state);
  }

  get segment() {
    return this.state.segment;
  }

  get logo() {
    return { logoUrl: this.state.logoUrl, logoStatus: this.state.logoStatus };
  }

  get userType() {
    return this.state.userType;
  }

  createDetailsSubsetFrom(data) {
    return pick(data, [
      'userType',
      'username',
      'firstName',
      'username',
      'lastName',
      'companyName',
      'address',
      'entityCode',
      'vatCode',
      'createdAt',
      'email',
    ]);
  }

  hasDetails() {
    return size(this.details) > 1;
  }

  reset() {
    this.state = {
      loading: true,
    };
    this.setState({});
  }

  fetch = async (guestId, { products }) => {
    const config = guestId ? { headers: { 'X-Guest-Id': guestId } } : {};
    const { data } = await getRequest('/user', config);
    const quota = await fetchQuota(products.report);

    const user = deserialize(data);

    delete user.quotas; // legacy quotas
    user.quota = quota;

    user.locale = this.setPreferredLocale(user);
    user.segment = this.setPreferredSegment(user);

    setTrackingEmail(user.email);
    dataLayer.push('setUser', { userId: user.id });

    this.setState({
      loading: false,
      ...omitBy(user, (value) => isNil(value) || value === ''),
    });
  };

  update = async (newValues, { silent = false } = {}) => {
    newValues = omit(newValues, ['id']);
    await putRequest('/user', newValues);
    if (!silent) {
      this.setState(newValues);
    }
  };

  setPreferredSegment(user) {
    const segment = userSegmentService.get();
    if (!user.segment && segment) {
      this.update({ segment: segment.userSegment }, { silent: true });
      return segment;
    }

    return user.segment;
  }

  setPreferredLocale(user) {
    const locale = getLocale();
    if (user.locale === locale || user.locale !== DEFAULT_API_USER_LOCALE) {
      return user.locale;
    }
    this.update({ locale }, { silent: true });
    return locale;
  }

  async refreshQuotas(products) {
    try {
      this.setState({
        quota: await fetchQuota(products.report),
      });
    } catch (err) {
      captureError(err);
    }
  }
}

export { DEFAULT_API_USER_LOCALE, fetchQuota };
