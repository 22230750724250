import { AnimationVariant, SCALE_ANIMATION_VARIANTS, Stack, Text } from '@carvertical/ui';
import cx from 'classnames';
import { forwardRef, useEffect, useState, type RefObject, useId } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ExclamationTriangleIconXS } from '@carvertical/icons/react';
import { useAutoAnimate } from 'hooks/useAutoAnimate';
import type { TextAreaProps as RootTextAreaProps } from 'types/component';
import styles from './TextArea.module.scss';

type TextFieldStatus = 'error' | 'success';

type TextAreaProps = {
  label: string;
  error?: boolean;
  // `v2` variant is used to unify UI form components design until
  // @carvertical/ui `Textarea` component is released
  variant?: 'v1' | 'v2';
  labelHidden?: boolean;
  status?: TextFieldStatus;
  message?: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  requiredIndicatorShown?: boolean;
} & RootTextAreaProps;

const MESSAGE_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: 'auto' },
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      disabled = false,
      error,
      id,
      label,
      labelHidden = false,
      message,
      placeholder,
      readOnly = false,
      requiredIndicatorShown = false,
      status,
      variant = 'v1',
      wrapperClassName,
      ...otherProps
    },
    ref,
  ) => {
    const [animatedRootRef] = useAutoAnimate();
    const [animatedInputWrapperRef] = useAutoAnimate() as [RefObject<HTMLDivElement>];
    const [mounted, setMounted] = useState(false);
    const messageId = useId();

    const focusInput = () => {
      animatedInputWrapperRef.current?.querySelector('textarea')?.focus();
    };

    useEffect(() => {
      setMounted(true);
    }, []);

    return (
      <div
        className={cx(
          styles.root,
          styles[variant],
          styles[status ?? ''],
          error && styles.error,
          readOnly && styles.readOnly,
          (disabled || readOnly) && styles.disabled,
        )}
        ref={animatedRootRef}
      >
        <label className={cx(styles.label, labelHidden && styles.hidden)} htmlFor={id}>
          {requiredIndicatorShown && <span aria-hidden="true">* </span>}

          {label}
        </label>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          className={cx(styles.inputWrapper, wrapperClassName)}
          ref={animatedInputWrapperRef}
          onClick={focusInput}
        >
          <textarea
            id={id}
            ref={ref}
            disabled={disabled}
            className={cx(styles.input, className)}
            placeholder={placeholder}
            readOnly={readOnly}
            {...otherProps}
          />
        </div>

        {/* TODO: Use message from @carvertical/ui TextArea component when it's released */}
        <AnimatePresence mode="wait">
          {message && (
            <Stack
              as={motion.div}
              type="horizontal"
              crossAxisAlign="center"
              // @ts-expect-error ts(2332)
              variants={MESSAGE_ANIMATION_VARIANTS}
              animate={AnimationVariant.Visible}
              initial={mounted ? AnimationVariant.Hidden : AnimationVariant.Visible}
            >
              <Stack type="horizontal" crossAxisAlign="center" className={styles.message}>
                {status && (
                  <motion.div
                    variants={SCALE_ANIMATION_VARIANTS}
                    key={`${status}-icon`}
                    transition={{ delay: 0.125 }}
                  >
                    <ExclamationTriangleIconXS className="flex" />
                  </motion.div>
                )}

                <Text variant="s" textColor="inherited" id={messageId} as={motion.p}>
                  {message}
                </Text>
              </Stack>
            </Stack>
          )}
        </AnimatePresence>
      </div>
    );
  },
);

export { TextArea };
export type { TextAreaProps };
