import { a as _t } from "./index.69d44069.js";
import { i as T, c as j, b as Vt, d as Nt, j as ce, a as Ot, $ as Ut, k as Wt, l as P, e as Ht, f as Gt, m as qt, n as Yt, h as Xt } from "./Collection.6227ba77.js";
import { m as R, D as H, a as E, E as de, e as M, F as Zt, G as fe, b as w, H as Jt, I as Qt, i as be, J as eu, p as $e, k as me, l as he, r as tu, j as uu } from "./utils.f8c6b750.js";
import x, { useRef as y, useEffect as I, useMemo as C, useState as z, createContext as L, useCallback as pe, forwardRef as G, useContext as ve } from "react";
function lu(t, e) {
  const u = y(!0), l = y(null);
  I(() => (u.current = !0, () => {
    u.current = !1;
  }), []), I(() => {
    u.current ? u.current = !1 : (!l.current || e.some((i, n) => !Object.is(i, l[n]))) && t(), l.current = e;
  }, e);
}
function k(t, e) {
  let u = t;
  for (T(u, e) && (u = u.parentElement); u && !T(u, e); )
    u = u.parentElement;
  return u || document.scrollingElement || document.documentElement;
}
const iu = Symbol.for("react-aria.i18n.locale"), nu = Symbol.for("react-aria.i18n.strings");
let F;
class _ {
  /** Returns a localized string for the given key and locale. */
  getStringForLocale(e, u) {
    let i = this.getStringsForLocale(u)[e];
    if (!i)
      throw new Error(`Could not find intl message ${e} in ${u} locale`);
    return i;
  }
  /** Returns all localized strings for the given locale. */
  getStringsForLocale(e) {
    let u = this.strings[e];
    return u || (u = ou(e, this.strings, this.defaultLocale), this.strings[e] = u), u;
  }
  static getGlobalDictionaryForPackage(e) {
    if (typeof window > "u")
      return null;
    let u = window[iu];
    if (F === void 0) {
      let i = window[nu];
      if (!i)
        return null;
      F = {};
      for (let n in i)
        F[n] = new _({
          [u]: i[n]
        }, u);
    }
    let l = F == null ? void 0 : F[e];
    if (!l)
      throw new Error(`Strings for package "${e}" were not included by LocalizedStringProvider. Please add it to the list passed to createLocalizedStringDictionary.`);
    return l;
  }
  constructor(e, u = "en-US") {
    this.strings = Object.fromEntries(Object.entries(e).filter(([, l]) => l)), this.defaultLocale = u;
  }
}
function ou(t, e, u = "en-US") {
  if (e[t])
    return e[t];
  let l = ru(t);
  if (e[l])
    return e[l];
  for (let i in e)
    if (i.startsWith(l + "-"))
      return e[i];
  return e[u];
}
function ru(t) {
  return Intl.Locale ? new Intl.Locale(t).language : t.split("-")[0];
}
const Q = /* @__PURE__ */ new Map(), ee = /* @__PURE__ */ new Map();
class su {
  /** Formats a localized string for the given key with the provided variables. */
  format(e, u) {
    let l = this.strings.getStringForLocale(e, this.locale);
    return typeof l == "function" ? l(u, this) : l;
  }
  plural(e, u, l = "cardinal") {
    let i = u["=" + e];
    if (i)
      return typeof i == "function" ? i() : i;
    let n = this.locale + ":" + l, o = Q.get(n);
    o || (o = new Intl.PluralRules(this.locale, {
      type: l
    }), Q.set(n, o));
    let s = o.select(e);
    return i = u[s] || u.other, typeof i == "function" ? i() : i;
  }
  number(e) {
    let u = ee.get(this.locale);
    return u || (u = new Intl.NumberFormat(this.locale), ee.set(this.locale, u)), u.format(e);
  }
  select(e, u) {
    let l = e[u] || e.other;
    return typeof l == "function" ? l() : l;
  }
  constructor(e, u) {
    this.locale = e, this.strings = u;
  }
}
const te = /* @__PURE__ */ new WeakMap();
function au(t) {
  let e = te.get(t);
  return e || (e = new _(t), te.set(t, e)), e;
}
function cu(t, e) {
  return e && _.getGlobalDictionaryForPackage(e) || au(t);
}
function q(t, e) {
  let { locale: u } = j(), l = cu(t, e);
  return C(() => new su(u, l), [
    u,
    l
  ]);
}
let N = /* @__PURE__ */ new Map();
function du(t) {
  let { locale: e } = j(), u = e + (t ? Object.entries(t).sort((i, n) => i[0] < n[0] ? -1 : 1).join() : "");
  if (N.has(u))
    return N.get(u);
  let l = new Intl.Collator(e, t);
  return N.set(u, l), l;
}
const ge = 7e3;
let O = null;
function fu(t, e = "assertive", u = ge) {
  O || (O = new bu()), O.announce(t, e, u);
}
class bu {
  createLog(e) {
    let u = document.createElement("div");
    return u.setAttribute("role", "log"), u.setAttribute("aria-live", e), u.setAttribute("aria-relevant", "additions"), u;
  }
  destroy() {
    this.node && (document.body.removeChild(this.node), this.node = null);
  }
  announce(e, u = "assertive", l = ge) {
    if (!this.node)
      return;
    let i = document.createElement("div");
    i.textContent = e, u === "assertive" ? this.assertiveLog.appendChild(i) : this.politeLog.appendChild(i), e !== "" && setTimeout(() => {
      i.remove();
    }, l);
  }
  clear(e) {
    this.node && ((!e || e === "assertive") && (this.assertiveLog.innerHTML = ""), (!e || e === "polite") && (this.politeLog.innerHTML = ""));
  }
  constructor() {
    this.node = document.createElement("div"), this.node.dataset.liveAnnouncer = "true", Object.assign(this.node.style, {
      border: 0,
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
      whiteSpace: "nowrap"
    }), this.assertiveLog = this.createLog("assertive"), this.node.appendChild(this.assertiveLog), this.politeLog = this.createLog("polite"), this.node.appendChild(this.politeLog), document.body.prepend(this.node);
  }
}
function $u(t) {
  let { id: e, label: u, "aria-labelledby": l, "aria-label": i, labelElementType: n = "label" } = t;
  e = R(e);
  let o = R(), s = {};
  u ? (l = l ? `${o} ${l}` : o, s = {
    id: o,
    htmlFor: n === "label" ? e : void 0
  }) : !l && !i && console.warn("If you do not provide a visible label, you must specify an aria-label or aria-labelledby attribute for accessibility");
  let r = Vt({
    id: e,
    "aria-label": i,
    "aria-labelledby": l
  });
  return {
    labelProps: s,
    fieldProps: r
  };
}
function mu(t) {
  let { description: e, errorMessage: u, isInvalid: l, validationState: i } = t, { labelProps: n, fieldProps: o } = $u(t), s = H([
    !!e,
    !!u,
    l,
    i
  ]), r = H([
    !!e,
    !!u,
    l,
    i
  ]);
  return o = E(o, {
    "aria-describedby": [
      s,
      // Use aria-describedby for error message because aria-errormessage is unsupported using VoiceOver or NVDA. See https://github.com/adobe/react-spectrum/issues/1346#issuecomment-740136268
      r,
      t["aria-describedby"]
    ].filter(Boolean).join(" ") || void 0
  }), {
    labelProps: n,
    fieldProps: o,
    descriptionProps: {
      id: s
    },
    errorMessageProps: {
      id: r
    }
  };
}
class ye {
  isDisabled(e) {
    var u;
    return this.disabledBehavior === "all" && (((u = e.props) === null || u === void 0 ? void 0 : u.isDisabled) || this.disabledKeys.has(e.key));
  }
  getNextKey(e) {
    for (e = this.collection.getKeyAfter(e); e != null; ) {
      let u = this.collection.getItem(e);
      if (u.type === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyAfter(e);
    }
    return null;
  }
  getPreviousKey(e) {
    for (e = this.collection.getKeyBefore(e); e != null; ) {
      let u = this.collection.getItem(e);
      if (u.type === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyBefore(e);
    }
    return null;
  }
  findKey(e, u, l) {
    let i = this.getItem(e);
    if (!i)
      return null;
    let n = i.getBoundingClientRect();
    do
      e = u(e), i = this.getItem(e);
    while (i && l(n, i.getBoundingClientRect()));
    return e;
  }
  isSameRow(e, u) {
    return e.top === u.top || e.left !== u.left;
  }
  isSameColumn(e, u) {
    return e.left === u.left || e.top !== u.top;
  }
  getKeyBelow(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (u) => this.getNextKey(u), this.isSameRow) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (u) => this.getPreviousKey(u), this.isSameRow) : this.getPreviousKey(e);
  }
  getNextColumn(e, u) {
    return u ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyRightOf(e) {
    return this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "rtl") : this.findKey(e, (u) => this.getNextColumn(u, this.direction === "rtl"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "rtl") : null;
  }
  getKeyLeftOf(e) {
    return this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "ltr") : this.findKey(e, (u) => this.getNextColumn(u, this.direction === "ltr"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "ltr") : null;
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    for (; e != null; ) {
      let u = this.collection.getItem(e);
      if ((u == null ? void 0 : u.type) === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyAfter(e);
    }
    return null;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    for (; e != null; ) {
      let u = this.collection.getItem(e);
      if (u.type === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyBefore(e);
    }
    return null;
  }
  getItem(e) {
    return e !== null ? this.ref.current.querySelector(`[data-key="${CSS.escape(e.toString())}"]`) : null;
  }
  getKeyPageAbove(e) {
    let u = this.ref.current, l = this.getItem(e);
    if (!l)
      return null;
    if (!T(u))
      return this.getFirstKey();
    let i = u.getBoundingClientRect(), n = l.getBoundingClientRect();
    if (this.orientation === "horizontal") {
      let o = i.x - u.scrollLeft, s = Math.max(0, n.x - o + n.width - i.width);
      for (; l && n.x - o > s; )
        e = this.getKeyAbove(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    } else {
      let o = i.y - u.scrollTop, s = Math.max(0, n.y - o + n.height - i.height);
      for (; l && n.y - o > s; )
        e = this.getKeyAbove(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    }
    return e ?? this.getFirstKey();
  }
  getKeyPageBelow(e) {
    let u = this.ref.current, l = this.getItem(e);
    if (!l)
      return null;
    if (!T(u))
      return this.getLastKey();
    let i = u.getBoundingClientRect(), n = l.getBoundingClientRect();
    if (this.orientation === "horizontal") {
      let o = i.x - u.scrollLeft, s = Math.min(u.scrollWidth, n.x - o - n.width + i.width);
      for (; l && n.x - o < s; )
        e = this.getKeyBelow(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    } else {
      let o = i.y - u.scrollTop, s = Math.min(u.scrollHeight, n.y - o - n.height + i.height);
      for (; l && n.y - o < s; )
        e = this.getKeyBelow(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    }
    return e ?? this.getLastKey();
  }
  getKeyForSearch(e, u) {
    if (!this.collator)
      return null;
    let l = this.collection, i = u || this.getFirstKey();
    for (; i != null; ) {
      let n = l.getItem(i), o = n.textValue.slice(0, e.length);
      if (n.textValue && this.collator.compare(o, e) === 0)
        return i;
      i = this.getKeyBelow(i);
    }
    return null;
  }
  constructor(...e) {
    if (e.length === 1) {
      let u = e[0];
      this.collection = u.collection, this.ref = u.ref, this.collator = u.collator, this.disabledKeys = u.disabledKeys || /* @__PURE__ */ new Set(), this.disabledBehavior = u.disabledBehavior || "all", this.orientation = u.orientation, this.direction = u.direction, this.layout = u.layout || "stack";
    } else
      this.collection = e[0], this.disabledKeys = e[1], this.ref = e[2], this.collator = e[3], this.layout = "stack", this.orientation = "vertical", this.disabledBehavior = "all";
    this.layout === "stack" && this.orientation === "vertical" && (this.getKeyLeftOf = void 0, this.getKeyRightOf = void 0);
  }
}
function hu(t) {
  let { selectionManager: e, collection: u, disabledKeys: l, ref: i, keyboardDelegate: n } = t, o = du({
    usage: "search",
    sensitivity: "base"
  }), s = e.disabledBehavior, r = C(() => n || new ye({
    collection: u,
    disabledKeys: l,
    disabledBehavior: s,
    ref: i,
    collator: o
  }), [
    n,
    u,
    l,
    i,
    o,
    s
  ]), { collectionProps: a } = Nt({
    ...t,
    ref: i,
    selectionManager: e,
    keyboardDelegate: r
  });
  return {
    listProps: a
  };
}
function pu(t) {
  let e;
  for (let u of t)
    e = u;
  return e;
}
const Y = /* @__PURE__ */ new WeakMap();
function ue(t, e) {
  let { id: u } = Y.get(t);
  if (!u)
    throw new Error("Unknown list");
  return `${u}-${vu(e)}`;
}
function vu(t) {
  return typeof t == "string" ? t.replace(/\s*/g, "") : "" + t;
}
var xe = {};
xe = {
  deselectedItem: (t) => `${t.item} غير المحدد`,
  longPressToSelect: "اضغط مطولًا للدخول إلى وضع التحديد.",
  select: "تحديد",
  selectedAll: "جميع العناصر المحددة.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "لم يتم تحديد عناصر",
    one: () => `${e.number(t.count)} عنصر محدد`,
    other: () => `${e.number(t.count)} عنصر محدد`
  })}.`,
  selectedItem: (t) => `${t.item} المحدد`
};
var Be = {};
Be = {
  deselectedItem: (t) => `${t.item} не е избран.`,
  longPressToSelect: "Натиснете и задръжте за да влезете в избирателен режим.",
  select: "Изберете",
  selectedAll: "Всички елементи са избрани.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Няма избрани елементи",
    one: () => `${e.number(t.count)} избран елемент`,
    other: () => `${e.number(t.count)} избрани елементи`
  })}.`,
  selectedItem: (t) => `${t.item} избран.`
};
var De = {};
De = {
  deselectedItem: (t) => `Položka ${t.item} není vybrána.`,
  longPressToSelect: "Dlouhým stisknutím přejdete do režimu výběru.",
  select: "Vybrat",
  selectedAll: "Vybrány všechny položky.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nevybrány žádné položky",
    one: () => `Vybrána ${e.number(t.count)} položka`,
    other: () => `Vybráno ${e.number(t.count)} položek`
  })}.`,
  selectedItem: (t) => `Vybrána položka ${t.item}.`
};
var Ce = {};
Ce = {
  deselectedItem: (t) => `${t.item} ikke valgt.`,
  longPressToSelect: "Lav et langt tryk for at aktivere valgtilstand.",
  select: "Vælg",
  selectedAll: "Alle elementer valgt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ingen elementer valgt",
    one: () => `${e.number(t.count)} element valgt`,
    other: () => `${e.number(t.count)} elementer valgt`
  })}.`,
  selectedItem: (t) => `${t.item} valgt.`
};
var Ee = {};
Ee = {
  deselectedItem: (t) => `${t.item} nicht ausgewählt.`,
  longPressToSelect: "Gedrückt halten, um Auswahlmodus zu öffnen.",
  select: "Auswählen",
  selectedAll: "Alle Elemente ausgewählt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Keine Elemente ausgewählt",
    one: () => `${e.number(t.count)} Element ausgewählt`,
    other: () => `${e.number(t.count)} Elemente ausgewählt`
  })}.`,
  selectedItem: (t) => `${t.item} ausgewählt.`
};
var Se = {};
Se = {
  deselectedItem: (t) => `Δεν επιλέχθηκε το στοιχείο ${t.item}.`,
  longPressToSelect: "Πατήστε παρατεταμένα για να μπείτε σε λειτουργία επιλογής.",
  select: "Επιλογή",
  selectedAll: "Επιλέχθηκαν όλα τα στοιχεία.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Δεν επιλέχθηκαν στοιχεία",
    one: () => `Επιλέχθηκε ${e.number(t.count)} στοιχείο`,
    other: () => `Επιλέχθηκαν ${e.number(t.count)} στοιχεία`
  })}.`,
  selectedItem: (t) => `Επιλέχθηκε το στοιχείο ${t.item}.`
};
var Ae = {};
Ae = {
  deselectedItem: (t) => `${t.item} not selected.`,
  select: "Select",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "No items selected",
    one: () => `${e.number(t.count)} item selected`,
    other: () => `${e.number(t.count)} items selected`
  })}.`,
  selectedAll: "All items selected.",
  selectedItem: (t) => `${t.item} selected.`,
  longPressToSelect: "Long press to enter selection mode."
};
var Ke = {};
Ke = {
  deselectedItem: (t) => `${t.item} no seleccionado.`,
  longPressToSelect: "Mantenga pulsado para abrir el modo de selección.",
  select: "Seleccionar",
  selectedAll: "Todos los elementos seleccionados.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ningún elemento seleccionado",
    one: () => `${e.number(t.count)} elemento seleccionado`,
    other: () => `${e.number(t.count)} elementos seleccionados`
  })}.`,
  selectedItem: (t) => `${t.item} seleccionado.`
};
var we = {};
we = {
  deselectedItem: (t) => `${t.item} pole valitud.`,
  longPressToSelect: "Valikurežiimi sisenemiseks vajutage pikalt.",
  select: "Vali",
  selectedAll: "Kõik üksused valitud.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Üksusi pole valitud",
    one: () => `${e.number(t.count)} üksus valitud`,
    other: () => `${e.number(t.count)} üksust valitud`
  })}.`,
  selectedItem: (t) => `${t.item} valitud.`
};
var Ie = {};
Ie = {
  deselectedItem: (t) => `Kohdetta ${t.item} ei valittu.`,
  longPressToSelect: "Siirry valintatilaan painamalla pitkään.",
  select: "Valitse",
  selectedAll: "Kaikki kohteet valittu.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ei yhtään kohdetta valittu",
    one: () => `${e.number(t.count)} kohde valittu`,
    other: () => `${e.number(t.count)} kohdetta valittu`
  })}.`,
  selectedItem: (t) => `${t.item} valittu.`
};
var Pe = {};
Pe = {
  deselectedItem: (t) => `${t.item} non sélectionné.`,
  longPressToSelect: "Appuyez de manière prolongée pour passer en mode de sélection.",
  select: "Sélectionner",
  selectedAll: "Tous les éléments sélectionnés.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Aucun élément sélectionné",
    one: () => `${e.number(t.count)} élément sélectionné`,
    other: () => `${e.number(t.count)} éléments sélectionnés`
  })}.`,
  selectedItem: (t) => `${t.item} sélectionné.`
};
var ke = {};
ke = {
  deselectedItem: (t) => `${t.item} לא נבחר.`,
  longPressToSelect: "הקשה ארוכה לכניסה למצב בחירה.",
  select: "בחר",
  selectedAll: "כל הפריטים נבחרו.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "לא נבחרו פריטים",
    one: () => `פריט ${e.number(t.count)} נבחר`,
    other: () => `${e.number(t.count)} פריטים נבחרו`
  })}.`,
  selectedItem: (t) => `${t.item} נבחר.`
};
var Fe = {};
Fe = {
  deselectedItem: (t) => `Stavka ${t.item} nije odabrana.`,
  longPressToSelect: "Dugo pritisnite za ulazak u način odabira.",
  select: "Odaberite",
  selectedAll: "Odabrane su sve stavke.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nije odabrana nijedna stavka",
    one: () => `Odabrana je ${e.number(t.count)} stavka`,
    other: () => `Odabrano je ${e.number(t.count)} stavki`
  })}.`,
  selectedItem: (t) => `Stavka ${t.item} je odabrana.`
};
var ze = {};
ze = {
  deselectedItem: (t) => `${t.item} nincs kijelölve.`,
  longPressToSelect: "Nyomja hosszan a kijelöléshez.",
  select: "Kijelölés",
  selectedAll: "Az összes elem kijelölve.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Egy elem sincs kijelölve",
    one: () => `${e.number(t.count)} elem kijelölve`,
    other: () => `${e.number(t.count)} elem kijelölve`
  })}.`,
  selectedItem: (t) => `${t.item} kijelölve.`
};
var Me = {};
Me = {
  deselectedItem: (t) => `${t.item} non selezionato.`,
  longPressToSelect: "Premi a lungo per passare alla modalità di selezione.",
  select: "Seleziona",
  selectedAll: "Tutti gli elementi selezionati.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nessun elemento selezionato",
    one: () => `${e.number(t.count)} elemento selezionato`,
    other: () => `${e.number(t.count)} elementi selezionati`
  })}.`,
  selectedItem: (t) => `${t.item} selezionato.`
};
var Le = {};
Le = {
  deselectedItem: (t) => `${t.item} が選択されていません。`,
  longPressToSelect: "長押しして選択モードを開きます。",
  select: "選択",
  selectedAll: "すべての項目を選択しました。",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "項目が選択されていません",
    one: () => `${e.number(t.count)} 項目を選択しました`,
    other: () => `${e.number(t.count)} 項目を選択しました`
  })}。`,
  selectedItem: (t) => `${t.item} を選択しました。`
};
var Te = {};
Te = {
  deselectedItem: (t) => `${t.item}이(가) 선택되지 않았습니다.`,
  longPressToSelect: "선택 모드로 들어가려면 길게 누르십시오.",
  select: "선택",
  selectedAll: "모든 항목이 선택되었습니다.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "선택된 항목이 없습니다",
    one: () => `${e.number(t.count)}개 항목이 선택되었습니다`,
    other: () => `${e.number(t.count)}개 항목이 선택되었습니다`
  })}.`,
  selectedItem: (t) => `${t.item}이(가) 선택되었습니다.`
};
var Re = {};
Re = {
  deselectedItem: (t) => `${t.item} nepasirinkta.`,
  longPressToSelect: "Norėdami įjungti pasirinkimo režimą, paspauskite ir palaikykite.",
  select: "Pasirinkti",
  selectedAll: "Pasirinkti visi elementai.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nepasirinktas nė vienas elementas",
    one: () => `Pasirinktas ${e.number(t.count)} elementas`,
    other: () => `Pasirinkta elementų: ${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `Pasirinkta: ${t.item}.`
};
var je = {};
je = {
  deselectedItem: (t) => `Vienums ${t.item} nav atlasīts.`,
  longPressToSelect: "Ilgi turiet nospiestu. lai ieslēgtu atlases režīmu.",
  select: "Atlasīt",
  selectedAll: "Atlasīti visi vienumi.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nav atlasīts neviens vienums",
    one: () => `Atlasīto vienumu skaits: ${e.number(t.count)}`,
    other: () => `Atlasīto vienumu skaits: ${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `Atlasīts vienums ${t.item}.`
};
var _e = {};
_e = {
  deselectedItem: (t) => `${t.item} er ikke valgt.`,
  longPressToSelect: "Bruk et langt trykk for å gå inn i valgmodus.",
  select: "Velg",
  selectedAll: "Alle elementer er valgt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ingen elementer er valgt",
    one: () => `${e.number(t.count)} element er valgt`,
    other: () => `${e.number(t.count)} elementer er valgt`
  })}.`,
  selectedItem: (t) => `${t.item} er valgt.`
};
var Ve = {};
Ve = {
  deselectedItem: (t) => `${t.item} niet geselecteerd.`,
  longPressToSelect: "Druk lang om de selectiemodus te openen.",
  select: "Selecteren",
  selectedAll: "Alle items geselecteerd.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Geen items geselecteerd",
    one: () => `${e.number(t.count)} item geselecteerd`,
    other: () => `${e.number(t.count)} items geselecteerd`
  })}.`,
  selectedItem: (t) => `${t.item} geselecteerd.`
};
var Ne = {};
Ne = {
  deselectedItem: (t) => `Nie zaznaczono ${t.item}.`,
  longPressToSelect: "Naciśnij i przytrzymaj, aby wejść do trybu wyboru.",
  select: "Zaznacz",
  selectedAll: "Wszystkie zaznaczone elementy.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nie zaznaczono żadnych elementów",
    one: () => `${e.number(t.count)} zaznaczony element`,
    other: () => `${e.number(t.count)} zaznaczonych elementów`
  })}.`,
  selectedItem: (t) => `Zaznaczono ${t.item}.`
};
var Oe = {};
Oe = {
  deselectedItem: (t) => `${t.item} não selecionado.`,
  longPressToSelect: "Mantenha pressionado para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${e.number(t.count)} item selecionado`,
    other: () => `${e.number(t.count)} itens selecionados`
  })}.`,
  selectedItem: (t) => `${t.item} selecionado.`
};
var Ue = {};
Ue = {
  deselectedItem: (t) => `${t.item} não selecionado.`,
  longPressToSelect: "Prima continuamente para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${e.number(t.count)} item selecionado`,
    other: () => `${e.number(t.count)} itens selecionados`
  })}.`,
  selectedItem: (t) => `${t.item} selecionado.`
};
var We = {};
We = {
  deselectedItem: (t) => `${t.item} neselectat.`,
  longPressToSelect: "Apăsați lung pentru a intra în modul de selectare.",
  select: "Selectare",
  selectedAll: "Toate elementele selectate.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Niciun element selectat",
    one: () => `${e.number(t.count)} element selectat`,
    other: () => `${e.number(t.count)} elemente selectate`
  })}.`,
  selectedItem: (t) => `${t.item} selectat.`
};
var He = {};
He = {
  deselectedItem: (t) => `${t.item} не выбрано.`,
  longPressToSelect: "Нажмите и удерживайте для входа в режим выбора.",
  select: "Выбрать",
  selectedAll: "Выбраны все элементы.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Нет выбранных элементов",
    one: () => `${e.number(t.count)} элемент выбран`,
    other: () => `${e.number(t.count)} элементов выбрано`
  })}.`,
  selectedItem: (t) => `${t.item} выбрано.`
};
var Ge = {};
Ge = {
  deselectedItem: (t) => `Nevybraté položky: ${t.item}.`,
  longPressToSelect: "Dlhším stlačením prejdite do režimu výberu.",
  select: "Vybrať",
  selectedAll: "Všetky vybraté položky.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Žiadne vybraté položky",
    one: () => `${e.number(t.count)} vybratá položka`,
    other: () => `Počet vybratých položiek:${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `Vybraté položky: ${t.item}.`
};
var qe = {};
qe = {
  deselectedItem: (t) => `Element ${t.item} ni izbran.`,
  longPressToSelect: "Za izbirni način pritisnite in dlje časa držite.",
  select: "Izberite",
  selectedAll: "Vsi elementi so izbrani.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Noben element ni izbran",
    one: () => `${e.number(t.count)} element je izbran`,
    other: () => `${e.number(t.count)} elementov je izbranih`
  })}.`,
  selectedItem: (t) => `Element ${t.item} je izbran.`
};
var Ye = {};
Ye = {
  deselectedItem: (t) => `${t.item} nije izabrano.`,
  longPressToSelect: "Dugo pritisnite za ulazak u režim biranja.",
  select: "Izaberite",
  selectedAll: "Izabrane su sve stavke.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nije izabrana nijedna stavka",
    one: () => `Izabrana je ${e.number(t.count)} stavka`,
    other: () => `Izabrano je ${e.number(t.count)} stavki`
  })}.`,
  selectedItem: (t) => `${t.item} je izabrano.`
};
var Xe = {};
Xe = {
  deselectedItem: (t) => `${t.item} ej markerat.`,
  longPressToSelect: "Tryck länge när du vill öppna väljarläge.",
  select: "Markera",
  selectedAll: "Alla markerade objekt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Inga markerade objekt",
    one: () => `${e.number(t.count)} markerat objekt`,
    other: () => `${e.number(t.count)} markerade objekt`
  })}.`,
  selectedItem: (t) => `${t.item} markerat.`
};
var Ze = {};
Ze = {
  deselectedItem: (t) => `${t.item} seçilmedi.`,
  longPressToSelect: "Seçim moduna girmek için uzun basın.",
  select: "Seç",
  selectedAll: "Tüm ögeler seçildi.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Hiçbir öge seçilmedi",
    one: () => `${e.number(t.count)} öge seçildi`,
    other: () => `${e.number(t.count)} öge seçildi`
  })}.`,
  selectedItem: (t) => `${t.item} seçildi.`
};
var Je = {};
Je = {
  deselectedItem: (t) => `${t.item} не вибрано.`,
  longPressToSelect: "Виконайте довге натиснення, щоб перейти в режим вибору.",
  select: "Вибрати",
  selectedAll: "Усі елементи вибрано.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Жодних елементів не вибрано",
    one: () => `${e.number(t.count)} елемент вибрано`,
    other: () => `Вибрано елементів: ${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `${t.item} вибрано.`
};
var Qe = {};
Qe = {
  deselectedItem: (t) => `未选择 ${t.item}。`,
  longPressToSelect: "长按以进入选择模式。",
  select: "选择",
  selectedAll: "已选择所有项目。",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "未选择项目",
    one: () => `已选择 ${e.number(t.count)} 个项目`,
    other: () => `已选择 ${e.number(t.count)} 个项目`
  })}。`,
  selectedItem: (t) => `已选择 ${t.item}。`
};
var et = {};
et = {
  deselectedItem: (t) => `未選取「${t.item}」。`,
  longPressToSelect: "長按以進入選擇模式。",
  select: "選取",
  selectedAll: "已選取所有項目。",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "未選取任何項目",
    one: () => `已選取 ${e.number(t.count)} 個項目`,
    other: () => `已選取 ${e.number(t.count)} 個項目`
  })}。`,
  selectedItem: (t) => `已選取「${t.item}」。`
};
var X = {};
X = {
  "ar-AE": xe,
  "bg-BG": Be,
  "cs-CZ": De,
  "da-DK": Ce,
  "de-DE": Ee,
  "el-GR": Se,
  "en-US": Ae,
  "es-ES": Ke,
  "et-EE": we,
  "fi-FI": Ie,
  "fr-FR": Pe,
  "he-IL": ke,
  "hr-HR": Fe,
  "hu-HU": ze,
  "it-IT": Me,
  "ja-JP": Le,
  "ko-KR": Te,
  "lt-LT": Re,
  "lv-LV": je,
  "nb-NO": _e,
  "nl-NL": Ve,
  "pl-PL": Ne,
  "pt-BR": Oe,
  "pt-PT": Ue,
  "ro-RO": We,
  "ru-RU": He,
  "sk-SK": Ge,
  "sl-SI": qe,
  "sr-SP": Ye,
  "sv-SE": Xe,
  "tr-TR": Ze,
  "uk-UA": Je,
  "zh-CN": Qe,
  "zh-TW": et
};
function gu(t) {
  return t && t.__esModule ? t.default : t;
}
function yu(t, e) {
  let { getRowText: u = (o) => {
    var s, r, a, d;
    return (d = (s = (r = e.collection).getTextValue) === null || s === void 0 ? void 0 : s.call(r, o)) !== null && d !== void 0 ? d : (a = e.collection.getItem(o)) === null || a === void 0 ? void 0 : a.textValue;
  } } = t, l = q(gu(X), "@react-aria/grid"), i = e.selectionManager.rawSelection, n = y(i);
  lu(() => {
    var o;
    if (!e.selectionManager.isFocused) {
      n.current = i;
      return;
    }
    let s = le(i, n.current), r = le(n.current, i), a = e.selectionManager.selectionBehavior === "replace", d = [];
    if (e.selectionManager.selectedKeys.size === 1 && a) {
      if (e.collection.getItem(e.selectionManager.selectedKeys.keys().next().value)) {
        let c = u(e.selectionManager.selectedKeys.keys().next().value);
        c && d.push(l.format("selectedItem", {
          item: c
        }));
      }
    } else if (s.size === 1 && r.size === 0) {
      let c = u(s.keys().next().value);
      c && d.push(l.format("selectedItem", {
        item: c
      }));
    } else if (r.size === 1 && s.size === 0 && e.collection.getItem(r.keys().next().value)) {
      let c = u(r.keys().next().value);
      c && d.push(l.format("deselectedItem", {
        item: c
      }));
    }
    e.selectionManager.selectionMode === "multiple" && (d.length === 0 || i === "all" || i.size > 1 || n.current === "all" || ((o = n.current) === null || o === void 0 ? void 0 : o.size) > 1) && d.push(i === "all" ? l.format("selectedAll") : l.format("selectedCount", {
      count: i.size
    })), d.length > 0 && fu(d.join(" ")), n.current = i;
  }, [
    i
  ]);
}
function le(t, e) {
  let u = /* @__PURE__ */ new Set();
  if (t === "all" || e === "all")
    return u;
  for (let l of t.keys())
    e.has(l) || u.add(l);
  return u;
}
function xu(t) {
  return t && t.__esModule ? t.default : t;
}
function Bu(t) {
  let e = q(xu(X), "@react-aria/grid"), u = de(), l = (u === "pointer" || u === "virtual" || u == null) && typeof window < "u" && "ontouchstart" in window, i = C(() => {
    let o = t.selectionManager.selectionMode, s = t.selectionManager.selectionBehavior, r;
    return l && (r = e.format("longPressToSelect")), s === "replace" && o !== "none" && t.hasItemActions ? r : void 0;
  }, [
    t.selectionManager.selectionMode,
    t.selectionManager.selectionBehavior,
    t.hasItemActions,
    e,
    l
  ]);
  return ce(i);
}
function Du(t, e, u) {
  let { isVirtualized: l, keyboardDelegate: i, onAction: n, linkBehavior: o = "action" } = t;
  !t["aria-label"] && !t["aria-labelledby"] && console.warn("An aria-label or aria-labelledby prop is required for accessibility.");
  let { listProps: s } = hu({
    selectionManager: e.selectionManager,
    collection: e.collection,
    disabledKeys: e.disabledKeys,
    ref: u,
    keyboardDelegate: i,
    isVirtualized: l,
    selectOnFocus: e.selectionManager.selectionBehavior === "replace",
    shouldFocusWrap: t.shouldFocusWrap,
    linkBehavior: o
  }), r = R(t.id);
  Y.set(e, {
    id: r,
    onAction: n,
    linkBehavior: o
  });
  let a = Bu({
    selectionManager: e.selectionManager,
    hasItemActions: !!n
  }), d = Ot(u, {
    isDisabled: e.collection.size !== 0
  }), c = M(t, {
    labelable: !0
  }), f = E(
    c,
    {
      role: "grid",
      id: r,
      "aria-multiselectable": e.selectionManager.selectionMode === "multiple" ? "true" : void 0
    },
    // If collection is empty, make sure the grid is tabbable unless there is a child tabbable element.
    e.collection.size === 0 ? {
      tabIndex: d ? -1 : 0
    } : s,
    a
  );
  return l && (f["aria-rowcount"] = e.collection.size, f["aria-colcount"] = 1), yu({}, e), {
    gridProps: f
  };
}
const ie = {
  expand: {
    ltr: "ArrowRight",
    rtl: "ArrowLeft"
  },
  collapse: {
    ltr: "ArrowLeft",
    rtl: "ArrowRight"
  }
};
function Cu(t, e, u) {
  var l, i;
  let { node: n, isVirtualized: o, shouldSelectOnPressUp: s } = t, { direction: r } = j(), { onAction: a, linkBehavior: d } = Y.get(e), c = H(), f = y(null), b = () => {
    var $;
    (f.current != null && n.key !== f.current || !(!(($ = u.current) === null || $ === void 0) && $.contains(document.activeElement))) && w(u.current);
  }, h = {}, p, m = e.selectionManager.isLink(n.key);
  n != null && "expandedKeys" in e && (p = [
    ...e.collection.getChildren(n.key)
  ].length > 1, a == null && !m && e.selectionManager.selectionMode === "none" && p && (a = () => e.toggleKey(n.key)), h = {
    "aria-expanded": p ? e.expandedKeys.has(n.key) : void 0,
    "aria-level": n.level + 1,
    "aria-posinset": (n == null ? void 0 : n.index) + 1,
    "aria-setsize": n.level > 0 ? pu(e.collection.getChildren(n == null ? void 0 : n.parentKey)).index + 1 : [
      ...e.collection
    ].filter((B) => B.level === 0).at(-1).index + 1
  });
  let { itemProps: g, ...S } = Ut({
    selectionManager: e.selectionManager,
    key: n.key,
    ref: u,
    isVirtualized: o,
    shouldSelectOnPressUp: s,
    onAction: a || !((l = n.props) === null || l === void 0) && l.onAction ? Zt((i = n.props) === null || i === void 0 ? void 0 : i.onAction, a ? () => a(n.key) : void 0) : void 0,
    focus: b,
    linkBehavior: d
  }), v = ($) => {
    if (!$.currentTarget.contains($.target))
      return;
    let B = Wt(u.current);
    if (B.currentNode = document.activeElement, "expandedKeys" in e && document.activeElement === u.current) {
      if ($.key === ie.expand[r] && e.selectionManager.focusedKey === n.key && p && !e.expandedKeys.has(n.key)) {
        e.toggleKey(n.key), $.stopPropagation();
        return;
      } else if ($.key === ie.collapse[r] && e.selectionManager.focusedKey === n.key && p && e.expandedKeys.has(n.key)) {
        e.toggleKey(n.key), $.stopPropagation();
        return;
      }
    }
    switch ($.key) {
      case "ArrowLeft": {
        let A = r === "rtl" ? B.nextNode() : B.previousNode();
        if (A)
          $.preventDefault(), $.stopPropagation(), w(A), P(A, {
            containingElement: k(u.current)
          });
        else if ($.preventDefault(), $.stopPropagation(), r === "rtl")
          w(u.current), P(u.current, {
            containingElement: k(u.current)
          });
        else {
          B.currentNode = u.current;
          let K = ne(B);
          K && (w(K), P(K, {
            containingElement: k(u.current)
          }));
        }
        break;
      }
      case "ArrowRight": {
        let A = r === "rtl" ? B.previousNode() : B.nextNode();
        if (A)
          $.preventDefault(), $.stopPropagation(), w(A), P(A, {
            containingElement: k(u.current)
          });
        else if ($.preventDefault(), $.stopPropagation(), r === "ltr")
          w(u.current), P(u.current, {
            containingElement: k(u.current)
          });
        else {
          B.currentNode = u.current;
          let K = ne(B);
          K && (w(K), P(K, {
            containingElement: k(u.current)
          }));
        }
        break;
      }
      case "ArrowUp":
      case "ArrowDown":
        !$.altKey && u.current.contains($.target) && ($.stopPropagation(), $.preventDefault(), u.current.parentElement.dispatchEvent(new KeyboardEvent($.nativeEvent.type, $.nativeEvent)));
        break;
    }
  }, V = ($) => {
    if (f.current = n.key, $.target !== u.current) {
      Jt() || e.selectionManager.setFocusedKey(n.key);
      return;
    }
  }, Rt = S.hasAction ? fe(n.props) : {}, J = E(g, Rt, {
    role: "row",
    onKeyDownCapture: v,
    onFocus: V,
    // 'aria-label': [(node.textValue || undefined), rowAnnouncement].filter(Boolean).join(', '),
    "aria-label": n.textValue || void 0,
    "aria-selected": e.selectionManager.canSelectItem(n.key) ? e.selectionManager.isSelected(n.key) : void 0,
    "aria-disabled": e.selectionManager.isDisabled(n.key) || void 0,
    "aria-labelledby": c && n.textValue ? `${ue(e, n.key)} ${c}` : void 0,
    id: ue(e, n.key)
  });
  o && (J["aria-rowindex"] = n.index + 1);
  let jt = {
    role: "gridcell",
    "aria-colindex": 1
  };
  return {
    rowProps: {
      ...E(J, h)
    },
    gridCellProps: jt,
    descriptionProps: {
      id: c
    },
    ...S
  };
}
function ne(t) {
  let e, u;
  do
    u = t.lastChild(), u && (e = u);
  while (u);
  return e;
}
const tt = /* @__PURE__ */ new WeakMap();
function Eu(t, e, u) {
  let { direction: l } = j(), i = t.keyboardDelegate || new ye({
    collection: e.collection,
    ref: u,
    orientation: "horizontal",
    direction: l,
    disabledKeys: e.disabledKeys,
    disabledBehavior: e.selectionManager.disabledBehavior
  }), { labelProps: n, fieldProps: o, descriptionProps: s, errorMessageProps: r } = mu({
    ...t,
    labelElementType: "span"
  }), { gridProps: a } = Du({
    ...t,
    ...o,
    keyboardDelegate: i,
    shouldFocusWrap: !0,
    linkBehavior: "override"
  }, e, u), [d, c] = z(!1), { focusWithinProps: f } = Qt({
    onFocusWithinChange: c
  }), b = M(t), h = y(e.collection.size);
  return I(() => {
    u.current && h.current > 0 && e.collection.size === 0 && d && u.current.focus(), h.current = e.collection.size;
  }, [
    e.collection.size,
    d,
    u
  ]), tt.set(e, {
    onRemove: t.onRemove
  }), {
    gridProps: E(a, b, {
      role: e.collection.size ? "grid" : null,
      "aria-atomic": !1,
      "aria-relevant": "additions",
      "aria-live": d ? "polite" : "off",
      ...f,
      ...o
    }),
    labelProps: n,
    descriptionProps: s,
    errorMessageProps: r
  };
}
var ut = {};
ut = {
  removeButtonLabel: "إزالة",
  removeDescription: "اضغط على مفتاح DELETE لإزالة علامة."
};
var lt = {};
lt = {
  removeButtonLabel: "Премахване",
  removeDescription: "Натиснете Delete, за да премахнете маркера."
};
var it = {};
it = {
  removeButtonLabel: "Odebrat",
  removeDescription: "Stisknutím klávesy Delete odeberete značku."
};
var nt = {};
nt = {
  removeButtonLabel: "Fjern",
  removeDescription: "Tryk på Slet for at fjerne tag."
};
var ot = {};
ot = {
  removeButtonLabel: "Entfernen",
  removeDescription: "Auf „Löschen“ drücken, um das Tag zu entfernen."
};
var rt = {};
rt = {
  removeButtonLabel: "Κατάργηση",
  removeDescription: "Πατήστε Διαγραφή για να καταργήσετε την ετικέτα."
};
var st = {};
st = {
  removeDescription: "Press Delete to remove tag.",
  removeButtonLabel: "Remove"
};
var at = {};
at = {
  removeButtonLabel: "Quitar",
  removeDescription: "Pulse Eliminar para quitar la etiqueta."
};
var ct = {};
ct = {
  removeButtonLabel: "Eemalda",
  removeDescription: "Sildi eemaldamiseks vajutage kustutusklahvi Delete."
};
var dt = {};
dt = {
  removeButtonLabel: "Poista",
  removeDescription: "Poista tunniste painamalla Poista-painiketta."
};
var ft = {};
ft = {
  removeButtonLabel: "Supprimer",
  removeDescription: "Appuyez sur Supprimer pour supprimer l’étiquette."
};
var bt = {};
bt = {
  removeButtonLabel: "הסר",
  removeDescription: "לחץ על מחק כדי להסיר תג."
};
var $t = {};
$t = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Delete za uklanjanje oznake."
};
var mt = {};
mt = {
  removeButtonLabel: "Eltávolítás",
  removeDescription: "Nyomja meg a Delete billentyűt a címke eltávolításához."
};
var ht = {};
ht = {
  removeButtonLabel: "Rimuovi",
  removeDescription: "Premi Elimina per rimuovere il tag."
};
var pt = {};
pt = {
  removeButtonLabel: "削除",
  removeDescription: "タグを削除するには、Delete キーを押します。"
};
var vt = {};
vt = {
  removeButtonLabel: "제거",
  removeDescription: "태그를 제거하려면 Delete 키를 누르십시오."
};
var gt = {};
gt = {
  removeButtonLabel: "Pašalinti",
  removeDescription: "Norėdami pašalinti žymą, paspauskite „Delete“ klavišą."
};
var yt = {};
yt = {
  removeButtonLabel: "Noņemt",
  removeDescription: "Nospiediet Delete [Dzēst], lai noņemtu tagu."
};
var xt = {};
xt = {
  removeButtonLabel: "Fjern",
  removeDescription: "Trykk på Slett for å fjerne taggen."
};
var Bt = {};
Bt = {
  removeButtonLabel: "Verwijderen",
  removeDescription: "Druk op Verwijderen om de tag te verwijderen."
};
var Dt = {};
Dt = {
  removeButtonLabel: "Usuń",
  removeDescription: "Naciśnij Usuń, aby usunąć znacznik."
};
var Ct = {};
Ct = {
  removeButtonLabel: "Remover",
  removeDescription: "Pressione Delete para remover a tag."
};
var Et = {};
Et = {
  removeButtonLabel: "Eliminar",
  removeDescription: "Prima Delete para eliminar a tag."
};
var St = {};
St = {
  removeButtonLabel: "Îndepărtaţi",
  removeDescription: "Apăsați pe Delete (Ștergere) pentru a elimina eticheta."
};
var At = {};
At = {
  removeButtonLabel: "Удалить",
  removeDescription: "Нажмите DELETE, чтобы удалить тег."
};
var Kt = {};
Kt = {
  removeButtonLabel: "Odstrániť",
  removeDescription: "Ak chcete odstrániť značku, stlačte kláves Delete."
};
var wt = {};
wt = {
  removeButtonLabel: "Odstrani",
  removeDescription: "Pritisnite Delete, da odstranite oznako."
};
var It = {};
It = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Obriši da biste uklonili oznaku."
};
var Pt = {};
Pt = {
  removeButtonLabel: "Ta bort",
  removeDescription: "Tryck på Radera för att ta bort taggen."
};
var kt = {};
kt = {
  removeButtonLabel: "Kaldır",
  removeDescription: "Etiketi kaldırmak için Sil tuşuna basın."
};
var Ft = {};
Ft = {
  removeButtonLabel: "Вилучити",
  removeDescription: "Натисніть Delete, щоб вилучити тег."
};
var zt = {};
zt = {
  removeButtonLabel: "删除",
  removeDescription: "按下“删除”以删除标记。"
};
var Mt = {};
Mt = {
  removeButtonLabel: "移除",
  removeDescription: "按 Delete 鍵以移除標記。"
};
var Lt = {};
Lt = {
  "ar-AE": ut,
  "bg-BG": lt,
  "cs-CZ": it,
  "da-DK": nt,
  "de-DE": ot,
  "el-GR": rt,
  "en-US": st,
  "es-ES": at,
  "et-EE": ct,
  "fi-FI": dt,
  "fr-FR": ft,
  "he-IL": bt,
  "hr-HR": $t,
  "hu-HU": mt,
  "it-IT": ht,
  "ja-JP": pt,
  "ko-KR": vt,
  "lt-LT": gt,
  "lv-LV": yt,
  "nb-NO": xt,
  "nl-NL": Bt,
  "pl-PL": Dt,
  "pt-BR": Ct,
  "pt-PT": Et,
  "ro-RO": St,
  "ru-RU": At,
  "sk-SK": Kt,
  "sl-SI": wt,
  "sr-SP": It,
  "sv-SE": Pt,
  "tr-TR": kt,
  "uk-UA": Ft,
  "zh-CN": zt,
  "zh-TW": Mt
};
function Su(t) {
  return t && t.__esModule ? t.default : t;
}
function Au(t, e, u) {
  let { item: l } = t, i = q(Su(Lt), "@react-aria/tag"), n = R(), { onRemove: o } = tt.get(e) || {}, { rowProps: s, gridCellProps: r, ...a } = Cu({
    node: l
  }, e, u);
  delete s.onKeyDownCapture;
  let { descriptionProps: d, ...c } = a, f = (v) => {
    (v.key === "Delete" || v.key === "Backspace") && (v.preventDefault(), e.selectionManager.isSelected(l.key) ? o == null || o(new Set(e.selectionManager.selectedKeys)) : o == null || o(/* @__PURE__ */ new Set([
      l.key
    ])));
  }, b = de();
  b === "virtual" && typeof window < "u" && "ontouchstart" in window && (b = "pointer");
  let h = o && (b === "keyboard" || b === "virtual") ? i.format("removeDescription") : "", p = ce(h), m = l.key === e.selectionManager.focusedKey, g = M(l.props), S = fe(l.props);
  return {
    removeButtonProps: {
      "aria-label": i.format("removeButtonLabel"),
      "aria-labelledby": `${n} ${s.id}`,
      id: n,
      onPress: () => o ? o(/* @__PURE__ */ new Set([
        l.key
      ])) : null,
      excludeFromTabOrder: !0
    },
    rowProps: E(s, g, S, {
      tabIndex: m || e.selectionManager.focusedKey == null ? 0 : -1,
      onKeyDown: o ? f : void 0,
      "aria-describedby": p["aria-describedby"]
    }),
    gridCellProps: E(r, {
      "aria-errormessage": t["aria-errormessage"],
      "aria-label": t["aria-label"]
    }),
    ...c,
    allowsRemoving: !!o
  };
}
const Ku = /* @__PURE__ */ L({});
class wu {
  build(e, u) {
    return this.context = u, oe(() => this.iterateCollection(e));
  }
  *iterateCollection(e) {
    let { children: u, items: l } = e;
    if (typeof u == "function") {
      if (!l)
        throw new Error("props.children was a function but props.items is missing");
      for (let i of e.items)
        yield* this.getFullNode({
          value: i
        }, {
          renderer: u
        });
    } else {
      let i = [];
      x.Children.forEach(u, (o) => {
        i.push(o);
      });
      let n = 0;
      for (let o of i) {
        let s = this.getFullNode({
          element: o,
          index: n
        }, {});
        for (let r of s)
          n++, yield r;
      }
    }
  }
  getKey(e, u, l, i) {
    if (e.key != null)
      return e.key;
    if (u.type === "cell" && u.key != null)
      return `${i}${u.key}`;
    let n = u.value;
    if (n != null) {
      var o;
      let s = (o = n.key) !== null && o !== void 0 ? o : n.id;
      if (s == null)
        throw new Error("No key found for item");
      return s;
    }
    return i ? `${i}.${u.index}` : `$.${u.index}`;
  }
  getChildState(e, u) {
    return {
      renderer: u.renderer || e.renderer
    };
  }
  *getFullNode(e, u, l, i) {
    let n = e.element;
    if (!n && e.value && u && u.renderer) {
      let r = this.cache.get(e.value);
      if (r && (!r.shouldInvalidate || !r.shouldInvalidate(this.context))) {
        r.index = e.index, r.parentKey = i ? i.key : null, yield r;
        return;
      }
      n = u.renderer(e.value);
    }
    if (x.isValidElement(n)) {
      let r = n.type;
      if (typeof r != "function" && typeof r.getCollectionNode != "function") {
        let f = typeof n.type == "function" ? n.type.name : n.type;
        throw new Error(`Unknown element <${f}> in collection.`);
      }
      let a = r.getCollectionNode(n.props, this.context), d = e.index, c = a.next();
      for (; !c.done && c.value; ) {
        let f = c.value;
        e.index = d;
        let b = f.key;
        b || (b = f.element ? null : this.getKey(n, e, u, l));
        let p = [
          ...this.getFullNode({
            ...f,
            key: b,
            index: d,
            wrapper: Iu(e.wrapper, f.wrapper)
          }, this.getChildState(u, f), l ? `${l}${n.key}` : n.key, i)
        ];
        for (let m of p) {
          if (m.value = f.value || e.value, m.value && this.cache.set(m.value, m), e.type && m.type !== e.type)
            throw new Error(`Unsupported type <${U(m.type)}> in <${U(i.type)}>. Only <${U(e.type)}> is supported.`);
          d++, yield m;
        }
        c = a.next(p);
      }
      return;
    }
    if (e.key == null)
      return;
    let o = this, s = {
      type: e.type,
      props: e.props,
      key: e.key,
      parentKey: i ? i.key : null,
      value: e.value,
      level: i ? i.level + 1 : 0,
      index: e.index,
      rendered: e.rendered,
      textValue: e.textValue,
      "aria-label": e["aria-label"],
      wrapper: e.wrapper,
      shouldInvalidate: e.shouldInvalidate,
      hasChildNodes: e.hasChildNodes,
      childNodes: oe(function* () {
        if (!e.hasChildNodes)
          return;
        let r = 0;
        for (let a of e.childNodes()) {
          a.key != null && (a.key = `${s.key}${a.key}`), a.index = r;
          let d = o.getFullNode(a, o.getChildState(u, a), s.key, s);
          for (let c of d)
            r++, yield c;
        }
      })
    };
    yield s;
  }
  constructor() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
}
function oe(t) {
  let e = [], u = null;
  return {
    *[Symbol.iterator]() {
      for (let l of e)
        yield l;
      u || (u = t());
      for (let l of u)
        e.push(l), yield l;
    }
  };
}
function Iu(t, e) {
  if (t && e)
    return (u) => t(e(u));
  if (t)
    return t;
  if (e)
    return e;
}
function U(t) {
  return t[0].toUpperCase() + t.slice(1);
}
function Pu(t, e, u) {
  let l = C(() => new wu(), []), { children: i, items: n, collection: o } = t;
  return C(() => {
    if (o)
      return o;
    let r = l.build({
      children: i,
      items: n
    }, u);
    return e(r);
  }, [
    l,
    i,
    n,
    o,
    u,
    e
  ]);
}
function ku(t, e) {
  return typeof e.getChildren == "function" ? e.getChildren(t.key) : t.childNodes;
}
function Fu(t) {
  return zu(t, 0);
}
function zu(t, e) {
  if (e < 0)
    return;
  let u = 0;
  for (let l of t) {
    if (u === e)
      return l;
    u++;
  }
}
function W(t, e, u) {
  if (e.parentKey === u.parentKey)
    return e.index - u.index;
  let l = [
    ...re(t, e),
    e
  ], i = [
    ...re(t, u),
    u
  ], n = l.slice(0, i.length).findIndex((o, s) => o !== i[s]);
  return n !== -1 ? (e = l[n], u = i[n], e.index - u.index) : l.findIndex((o) => o === u) >= 0 ? 1 : (i.findIndex((o) => o === e) >= 0, -1);
}
function re(t, e) {
  let u = [];
  for (; (e == null ? void 0 : e.parentKey) != null; )
    e = t.getItem(e.parentKey), u.unshift(e);
  return u;
}
class se {
  *[Symbol.iterator]() {
    yield* this.iterable;
  }
  get size() {
    return this.keyMap.size;
  }
  getKeys() {
    return this.keyMap.keys();
  }
  getKeyBefore(e) {
    let u = this.keyMap.get(e);
    return u ? u.prevKey : null;
  }
  getKeyAfter(e) {
    let u = this.keyMap.get(e);
    return u ? u.nextKey : null;
  }
  getFirstKey() {
    return this.firstKey;
  }
  getLastKey() {
    return this.lastKey;
  }
  getItem(e) {
    return this.keyMap.get(e);
  }
  at(e) {
    const u = [
      ...this.getKeys()
    ];
    return this.getItem(u[e]);
  }
  getChildren(e) {
    let u = this.keyMap.get(e);
    return (u == null ? void 0 : u.childNodes) || [];
  }
  constructor(e) {
    this.keyMap = /* @__PURE__ */ new Map(), this.iterable = e;
    let u = (n) => {
      if (this.keyMap.set(n.key, n), n.childNodes && n.type === "section")
        for (let o of n.childNodes)
          u(o);
    };
    for (let n of e)
      u(n);
    let l, i = 0;
    for (let [n, o] of this.keyMap)
      l ? (l.nextKey = n, o.prevKey = l.key) : (this.firstKey = n, o.prevKey = void 0), o.type === "item" && (o.index = i++), l = o, l.nextKey = void 0;
    this.lastKey = l == null ? void 0 : l.key;
  }
}
class D extends Set {
  constructor(e, u, l) {
    super(e), e instanceof D ? (this.anchorKey = u || e.anchorKey, this.currentKey = l || e.currentKey) : (this.anchorKey = u, this.currentKey = l);
  }
}
function Mu(t, e, u) {
  let [l, i] = z(t || e), n = y(t !== void 0), o = t !== void 0;
  I(() => {
    let a = n.current;
    a !== o && console.warn(`WARN: A component changed from ${a ? "controlled" : "uncontrolled"} to ${o ? "controlled" : "uncontrolled"}.`), n.current = o;
  }, [
    o
  ]);
  let s = o ? t : l, r = pe((a, ...d) => {
    let c = (f, ...b) => {
      u && (Object.is(s, f) || u(f, ...b)), o || (s = f);
    };
    typeof a == "function" ? (console.warn("We can not support a function callback. See Github Issues for details https://github.com/adobe/react-spectrum/issues/2320"), i((b, ...h) => {
      let p = a(o ? s : b, ...h);
      return c(p, ...d), o ? b : p;
    })) : (o || i(a), c(a, ...d));
  }, [
    o,
    s,
    u
  ]);
  return [
    s,
    r
  ];
}
function Lu(t, e) {
  if (t.size !== e.size)
    return !1;
  for (let u of t)
    if (!e.has(u))
      return !1;
  return !0;
}
function Tu(t) {
  let { selectionMode: e = "none", disallowEmptySelection: u, allowDuplicateSelectionEvents: l, selectionBehavior: i = "toggle", disabledBehavior: n = "all" } = t, o = y(!1), [, s] = z(!1), r = y(null), a = y(null), [, d] = z(null), c = C(() => ae(t.selectedKeys), [
    t.selectedKeys
  ]), f = C(() => ae(t.defaultSelectedKeys, new D()), [
    t.defaultSelectedKeys
  ]), [b, h] = Mu(c, f, t.onSelectionChange), p = C(() => t.disabledKeys ? new Set(t.disabledKeys) : /* @__PURE__ */ new Set(), [
    t.disabledKeys
  ]), [m, g] = z(i);
  i === "replace" && m === "toggle" && typeof b == "object" && b.size === 0 && g("replace");
  let S = y(i);
  return I(() => {
    i !== S.current && (g(i), S.current = i);
  }, [
    i
  ]), {
    selectionMode: e,
    disallowEmptySelection: u,
    selectionBehavior: m,
    setSelectionBehavior: g,
    get isFocused() {
      return o.current;
    },
    setFocused(v) {
      o.current = v, s(v);
    },
    get focusedKey() {
      return r.current;
    },
    get childFocusStrategy() {
      return a.current;
    },
    setFocusedKey(v, V = "first") {
      r.current = v, a.current = V, d(v);
    },
    selectedKeys: b,
    setSelectedKeys(v) {
      (l || !Lu(v, b)) && h(v);
    },
    disabledKeys: p,
    disabledBehavior: n
  };
}
function ae(t, e) {
  return t ? t === "all" ? "all" : new D(t) : e;
}
class Ru {
  /**
  * The type of selection that is allowed in the collection.
  */
  get selectionMode() {
    return this.state.selectionMode;
  }
  /**
  * Whether the collection allows empty selection.
  */
  get disallowEmptySelection() {
    return this.state.disallowEmptySelection;
  }
  /**
  * The selection behavior for the collection.
  */
  get selectionBehavior() {
    return this.state.selectionBehavior;
  }
  /**
  * Sets the selection behavior for the collection.
  */
  setSelectionBehavior(e) {
    this.state.setSelectionBehavior(e);
  }
  /**
  * Whether the collection is currently focused.
  */
  get isFocused() {
    return this.state.isFocused;
  }
  /**
  * Sets whether the collection is focused.
  */
  setFocused(e) {
    this.state.setFocused(e);
  }
  /**
  * The current focused key in the collection.
  */
  get focusedKey() {
    return this.state.focusedKey;
  }
  /** Whether the first or last child of the focused key should receive focus. */
  get childFocusStrategy() {
    return this.state.childFocusStrategy;
  }
  /**
  * Sets the focused key.
  */
  setFocusedKey(e, u) {
    (e == null || this.collection.getItem(e)) && this.state.setFocusedKey(e, u);
  }
  /**
  * The currently selected keys in the collection.
  */
  get selectedKeys() {
    return this.state.selectedKeys === "all" ? new Set(this.getSelectAllKeys()) : this.state.selectedKeys;
  }
  /**
  * The raw selection value for the collection.
  * Either 'all' for select all, or a set of keys.
  */
  get rawSelection() {
    return this.state.selectedKeys;
  }
  /**
  * Returns whether a key is selected.
  */
  isSelected(e) {
    return this.state.selectionMode === "none" ? !1 : (e = this.getKey(e), this.state.selectedKeys === "all" ? this.canSelectItem(e) : this.state.selectedKeys.has(e));
  }
  /**
  * Whether the selection is empty.
  */
  get isEmpty() {
    return this.state.selectedKeys !== "all" && this.state.selectedKeys.size === 0;
  }
  /**
  * Whether all items in the collection are selected.
  */
  get isSelectAll() {
    if (this.isEmpty)
      return !1;
    if (this.state.selectedKeys === "all")
      return !0;
    if (this._isSelectAll != null)
      return this._isSelectAll;
    let e = this.getSelectAllKeys(), u = this.state.selectedKeys;
    return this._isSelectAll = e.every((l) => u.has(l)), this._isSelectAll;
  }
  get firstSelectedKey() {
    let e = null;
    for (let u of this.state.selectedKeys) {
      let l = this.collection.getItem(u);
      (!e || l && W(this.collection, l, e) < 0) && (e = l);
    }
    return e == null ? void 0 : e.key;
  }
  get lastSelectedKey() {
    let e = null;
    for (let u of this.state.selectedKeys) {
      let l = this.collection.getItem(u);
      (!e || l && W(this.collection, l, e) > 0) && (e = l);
    }
    return e == null ? void 0 : e.key;
  }
  get disabledKeys() {
    return this.state.disabledKeys;
  }
  get disabledBehavior() {
    return this.state.disabledBehavior;
  }
  /**
  * Extends the selection to the given key.
  */
  extendSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single") {
      this.replaceSelection(e);
      return;
    }
    e = this.getKey(e);
    let u;
    if (this.state.selectedKeys === "all")
      u = new D([
        e
      ], e, e);
    else {
      let l = this.state.selectedKeys, i = l.anchorKey || e;
      u = new D(l, i, e);
      for (let n of this.getKeyRange(i, l.currentKey || e))
        u.delete(n);
      for (let n of this.getKeyRange(e, i))
        this.canSelectItem(n) && u.add(n);
    }
    this.state.setSelectedKeys(u);
  }
  getKeyRange(e, u) {
    let l = this.collection.getItem(e), i = this.collection.getItem(u);
    return l && i ? W(this.collection, l, i) <= 0 ? this.getKeyRangeInternal(e, u) : this.getKeyRangeInternal(u, e) : [];
  }
  getKeyRangeInternal(e, u) {
    let l = [], i = e;
    for (; i; ) {
      let n = this.collection.getItem(i);
      if ((n && n.type === "item" || n.type === "cell" && this.allowsCellSelection) && l.push(i), i === u)
        return l;
      i = this.collection.getKeyAfter(i);
    }
    return [];
  }
  getKey(e) {
    let u = this.collection.getItem(e);
    if (!u || u.type === "cell" && this.allowsCellSelection)
      return e;
    for (; u.type !== "item" && u.parentKey != null; )
      u = this.collection.getItem(u.parentKey);
    return !u || u.type !== "item" ? null : u.key;
  }
  /**
  * Toggles whether the given key is selected.
  */
  toggleSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single" && !this.isSelected(e)) {
      this.replaceSelection(e);
      return;
    }
    if (e = this.getKey(e), e == null)
      return;
    let u = new D(this.state.selectedKeys === "all" ? this.getSelectAllKeys() : this.state.selectedKeys);
    u.has(e) ? u.delete(e) : this.canSelectItem(e) && (u.add(e), u.anchorKey = e, u.currentKey = e), !(this.disallowEmptySelection && u.size === 0) && this.state.setSelectedKeys(u);
  }
  /**
  * Replaces the selection with only the given key.
  */
  replaceSelection(e) {
    if (this.selectionMode === "none" || (e = this.getKey(e), e == null))
      return;
    let u = this.canSelectItem(e) ? new D([
      e
    ], e, e) : new D();
    this.state.setSelectedKeys(u);
  }
  /**
  * Replaces the selection with the given keys.
  */
  setSelectedKeys(e) {
    if (this.selectionMode === "none")
      return;
    let u = new D();
    for (let l of e)
      if (l = this.getKey(l), l != null && (u.add(l), this.selectionMode === "single"))
        break;
    this.state.setSelectedKeys(u);
  }
  getSelectAllKeys() {
    let e = [], u = (l) => {
      for (; l != null; ) {
        if (this.canSelectItem(l)) {
          let i = this.collection.getItem(l);
          i.type === "item" && e.push(l), i.hasChildNodes && (this.allowsCellSelection || i.type !== "item") && u(Fu(ku(i, this.collection)).key);
        }
        l = this.collection.getKeyAfter(l);
      }
    };
    return u(this.collection.getFirstKey()), e;
  }
  /**
  * Selects all items in the collection.
  */
  selectAll() {
    !this.isSelectAll && this.selectionMode === "multiple" && this.state.setSelectedKeys("all");
  }
  /**
  * Removes all keys from the selection.
  */
  clearSelection() {
    !this.disallowEmptySelection && (this.state.selectedKeys === "all" || this.state.selectedKeys.size > 0) && this.state.setSelectedKeys(new D());
  }
  /**
  * Toggles between select all and an empty selection.
  */
  toggleSelectAll() {
    this.isSelectAll ? this.clearSelection() : this.selectAll();
  }
  select(e, u) {
    this.selectionMode !== "none" && (this.selectionMode === "single" ? this.isSelected(e) && !this.disallowEmptySelection ? this.toggleSelection(e) : this.replaceSelection(e) : this.selectionBehavior === "toggle" || u && (u.pointerType === "touch" || u.pointerType === "virtual") ? this.toggleSelection(e) : this.replaceSelection(e));
  }
  /**
  * Returns whether the current selection is equal to the given selection.
  */
  isSelectionEqual(e) {
    if (e === this.state.selectedKeys)
      return !0;
    let u = this.selectedKeys;
    if (e.size !== u.size)
      return !1;
    for (let l of e)
      if (!u.has(l))
        return !1;
    for (let l of u)
      if (!e.has(l))
        return !1;
    return !0;
  }
  canSelectItem(e) {
    var u;
    if (this.state.selectionMode === "none" || this.state.disabledKeys.has(e))
      return !1;
    let l = this.collection.getItem(e);
    return !(!l || !(l == null || (u = l.props) === null || u === void 0) && u.isDisabled || l.type === "cell" && !this.allowsCellSelection);
  }
  isDisabled(e) {
    var u, l;
    return this.state.disabledBehavior === "all" && (this.state.disabledKeys.has(e) || !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (u = l.props) === null || u === void 0) && u.isDisabled));
  }
  isLink(e) {
    var u, l;
    return !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (u = l.props) === null || u === void 0) && u.href);
  }
  getItemProps(e) {
    var u;
    return (u = this.collection.getItem(e)) === null || u === void 0 ? void 0 : u.props;
  }
  constructor(e, u, l) {
    this.collection = e, this.state = u;
    var i;
    this.allowsCellSelection = (i = l == null ? void 0 : l.allowsCellSelection) !== null && i !== void 0 ? i : !1, this._isSelectAll = null;
  }
}
function ju(t) {
  let { filter: e } = t, u = Tu(t), l = C(() => t.disabledKeys ? new Set(t.disabledKeys) : /* @__PURE__ */ new Set(), [
    t.disabledKeys
  ]), i = pe((a) => e ? new se(e(a)) : new se(a), [
    e
  ]), n = C(() => ({
    suppressTextValueWarning: t.suppressTextValueWarning
  }), [
    t.suppressTextValueWarning
  ]), o = Pu(t, i, n), s = C(() => new Ru(o, u), [
    o,
    u
  ]);
  const r = y(null);
  return I(() => {
    if (u.focusedKey != null && !o.getItem(u.focusedKey)) {
      const a = r.current.getItem(u.focusedKey), d = [
        ...r.current.getKeys()
      ].map((m) => {
        const g = r.current.getItem(m);
        return g.type === "item" ? g : null;
      }).filter((m) => m !== null), c = [
        ...o.getKeys()
      ].map((m) => {
        const g = o.getItem(m);
        return g.type === "item" ? g : null;
      }).filter((m) => m !== null), f = d.length - c.length;
      let b = Math.min(f > 1 ? Math.max(a.index - f + 1, 0) : a.index, c.length - 1), h, p = !1;
      for (; b >= 0; ) {
        if (!s.isDisabled(c[b].key)) {
          h = c[b];
          break;
        }
        b < c.length - 1 && !p ? b++ : (p = !0, b > a.index && (b = a.index), b--);
      }
      u.setFocusedKey(h ? h.key : null);
    }
    r.current = o;
  }, [
    o,
    s,
    u,
    u.focusedKey
  ]), {
    collection: o,
    disabledKeys: l,
    selectionManager: s
  };
}
const _u = /* @__PURE__ */ L({}), Z = /* @__PURE__ */ L(null), Vu = /* @__PURE__ */ L(null), Tt = /* @__PURE__ */ L(null);
function Nu(t, e) {
  [t, e] = be(t, e, Vu);
  let u = y(null), [l, i] = eu(), { collection: n, document: o } = Ht(), s = ju({
    ...t,
    children: void 0,
    collection: n
  }), r = M(t), a = Object.fromEntries(Object.entries(r).map(([p]) => [
    p,
    void 0
  ])), { gridProps: d, labelProps: c, descriptionProps: f, errorMessageProps: b } = Eu({
    ...t,
    ...a,
    label: i
  }, s, u);
  var h;
  return /* @__PURE__ */ x.createElement("div", {
    ...r,
    ref: e,
    slot: t.slot || void 0,
    className: (h = t.className) !== null && h !== void 0 ? h : "react-aria-TagGroup",
    style: t.style
  }, /* @__PURE__ */ x.createElement($e, {
    values: [
      [
        _u,
        {
          ...c,
          elementType: "span",
          ref: l
        }
      ],
      [
        Tt,
        {
          ...d,
          ref: u
        }
      ],
      [
        Z,
        s
      ],
      [
        Gt,
        o
      ],
      [
        Ku,
        {
          slots: {
            description: f,
            errorMessage: b
          }
        }
      ]
    ]
  }, t.children));
}
const Zu = /* @__PURE__ */ G(Nu);
function Ou(t, e) {
  let u = qt(t);
  return /* @__PURE__ */ x.createElement(x.Fragment, null, u, /* @__PURE__ */ x.createElement(Uu, {
    props: t,
    forwardedRef: e
  }));
}
function Uu({ props: t, forwardedRef: e }) {
  let u = ve(Z), [l, i] = be(t, e, Tt);
  delete l.items, delete l.renderEmptyState;
  let n = Yt({
    items: u.collection,
    children: (c) => {
      switch (c.type) {
        case "item":
          return /* @__PURE__ */ x.createElement(Hu, {
            item: c
          });
        default:
          throw new Error("Unsupported node type in TagList: " + c.type);
      }
    }
  }), { focusProps: o, isFocused: s, isFocusVisible: r } = me(), a = {
    isEmpty: u.collection.size === 0,
    isFocused: s,
    isFocusVisible: r,
    state: u
  }, d = he({
    className: t.className,
    style: t.style,
    defaultClassName: "react-aria-TagList",
    values: a
  });
  return /* @__PURE__ */ x.createElement("div", {
    ...E(l, o),
    ...d,
    ref: i,
    "data-empty": u.collection.size === 0 || void 0,
    "data-focused": s || void 0,
    "data-focus-visible": r || void 0
  }, u.collection.size === 0 && t.renderEmptyState ? t.renderEmptyState(a) : n);
}
const Ju = /* @__PURE__ */ G(Ou);
function Wu(t, e) {
  return Xt("item", t, e, t.children);
}
const Qu = /* @__PURE__ */ G(Wu);
function Hu({ item: t }) {
  let e = ve(Z), u = tu(t.props.ref), { focusProps: l, isFocusVisible: i } = me({
    within: !0
  }), { rowProps: n, gridCellProps: o, removeButtonProps: s, ...r } = Au({
    item: t
  }, e, u), { hoverProps: a, isHovered: d } = uu({
    isDisabled: !r.allowsSelection,
    onHoverStart: t.props.onHoverStart,
    onHoverChange: t.props.onHoverChange,
    onHoverEnd: t.props.onHoverEnd
  }), c = t.props, f = he({
    ...c,
    id: void 0,
    children: t.rendered,
    defaultClassName: "react-aria-Tag",
    values: {
      ...r,
      isFocusVisible: i,
      isHovered: d,
      selectionMode: e.selectionManager.selectionMode,
      selectionBehavior: e.selectionManager.selectionBehavior
    }
  });
  return I(() => {
    t.textValue || console.warn("A `textValue` prop is required for <Tag> elements with non-plain text children for accessibility.");
  }, [
    t.textValue
  ]), /* @__PURE__ */ x.createElement("div", {
    ref: u,
    ...f,
    ...E(M(c), n, l, a),
    "data-selected": r.isSelected || void 0,
    "data-disabled": r.isDisabled || void 0,
    "data-hovered": d || void 0,
    "data-focused": r.isFocused || void 0,
    "data-focus-visible": i || void 0,
    "data-pressed": r.isPressed || void 0,
    "data-allows-removing": r.allowsRemoving || void 0,
    "data-selection-mode": e.selectionManager.selectionMode === "none" ? void 0 : e.selectionManager.selectionMode
  }, /* @__PURE__ */ x.createElement("div", {
    ...o,
    style: {
      display: "contents"
    }
  }, /* @__PURE__ */ x.createElement($e, {
    values: [
      [
        _t,
        {
          slots: {
            remove: s
          }
        }
      ]
    ]
  }, f.children)));
}
export {
  Zu as $,
  Ju as a,
  Qu as b
};
