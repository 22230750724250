import { Stack, Button, TagInput, type TagInputProps } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { cn } from '@carvertical/utils/styling';
import { trimStart } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { type ChangeEvent, useCallback, useEffect, useId, useRef, useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useRouteData } from 'context/RouteDataProvider';
import { QUERY_PARAMS } from 'constants/queryParams';
import { FormField } from 'components/common/ui/forms';
import type { Mode } from 'types/identifier';
import { type ExistingMarkets, isExistingMarket } from 'utils/market';
import { isQueryMatchingParam, sanitizeQueryParams } from 'utils/url';
import { useFeatureFlagPayload } from 'modules/analytics';
// FIXME: Dependency cycle
import { VALID_VIN_LENGTH } from 'modules/shared/constants';
import type { Market } from 'types/market';
// FIXME: Dependency cycle
import { trimWhitespaces } from 'modules/shared/utils';
import { replaceForeignChars } from 'utils/vin';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { AustralianStateSelector } from './AustralianStateSelector';
import { ContinueWithoutVinCta } from './ContinueWithoutVinCta';
import { IdentifierInput, type IdentifierInputProps } from './IdentifierInput';
import { IdentifierSwitch } from './IdentifierSwitch';
import { LicensePlatePrefix } from './LicensePlatePrefix';
import { type IdentifierFormFields, getIdentifierFormSchema } from './schemas';
import type { SurroundingBackground } from './types';
import styles from './IdentifierForm.module.scss';

type IdentifierFormProps = {
  className?: string;
  fullWidth?: boolean;
  surroundingBackground?: SurroundingBackground;
  defaultValue?: string;
  focused?: boolean;
  forcedMode?: Mode;
  queryParamForwardingDisabled?: boolean;
  onSubmit?: (vin: string) => void;
  continueWithoutVinCtaVisible?: boolean;
  continueWithoutVinCtaLabel?: string;
  bulkProps?: BulkProps;
} & Pick<
  IdentifierInputProps,
  | 'size'
  | 'buttonVariant'
  | 'buttonLabel'
  | 'explanationShown'
  | 'labelHidden'
  | 'outsideButtonShown'
>;

type BulkProps = {
  enabled?: boolean;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  submitButtonLabel?: string;
  tagInputLabel?: string;
  fieldErrorMessage?: string;
  tagsLimitReached?: boolean;
  submitBulk?: () => void;
  setAndValidateTags?: (vins: string[]) => void;
  setFieldErrorMessage?: (message: string) => void;
} & Omit<TagInputProps, 'onChange' | 'label'>;

const FIELD_NAME = 'identifier';
const LICENSE_PLATE_EXCLUDED_MARKETS: ExistingMarkets = [{ belgium: 'fr-BE' }];

const IdentifierForm = ({
  buttonLabel,
  buttonVariant,
  className,
  continueWithoutVinCtaVisible = true,
  continueWithoutVinCtaLabel,
  defaultValue,
  focused = false,
  forcedMode,
  fullWidth = false,
  labelHidden = true,
  explanationShown = true,
  outsideButtonShown = false,
  queryParamForwardingDisabled = false,
  onSubmit,
  size = 'l',
  surroundingBackground = 'mediumDark',
  bulkProps,
}: IdentifierFormProps) => {
  const { t } = useTranslation();
  const { push, query } = useRouter();
  const { basePath, locale, market, pages } = useRouteData();
  const hintId = useId();
  const formRef = useRef<HTMLFormElement>(null);
  const [manualLoading, setManualLoading] = useState(false);

  const lpDisabled = useLpDisabled(market);
  const { identifier: identifierFromMarket } = market;

  const tagsCount = bulkProps?.tags?.length || 0;

  const initialMode = (() => {
    const { identifierModeOverride } = QUERY_PARAMS;

    const queryMatches = isQueryMatchingParam(query, identifierModeOverride);

    if (queryMatches && identifierFromMarket.initial) {
      return query[identifierModeOverride.name] as Mode;
    }

    return lpDisabled ? 'vin' : forcedMode || identifierFromMarket.initial;
  })();

  const form = useForm<IdentifierFormFields>({
    defaultValues: {
      mode: forcedMode || initialMode || 'vin',
      identifier: defaultValue,
    },
    resolver: zodResolver(getIdentifierFormSchema(identifierFromMarket)),
  });

  const { formState, handleSubmit, setValue, watch, control } = form;
  const { field } = useController({ name: FIELD_NAME, control });
  const { isSubmitting, isSubmitSuccessful } = formState;

  const loading = isSubmitting || isSubmitSuccessful;
  const mode = watch('mode');
  const identifierValue = watch(FIELD_NAME);
  const lpMode = mode === 'lp';
  const switchable = lpDisabled ? false : !forcedMode && !!initialMode;

  const submit = useCallback(
    ({ identifier: submittedValue, countryState }: IdentifierFormFields) => {
      const value = Array.isArray(submittedValue) ? submittedValue.toString() : submittedValue;
      const vin = trimWhitespaces(value)?.toUpperCase();

      if (onSubmit && vin) {
        onSubmit(vin);
      } else {
        push({
          pathname: `${basePath}${trimStart(pages.precheck.path, '/')}/searching`,
          query: {
            ...(!queryParamForwardingDisabled && sanitizeQueryParams(query)),
            ...(vin ? { vin } : {}),
            ...(countryState ? { countryState } : {}),
          },
        });
      }
    },
    [basePath, pages.precheck.path, push, onSubmit, query, queryParamForwardingDisabled],
  );

  const label = {
    vin: t('vinForm.placeholder'),
    lp: t('vinForm.placeholderLp'),
  }[mode];

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target;

    setValue(FIELD_NAME, replaceForeignChars(inputValue));
  };

  const submitBulk = () => {
    if (!bulkProps) {
      return;
    }

    if (!tagsCount || (tagsCount === 1 && Array.isArray(identifierValue))) {
      const submitValue = Array.isArray(identifierValue) ? identifierValue[0] : identifierValue;

      if (!submitValue) {
        bulkProps.setFieldErrorMessage?.(t(ERROR_TRANSLATION_KEYS.required));
        return;
      }

      if (submitValue?.length !== VALID_VIN_LENGTH) {
        bulkProps.setFieldErrorMessage?.(t(ERROR_TRANSLATION_KEYS.vinBulk));
        return;
      }

      bulkProps.setFieldErrorMessage?.('');
      setManualLoading(true);
      submit({ identifier: submitValue, mode: forcedMode || initialMode || 'vin' });
    }

    if (tagsCount) {
      if (bulkProps.tags?.length && identifierValue && !Array.isArray(identifierValue)) {
        bulkProps.setAndValidateTags?.([...bulkProps.tags, identifierValue.toString()]);
        setValue(FIELD_NAME, '');
        return;
      }

      if (tagsCount > 1) {
        bulkProps.submitBulk?.();
      }
    }
  };

  const renderPrefix = () => {
    const existingMarket = (markets: ExistingMarkets) =>
      isExistingMarket({
        markets,
        market,
        locale,
      });

    if (existingMarket(['australia']) && lpMode) {
      return <AustralianStateSelector disabled={loading} />;
    }

    if (lpMode && !existingMarket(LICENSE_PLATE_EXCLUDED_MARKETS)) {
      return <LicensePlatePrefix hintId={hintId} />;
    }

    return undefined;
  };

  useEffect(() => {
    if (initialMode) {
      setValue('mode', initialMode);
    }
  }, [forcedMode, initialMode, setValue]);

  useEffect(() => {
    if (formRef.current && focused) {
      const inputElement: HTMLInputElement = formRef.current.querySelector(`[name=${FIELD_NAME}]`)!;

      inputElement.focus();
    }
  }, [focused]);

  const Field = FormField<IdentifierFormFields>;

  return (
    <FormProvider {...form}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(submit)}
        className={cn(styles.root, fullWidth && styles.fullWidth, className)}
      >
        <Stack gap={2}>
          {switchable && (
            <div className={styles.toggleWrapper}>
              <IdentifierSwitch surroundingBackground={surroundingBackground} />
            </div>
          )}

          <div className={styles.inputWrapper}>
            {bulkProps?.enabled ? (
              <TagInput
                {...field}
                fullWidth
                size={size}
                tags={bulkProps.tags}
                maxTags={bulkProps.maxTags}
                invalidTags={bulkProps.invalidTags}
                value={identifierValue}
                label={bulkProps.tagInputLabel || label}
                disabled={bulkProps.submitDisabled || manualLoading}
                status={bulkProps.fieldErrorMessage ? 'error' : undefined}
                message={bulkProps.fieldErrorMessage}
                tagClassName="uppercase"
                inputClassName="uppercase"
                onInput={onInputChange}
                onChange={(value) => {
                  field.onChange(value);
                  if (Array.isArray(value)) {
                    bulkProps.setAndValidateTags?.(value);
                  }
                }}
              />
            ) : (
              <Field name="identifier">
                <IdentifierInput
                  label={label}
                  size={size}
                  prefix={renderPrefix()}
                  loading={isSubmitSuccessful}
                  buttonLabel={buttonLabel}
                  labelHidden={labelHidden}
                  outsideButtonShown={outsideButtonShown}
                  buttonVariant={buttonVariant}
                  surroundingBackground={surroundingBackground}
                  explanationShown={explanationShown && !lpMode}
                  activePlaceholder={lpMode ? identifierFromMarket.lp?.placeholder : undefined}
                  aria-describedby={hintId}
                />
              </Field>
            )}
          </div>

          {outsideButtonShown && (
            <Button
              size="m"
              type="submit"
              variant="yellow"
              disabled={loading}
              className="w-full md:w-fit"
              {...(bulkProps?.enabled && {
                type: 'button',
                loading: bulkProps.submitLoading,
                disabled: manualLoading || bulkProps.submitDisabled,
                className: cn(
                  'w-full md:w-auto',
                  // FIXME: Remove `!` once https://github.com/carVertical/frontend-monorepo/pull/1067 is merged.
                  bulkProps?.submitLoading && '!bg-yellow-500 !text-black !shadow-none',
                ),
                onClick: submitBulk,
              })}
            >
              {buttonLabel || bulkProps?.submitButtonLabel}
            </Button>
          )}

          {continueWithoutVinCtaVisible && (
            <div className={styles.ctaWrapper}>
              <ContinueWithoutVinCta
                surroundingBackground={surroundingBackground}
                disabled={loading}
                ctaLabel={continueWithoutVinCtaLabel}
              />
            </div>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

function useLpDisabled(market: Market) {
  const lpDisabledMarkets = useFeatureFlagPayload('lpDisabledMarkets');
  const lpDisabled = lpDisabledMarkets
    ? isExistingMarket({ markets: lpDisabledMarkets, market })
    : false;

  return lpDisabled;
}

export { IdentifierForm };
export type { IdentifierFormProps };
