import { Button } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { getCountryName } from 'services/countries';
import { PRICING_SECTION_ID } from 'modules/business/constants';
import { useModal, useMarket } from '../../hooks';
import { useMarketSwitcher } from '../MarketSwitcher/hooks';
import { useSuggestCorrectMarket, useCheckoutUrl, useSuggestedMarket, useType } from './hooks';

const MarketSelection = () => {
  const { t } = useTranslation(['geo', 'common']);
  const { getMarketLanguages } = useMarket();
  const { confirmMarketSelection, market } = useSuggestCorrectMarket();
  const { close: closeSuggestCheckoutModal } = useModal('suggestCheckout');
  const { open: openLanguageSelectionModal } = useModal('languageSelection');
  const { selectMarket, switcherMarkets, setLanguageModalType } = useMarketSwitcher();

  const type = useType();
  const checkoutUrl = useCheckoutUrl();
  const suggestedMarket = useSuggestedMarket();
  const switchToMarketLabel = useSuggestedCountryTranslatedLabel(suggestedMarket?.countryCode);

  const countryName = getCountryName(market.countryCode);
  const { slug: currentMarketSlug } = getMarketLanguages(market.countryCode, checkoutUrl)[0];

  const stayInCurrentMarket = () => confirmMarketSelection();

  const selectedMarket =
    switcherMarkets.find(
      (switcherMarket) => switcherMarket.countryCode === suggestedMarket?.countryCode,
    ) || switcherMarkets[0];

  const multilingual = selectedMarket.languages.length > 1;
  const selectedMarketSlug = `${selectedMarket.languages[0].slug}${
    type === 'checkout' ? `#${PRICING_SECTION_ID}` : ''
  }`;

  const switchToMarket = () => {
    selectMarket(selectedMarket);

    if (type === 'checkout') {
      closeSuggestCheckoutModal();
    }

    setLanguageModalType(type === 'checkout' ? 'checkout' : 'suggestion');

    if (multilingual) {
      openLanguageSelectionModal();
    }
  };

  if (!suggestedMarket) {
    return null;
  }

  return (
    <div className="flex flex-col items-stretch gap-2">
      <Button onClick={switchToMarket} href={!multilingual ? `${selectedMarketSlug}` : undefined}>
        {switchToMarketLabel}
      </Button>

      <Button
        href={type === 'checkout' ? currentMarketSlug : undefined}
        variant="outlined"
        onClick={stayInCurrentMarket}
      >
        {t('stayInCountryAction', { country: countryName })}
      </Button>
    </div>
  );
};

function useSuggestedCountryTranslatedLabel(countryCode?: string) {
  const { t } = useTranslation('geo');
  const suggestedCountryName = getCountryName(countryCode, { inNativeLanguage: true });
  const translatedText = t('switchToCountryAction', { country: suggestedCountryName });

  return translatedText;
}

export { MarketSelection };
