import * as un from "react";
import gs, { createContext as xt, useLayoutEffect as Ni, useEffect as Fe, useContext as j, useRef as Pe, useInsertionEffect as Ui, useCallback as $i, useMemo as Jt, forwardRef as Gi, createElement as Wi, useId as Hi } from "react";
const ys = xt({
  transformPagePoint: (t) => t,
  isStatic: !1,
  reducedMotion: "never"
}), te = xt({}), ke = xt(null), ee = typeof document < "u", zi = ee ? Ni : Fe, vs = xt({ strict: !1 });
function Ki(t, e, n, s) {
  const { visualElement: i } = j(te), o = j(vs), r = j(ke), a = j(ys).reducedMotion, c = Pe();
  s = s || o.renderer, !c.current && s && (c.current = s(t, {
    visualState: e,
    parent: i,
    props: n,
    presenceContext: r,
    blockInitialAnimation: r ? r.initial === !1 : !1,
    reducedMotionConfig: a
  }));
  const l = c.current;
  Ui(() => {
    l && l.update(n, r);
  });
  const u = Pe(!!window.HandoffAppearAnimations);
  return zi(() => {
    l && (l.render(), u.current && l.animationState && l.animationState.animateChanges());
  }), Fe(() => {
    l && (l.updateFeatures(), !u.current && l.animationState && l.animationState.animateChanges(), window.HandoffAppearAnimations = void 0, u.current = !1);
  }), l;
}
function dt(t) {
  return typeof t == "object" && Object.prototype.hasOwnProperty.call(t, "current");
}
function _i(t, e, n) {
  return $i(
    (s) => {
      s && t.mount && t.mount(s), e && (s ? e.mount(s) : e.unmount()), n && (typeof n == "function" ? n(s) : dt(n) && (n.current = s));
    },
    /**
     * Only pass a new ref callback to React if we've received a visual element
     * factory. Otherwise we'll be mounting/remounting every time externalRef
     * or other dependencies change.
     */
    [e]
  );
}
function Mt(t) {
  return typeof t == "string" || Array.isArray(t);
}
function ne(t) {
  return typeof t == "object" && typeof t.start == "function";
}
const je = [
  "animate",
  "whileInView",
  "whileFocus",
  "whileHover",
  "whileTap",
  "whileDrag",
  "exit"
], Oe = ["initial", ...je];
function se(t) {
  return ne(t.animate) || Oe.some((e) => Mt(t[e]));
}
function xs(t) {
  return !!(se(t) || t.variants);
}
function Yi(t, e) {
  if (se(t)) {
    const { initial: n, animate: s } = t;
    return {
      initial: n === !1 || Mt(n) ? n : void 0,
      animate: Mt(s) ? s : void 0
    };
  }
  return t.inherit !== !1 ? e : {};
}
function Xi(t) {
  const { initial: e, animate: n } = Yi(t, j(te));
  return Jt(() => ({ initial: e, animate: n }), [hn(e), hn(n)]);
}
function hn(t) {
  return Array.isArray(t) ? t.join(" ") : t;
}
const fn = {
  animation: [
    "animate",
    "variants",
    "whileHover",
    "whileTap",
    "exit",
    "whileInView",
    "whileFocus",
    "whileDrag"
  ],
  exit: ["exit"],
  drag: ["drag", "dragControls"],
  focus: ["whileFocus"],
  hover: ["whileHover", "onHoverStart", "onHoverEnd"],
  tap: ["whileTap", "onTap", "onTapStart", "onTapCancel"],
  pan: ["onPan", "onPanStart", "onPanSessionStart", "onPanEnd"],
  inView: ["whileInView", "onViewportEnter", "onViewportLeave"],
  layout: ["layout", "layoutId"]
}, Lt = {};
for (const t in fn)
  Lt[t] = {
    isEnabled: (e) => fn[t].some((n) => !!e[n])
  };
function qi(t) {
  for (const e in t)
    Lt[e] = {
      ...Lt[e],
      ...t[e]
    };
}
const Ps = xt({}), Ts = xt({}), Zi = Symbol.for("motionComponentSymbol");
function Qi({ preloadedFeatures: t, createVisualElement: e, useRender: n, useVisualState: s, Component: i }) {
  t && qi(t);
  function o(a, c) {
    let l;
    const u = {
      ...j(ys),
      ...a,
      layoutId: Ji(a)
    }, { isStatic: h } = u, f = Xi(a), d = s(a, h);
    if (!h && ee) {
      f.visualElement = Ki(i, d, u, e);
      const m = j(Ts), p = j(vs).strict;
      f.visualElement && (l = f.visualElement.loadFeatures(
        // Note: Pass the full new combined props to correctly re-render dynamic feature components.
        u,
        p,
        t,
        m
      ));
    }
    return un.createElement(
      te.Provider,
      { value: f },
      l && f.visualElement ? un.createElement(l, { visualElement: f.visualElement, ...u }) : null,
      n(i, a, _i(d, f.visualElement, c), d, h, f.visualElement)
    );
  }
  const r = Gi(o);
  return r[Zi] = i, r;
}
function Ji({ layoutId: t }) {
  const e = j(Ps).id;
  return e && t !== void 0 ? e + "-" + t : t;
}
function tr(t) {
  function e(s, i = {}) {
    return Qi(t(s, i));
  }
  if (typeof Proxy > "u")
    return e;
  const n = /* @__PURE__ */ new Map();
  return new Proxy(e, {
    /**
     * Called when `motion` is referenced with a prop: `motion.div`, `motion.input` etc.
     * The prop name is passed through as `key` and we can use that to generate a `motion`
     * DOM component with that name.
     */
    get: (s, i) => (n.has(i) || n.set(i, e(i)), n.get(i))
  });
}
const er = [
  "animate",
  "circle",
  "defs",
  "desc",
  "ellipse",
  "g",
  "image",
  "line",
  "filter",
  "marker",
  "mask",
  "metadata",
  "path",
  "pattern",
  "polygon",
  "polyline",
  "rect",
  "stop",
  "switch",
  "symbol",
  "svg",
  "text",
  "tspan",
  "use",
  "view"
];
function Ie(t) {
  return (
    /**
     * If it's not a string, it's a custom React component. Currently we only support
     * HTML custom React components.
     */
    typeof t != "string" || /**
     * If it contains a dash, the element is a custom HTML webcomponent.
     */
    t.includes("-") ? !1 : (
      /**
       * If it's in our list of lowercase SVG tags, it's an SVG component
       */
      !!(er.indexOf(t) > -1 || /**
       * If it contains a capital letter, it's an SVG component
       */
      /[A-Z]/.test(t))
    )
  );
}
const zt = {};
function nr(t) {
  Object.assign(zt, t);
}
const Bt = [
  "transformPerspective",
  "x",
  "y",
  "z",
  "translateX",
  "translateY",
  "translateZ",
  "scale",
  "scaleX",
  "scaleY",
  "rotate",
  "rotateX",
  "rotateY",
  "rotateZ",
  "skew",
  "skewX",
  "skewY"
], ut = new Set(Bt);
function bs(t, { layout: e, layoutId: n }) {
  return ut.has(t) || t.startsWith("origin") || (e || n !== void 0) && (!!zt[t] || t === "opacity");
}
const O = (t) => !!(t && t.getVelocity), sr = {
  x: "translateX",
  y: "translateY",
  z: "translateZ",
  transformPerspective: "perspective"
}, ir = Bt.length;
function rr(t, { enableHardwareAcceleration: e = !0, allowTransformNone: n = !0 }, s, i) {
  let o = "";
  for (let r = 0; r < ir; r++) {
    const a = Bt[r];
    if (t[a] !== void 0) {
      const c = sr[a] || a;
      o += `${c}(${t[a]}) `;
    }
  }
  return e && !t.z && (o += "translateZ(0)"), o = o.trim(), i ? o = i(t, s ? "" : o) : n && s && (o = "none"), o;
}
const Vs = (t) => (e) => typeof e == "string" && e.startsWith(t), Ss = Vs("--"), Te = Vs("var(--"), or = /var\s*\(\s*--[\w-]+(\s*,\s*(?:(?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*)+)?\s*\)/g, ar = (t, e) => e && typeof t == "number" ? e.transform(t) : t, nt = (t, e, n) => Math.min(Math.max(n, t), e), ht = {
  test: (t) => typeof t == "number",
  parse: parseFloat,
  transform: (t) => t
}, At = {
  ...ht,
  transform: (t) => nt(0, 1, t)
}, It = {
  ...ht,
  default: 1
}, wt = (t) => Math.round(t * 1e5) / 1e5, ie = /(-)?([\d]*\.?[\d])+/g, As = /(#[0-9a-f]{3,8}|(rgb|hsl)a?\((-?[\d\.]+%?[,\s]+){2}(-?[\d\.]+%?)\s*[\,\/]?\s*[\d\.]*%?\))/gi, cr = /^(#[0-9a-f]{3,8}|(rgb|hsl)a?\((-?[\d\.]+%?[,\s]+){2}(-?[\d\.]+%?)\s*[\,\/]?\s*[\d\.]*%?\))$/i;
function Et(t) {
  return typeof t == "string";
}
const Ft = (t) => ({
  test: (e) => Et(e) && e.endsWith(t) && e.split(" ").length === 1,
  parse: parseFloat,
  transform: (e) => `${e}${t}`
}), Q = Ft("deg"), $ = Ft("%"), v = Ft("px"), lr = Ft("vh"), ur = Ft("vw"), dn = {
  ...$,
  parse: (t) => $.parse(t) / 100,
  transform: (t) => $.transform(t * 100)
}, mn = {
  ...ht,
  transform: Math.round
}, ws = {
  // Border props
  borderWidth: v,
  borderTopWidth: v,
  borderRightWidth: v,
  borderBottomWidth: v,
  borderLeftWidth: v,
  borderRadius: v,
  radius: v,
  borderTopLeftRadius: v,
  borderTopRightRadius: v,
  borderBottomRightRadius: v,
  borderBottomLeftRadius: v,
  // Positioning props
  width: v,
  maxWidth: v,
  height: v,
  maxHeight: v,
  size: v,
  top: v,
  right: v,
  bottom: v,
  left: v,
  // Spacing props
  padding: v,
  paddingTop: v,
  paddingRight: v,
  paddingBottom: v,
  paddingLeft: v,
  margin: v,
  marginTop: v,
  marginRight: v,
  marginBottom: v,
  marginLeft: v,
  // Transform props
  rotate: Q,
  rotateX: Q,
  rotateY: Q,
  rotateZ: Q,
  scale: It,
  scaleX: It,
  scaleY: It,
  scaleZ: It,
  skew: Q,
  skewX: Q,
  skewY: Q,
  distance: v,
  translateX: v,
  translateY: v,
  translateZ: v,
  x: v,
  y: v,
  z: v,
  perspective: v,
  transformPerspective: v,
  opacity: At,
  originX: dn,
  originY: dn,
  originZ: v,
  // Misc
  zIndex: mn,
  // SVG
  fillOpacity: At,
  strokeOpacity: At,
  numOctaves: mn
};
function Ne(t, e, n, s) {
  const { style: i, vars: o, transform: r, transformOrigin: a } = t;
  let c = !1, l = !1, u = !0;
  for (const h in e) {
    const f = e[h];
    if (Ss(h)) {
      o[h] = f;
      continue;
    }
    const d = ws[h], m = ar(f, d);
    if (ut.has(h)) {
      if (c = !0, r[h] = m, !u)
        continue;
      f !== (d.default || 0) && (u = !1);
    } else
      h.startsWith("origin") ? (l = !0, a[h] = m) : i[h] = m;
  }
  if (e.transform || (c || s ? i.transform = rr(t.transform, n, u, s) : i.transform && (i.transform = "none")), l) {
    const { originX: h = "50%", originY: f = "50%", originZ: d = 0 } = a;
    i.transformOrigin = `${h} ${f} ${d}`;
  }
}
const Ue = () => ({
  style: {},
  transform: {},
  transformOrigin: {},
  vars: {}
});
function Cs(t, e, n) {
  for (const s in e)
    !O(e[s]) && !bs(s, n) && (t[s] = e[s]);
}
function hr({ transformTemplate: t }, e, n) {
  return Jt(() => {
    const s = Ue();
    return Ne(s, e, { enableHardwareAcceleration: !n }, t), Object.assign({}, s.vars, s.style);
  }, [e]);
}
function fr(t, e, n) {
  const s = t.style || {}, i = {};
  return Cs(i, s, t), Object.assign(i, hr(t, e, n)), t.transformValues ? t.transformValues(i) : i;
}
function dr(t, e, n) {
  const s = {}, i = fr(t, e, n);
  return t.drag && t.dragListener !== !1 && (s.draggable = !1, i.userSelect = i.WebkitUserSelect = i.WebkitTouchCallout = "none", i.touchAction = t.drag === !0 ? "none" : `pan-${t.drag === "x" ? "y" : "x"}`), t.tabIndex === void 0 && (t.onTap || t.onTapStart || t.whileTap) && (s.tabIndex = 0), s.style = i, s;
}
const mr = /* @__PURE__ */ new Set([
  "animate",
  "exit",
  "variants",
  "initial",
  "style",
  "values",
  "variants",
  "transition",
  "transformTemplate",
  "transformValues",
  "custom",
  "inherit",
  "onLayoutAnimationStart",
  "onLayoutAnimationComplete",
  "onLayoutMeasure",
  "onBeforeLayoutMeasure",
  "onAnimationStart",
  "onAnimationComplete",
  "onUpdate",
  "onDragStart",
  "onDrag",
  "onDragEnd",
  "onMeasureDragConstraints",
  "onDirectionLock",
  "onDragTransitionEnd",
  "_dragX",
  "_dragY",
  "onHoverStart",
  "onHoverEnd",
  "onViewportEnter",
  "onViewportLeave",
  "ignoreStrict",
  "viewport"
]);
function Kt(t) {
  return t.startsWith("while") || t.startsWith("drag") && t !== "draggable" || t.startsWith("layout") || t.startsWith("onTap") || t.startsWith("onPan") || mr.has(t);
}
let Ds = (t) => !Kt(t);
function pr(t) {
  t && (Ds = (e) => e.startsWith("on") ? !Kt(e) : t(e));
}
try {
  pr(require("@emotion/is-prop-valid").default);
} catch {
}
function gr(t, e, n) {
  const s = {};
  for (const i in t)
    i === "values" && typeof t.values == "object" || (Ds(i) || n === !0 && Kt(i) || !e && !Kt(i) || // If trying to use native HTML drag events, forward drag listeners
    t.draggable && i.startsWith("onDrag")) && (s[i] = t[i]);
  return s;
}
function pn(t, e, n) {
  return typeof t == "string" ? t : v.transform(e + n * t);
}
function yr(t, e, n) {
  const s = pn(e, t.x, t.width), i = pn(n, t.y, t.height);
  return `${s} ${i}`;
}
const vr = {
  offset: "stroke-dashoffset",
  array: "stroke-dasharray"
}, xr = {
  offset: "strokeDashoffset",
  array: "strokeDasharray"
};
function Pr(t, e, n = 1, s = 0, i = !0) {
  t.pathLength = 1;
  const o = i ? vr : xr;
  t[o.offset] = v.transform(-s);
  const r = v.transform(e), a = v.transform(n);
  t[o.array] = `${r} ${a}`;
}
function $e(t, {
  attrX: e,
  attrY: n,
  attrScale: s,
  originX: i,
  originY: o,
  pathLength: r,
  pathSpacing: a = 1,
  pathOffset: c = 0,
  // This is object creation, which we try to avoid per-frame.
  ...l
}, u, h, f) {
  if (Ne(t, l, u, f), h) {
    t.style.viewBox && (t.attrs.viewBox = t.style.viewBox);
    return;
  }
  t.attrs = t.style, t.style = {};
  const { attrs: d, style: m, dimensions: p } = t;
  d.transform && (p && (m.transform = d.transform), delete d.transform), p && (i !== void 0 || o !== void 0 || m.transform) && (m.transformOrigin = yr(p, i !== void 0 ? i : 0.5, o !== void 0 ? o : 0.5)), e !== void 0 && (d.x = e), n !== void 0 && (d.y = n), s !== void 0 && (d.scale = s), r !== void 0 && Pr(d, r, a, c, !1);
}
const Ms = () => ({
  ...Ue(),
  attrs: {}
}), Ge = (t) => typeof t == "string" && t.toLowerCase() === "svg";
function Tr(t, e, n, s) {
  const i = Jt(() => {
    const o = Ms();
    return $e(o, e, { enableHardwareAcceleration: !1 }, Ge(s), t.transformTemplate), {
      ...o.attrs,
      style: { ...o.style }
    };
  }, [e]);
  if (t.style) {
    const o = {};
    Cs(o, t.style, t), i.style = { ...o, ...i.style };
  }
  return i;
}
function br(t = !1) {
  return (n, s, i, { latestValues: o }, r) => {
    const c = (Ie(n) ? Tr : dr)(s, o, r, n), u = {
      ...gr(s, typeof n == "string", t),
      ...c,
      ref: i
    }, { children: h } = s, f = Jt(() => O(h) ? h.get() : h, [h]);
    return Wi(n, {
      ...u,
      children: f
    });
  };
}
const We = (t) => t.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
function Ls(t, { style: e, vars: n }, s, i) {
  Object.assign(t.style, e, i && i.getProjectionStyles(s));
  for (const o in n)
    t.style.setProperty(o, n[o]);
}
const Rs = /* @__PURE__ */ new Set([
  "baseFrequency",
  "diffuseConstant",
  "kernelMatrix",
  "kernelUnitLength",
  "keySplines",
  "keyTimes",
  "limitingConeAngle",
  "markerHeight",
  "markerWidth",
  "numOctaves",
  "targetX",
  "targetY",
  "surfaceScale",
  "specularConstant",
  "specularExponent",
  "stdDeviation",
  "tableValues",
  "viewBox",
  "gradientTransform",
  "pathLength",
  "startOffset",
  "textLength",
  "lengthAdjust"
]);
function Bs(t, e, n, s) {
  Ls(t, e, void 0, s);
  for (const i in e.attrs)
    t.setAttribute(Rs.has(i) ? i : We(i), e.attrs[i]);
}
function He(t, e) {
  const { style: n } = t, s = {};
  for (const i in n)
    (O(n[i]) || e.style && O(e.style[i]) || bs(i, t)) && (s[i] = n[i]);
  return s;
}
function Es(t, e) {
  const n = He(t, e);
  for (const s in t)
    if (O(t[s]) || O(e[s])) {
      const i = Bt.indexOf(s) !== -1 ? "attr" + s.charAt(0).toUpperCase() + s.substring(1) : s;
      n[i] = t[s];
    }
  return n;
}
function ze(t, e, n, s = {}, i = {}) {
  return typeof e == "function" && (e = e(n !== void 0 ? n : t.custom, s, i)), typeof e == "string" && (e = t.variants && t.variants[e]), typeof e == "function" && (e = e(n !== void 0 ? n : t.custom, s, i)), e;
}
function Vr(t) {
  const e = Pe(null);
  return e.current === null && (e.current = t()), e.current;
}
const _t = (t) => Array.isArray(t), Sr = (t) => !!(t && typeof t == "object" && t.mix && t.toValue), Ar = (t) => _t(t) ? t[t.length - 1] || 0 : t;
function Wt(t) {
  const e = O(t) ? t.get() : t;
  return Sr(e) ? e.toValue() : e;
}
function wr({ scrapeMotionValuesFromProps: t, createRenderState: e, onMount: n }, s, i, o) {
  const r = {
    latestValues: Cr(s, i, o, t),
    renderState: e()
  };
  return n && (r.mount = (a) => n(s, a, r)), r;
}
const Fs = (t) => (e, n) => {
  const s = j(te), i = j(ke), o = () => wr(t, e, s, i);
  return n ? o() : Vr(o);
};
function Cr(t, e, n, s) {
  const i = {}, o = s(t, {});
  for (const f in o)
    i[f] = Wt(o[f]);
  let { initial: r, animate: a } = t;
  const c = se(t), l = xs(t);
  e && l && !c && t.inherit !== !1 && (r === void 0 && (r = e.initial), a === void 0 && (a = e.animate));
  let u = n ? n.initial === !1 : !1;
  u = u || r === !1;
  const h = u ? a : r;
  return h && typeof h != "boolean" && !ne(h) && (Array.isArray(h) ? h : [h]).forEach((d) => {
    const m = ze(t, d);
    if (!m)
      return;
    const { transitionEnd: p, transition: x, ...b } = m;
    for (const y in b) {
      let g = b[y];
      if (Array.isArray(g)) {
        const P = u ? g.length - 1 : 0;
        g = g[P];
      }
      g !== null && (i[y] = g);
    }
    for (const y in p)
      i[y] = p[y];
  }), i;
}
const M = (t) => t;
class gn {
  constructor() {
    this.order = [], this.scheduled = /* @__PURE__ */ new Set();
  }
  add(e) {
    if (!this.scheduled.has(e))
      return this.scheduled.add(e), this.order.push(e), !0;
  }
  remove(e) {
    const n = this.order.indexOf(e);
    n !== -1 && (this.order.splice(n, 1), this.scheduled.delete(e));
  }
  clear() {
    this.order.length = 0, this.scheduled.clear();
  }
}
function Dr(t) {
  let e = new gn(), n = new gn(), s = 0, i = !1, o = !1;
  const r = /* @__PURE__ */ new WeakSet(), a = {
    /**
     * Schedule a process to run on the next frame.
     */
    schedule: (c, l = !1, u = !1) => {
      const h = u && i, f = h ? e : n;
      return l && r.add(c), f.add(c) && h && i && (s = e.order.length), c;
    },
    /**
     * Cancel the provided callback from running on the next frame.
     */
    cancel: (c) => {
      n.remove(c), r.delete(c);
    },
    /**
     * Execute all schedule callbacks.
     */
    process: (c) => {
      if (i) {
        o = !0;
        return;
      }
      if (i = !0, [e, n] = [n, e], n.clear(), s = e.order.length, s)
        for (let l = 0; l < s; l++) {
          const u = e.order[l];
          u(c), r.has(u) && (a.schedule(u), t());
        }
      i = !1, o && (o = !1, a.process(c));
    }
  };
  return a;
}
const Nt = [
  "prepare",
  "read",
  "update",
  "preRender",
  "render",
  "postRender"
], Mr = 40;
function Lr(t, e) {
  let n = !1, s = !0;
  const i = {
    delta: 0,
    timestamp: 0,
    isProcessing: !1
  }, o = Nt.reduce((h, f) => (h[f] = Dr(() => n = !0), h), {}), r = (h) => o[h].process(i), a = () => {
    const h = performance.now();
    n = !1, i.delta = s ? 1e3 / 60 : Math.max(Math.min(h - i.timestamp, Mr), 1), i.timestamp = h, i.isProcessing = !0, Nt.forEach(r), i.isProcessing = !1, n && e && (s = !1, t(a));
  }, c = () => {
    n = !0, s = !0, i.isProcessing || t(a);
  };
  return { schedule: Nt.reduce((h, f) => {
    const d = o[f];
    return h[f] = (m, p = !1, x = !1) => (n || c(), d.schedule(m, p, x)), h;
  }, {}), cancel: (h) => Nt.forEach((f) => o[f].cancel(h)), state: i, steps: o };
}
const { schedule: S, cancel: Y, state: E, steps: ce } = Lr(typeof requestAnimationFrame < "u" ? requestAnimationFrame : M, !0), Rr = {
  useVisualState: Fs({
    scrapeMotionValuesFromProps: Es,
    createRenderState: Ms,
    onMount: (t, e, { renderState: n, latestValues: s }) => {
      S.read(() => {
        try {
          n.dimensions = typeof e.getBBox == "function" ? e.getBBox() : e.getBoundingClientRect();
        } catch {
          n.dimensions = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          };
        }
      }), S.render(() => {
        $e(n, s, { enableHardwareAcceleration: !1 }, Ge(e.tagName), t.transformTemplate), Bs(e, n);
      });
    }
  })
}, Br = {
  useVisualState: Fs({
    scrapeMotionValuesFromProps: He,
    createRenderState: Ue
  })
};
function Er(t, { forwardMotionProps: e = !1 }, n, s) {
  return {
    ...Ie(t) ? Rr : Br,
    preloadedFeatures: n,
    useRender: br(e),
    createVisualElement: s,
    Component: t
  };
}
function z(t, e, n, s = { passive: !0 }) {
  return t.addEventListener(e, n, s), () => t.removeEventListener(e, n);
}
const ks = (t) => t.pointerType === "mouse" ? typeof t.button != "number" || t.button <= 0 : t.isPrimary !== !1;
function re(t, e = "page") {
  return {
    point: {
      x: t[e + "X"],
      y: t[e + "Y"]
    }
  };
}
const Fr = (t) => (e) => ks(e) && t(e, re(e));
function K(t, e, n, s) {
  return z(t, e, Fr(n), s);
}
const kr = (t, e) => (n) => e(t(n)), tt = (...t) => t.reduce(kr);
function js(t) {
  let e = null;
  return () => {
    const n = () => {
      e = null;
    };
    return e === null ? (e = t, n) : !1;
  };
}
const yn = js("dragHorizontal"), vn = js("dragVertical");
function Os(t) {
  let e = !1;
  if (t === "y")
    e = vn();
  else if (t === "x")
    e = yn();
  else {
    const n = yn(), s = vn();
    n && s ? e = () => {
      n(), s();
    } : (n && n(), s && s());
  }
  return e;
}
function Is() {
  const t = Os(!0);
  return t ? (t(), !1) : !0;
}
class it {
  constructor(e) {
    this.isMounted = !1, this.node = e;
  }
  update() {
  }
}
function xn(t, e) {
  const n = "pointer" + (e ? "enter" : "leave"), s = "onHover" + (e ? "Start" : "End"), i = (o, r) => {
    if (o.type === "touch" || Is())
      return;
    const a = t.getProps();
    t.animationState && a.whileHover && t.animationState.setActive("whileHover", e), a[s] && S.update(() => a[s](o, r));
  };
  return K(t.current, n, i, {
    passive: !t.getProps()[s]
  });
}
class jr extends it {
  mount() {
    this.unmount = tt(xn(this.node, !0), xn(this.node, !1));
  }
  unmount() {
  }
}
class Or extends it {
  constructor() {
    super(...arguments), this.isActive = !1;
  }
  onFocus() {
    let e = !1;
    try {
      e = this.node.current.matches(":focus-visible");
    } catch {
      e = !0;
    }
    !e || !this.node.animationState || (this.node.animationState.setActive("whileFocus", !0), this.isActive = !0);
  }
  onBlur() {
    !this.isActive || !this.node.animationState || (this.node.animationState.setActive("whileFocus", !1), this.isActive = !1);
  }
  mount() {
    this.unmount = tt(z(this.node.current, "focus", () => this.onFocus()), z(this.node.current, "blur", () => this.onBlur()));
  }
  unmount() {
  }
}
const Ns = (t, e) => e ? t === e ? !0 : Ns(t, e.parentElement) : !1;
function le(t, e) {
  if (!e)
    return;
  const n = new PointerEvent("pointer" + t);
  e(n, re(n));
}
class Ir extends it {
  constructor() {
    super(...arguments), this.removeStartListeners = M, this.removeEndListeners = M, this.removeAccessibleListeners = M, this.startPointerPress = (e, n) => {
      if (this.removeEndListeners(), this.isPressing)
        return;
      const s = this.node.getProps(), o = K(window, "pointerup", (a, c) => {
        if (!this.checkPressEnd())
          return;
        const { onTap: l, onTapCancel: u } = this.node.getProps();
        S.update(() => {
          Ns(this.node.current, a.target) ? l && l(a, c) : u && u(a, c);
        });
      }, { passive: !(s.onTap || s.onPointerUp) }), r = K(window, "pointercancel", (a, c) => this.cancelPress(a, c), { passive: !(s.onTapCancel || s.onPointerCancel) });
      this.removeEndListeners = tt(o, r), this.startPress(e, n);
    }, this.startAccessiblePress = () => {
      const e = (o) => {
        if (o.key !== "Enter" || this.isPressing)
          return;
        const r = (a) => {
          a.key !== "Enter" || !this.checkPressEnd() || le("up", (c, l) => {
            const { onTap: u } = this.node.getProps();
            u && S.update(() => u(c, l));
          });
        };
        this.removeEndListeners(), this.removeEndListeners = z(this.node.current, "keyup", r), le("down", (a, c) => {
          this.startPress(a, c);
        });
      }, n = z(this.node.current, "keydown", e), s = () => {
        this.isPressing && le("cancel", (o, r) => this.cancelPress(o, r));
      }, i = z(this.node.current, "blur", s);
      this.removeAccessibleListeners = tt(n, i);
    };
  }
  startPress(e, n) {
    this.isPressing = !0;
    const { onTapStart: s, whileTap: i } = this.node.getProps();
    i && this.node.animationState && this.node.animationState.setActive("whileTap", !0), s && S.update(() => s(e, n));
  }
  checkPressEnd() {
    return this.removeEndListeners(), this.isPressing = !1, this.node.getProps().whileTap && this.node.animationState && this.node.animationState.setActive("whileTap", !1), !Is();
  }
  cancelPress(e, n) {
    if (!this.checkPressEnd())
      return;
    const { onTapCancel: s } = this.node.getProps();
    s && S.update(() => s(e, n));
  }
  mount() {
    const e = this.node.getProps(), n = K(this.node.current, "pointerdown", this.startPointerPress, { passive: !(e.onTapStart || e.onPointerStart) }), s = z(this.node.current, "focus", this.startAccessiblePress);
    this.removeStartListeners = tt(n, s);
  }
  unmount() {
    this.removeStartListeners(), this.removeEndListeners(), this.removeAccessibleListeners();
  }
}
const be = /* @__PURE__ */ new WeakMap(), ue = /* @__PURE__ */ new WeakMap(), Nr = (t) => {
  const e = be.get(t.target);
  e && e(t);
}, Ur = (t) => {
  t.forEach(Nr);
};
function $r({ root: t, ...e }) {
  const n = t || document;
  ue.has(n) || ue.set(n, {});
  const s = ue.get(n), i = JSON.stringify(e);
  return s[i] || (s[i] = new IntersectionObserver(Ur, { root: t, ...e })), s[i];
}
function Gr(t, e, n) {
  const s = $r(e);
  return be.set(t, n), s.observe(t), () => {
    be.delete(t), s.unobserve(t);
  };
}
const Wr = {
  some: 0,
  all: 1
};
class Hr extends it {
  constructor() {
    super(...arguments), this.hasEnteredView = !1, this.isInView = !1;
  }
  startObserver() {
    this.unmount();
    const { viewport: e = {} } = this.node.getProps(), { root: n, margin: s, amount: i = "some", once: o } = e, r = {
      root: n ? n.current : void 0,
      rootMargin: s,
      threshold: typeof i == "number" ? i : Wr[i]
    }, a = (c) => {
      const { isIntersecting: l } = c;
      if (this.isInView === l || (this.isInView = l, o && !l && this.hasEnteredView))
        return;
      l && (this.hasEnteredView = !0), this.node.animationState && this.node.animationState.setActive("whileInView", l);
      const { onViewportEnter: u, onViewportLeave: h } = this.node.getProps(), f = l ? u : h;
      f && f(c);
    };
    return Gr(this.node.current, r, a);
  }
  mount() {
    this.startObserver();
  }
  update() {
    if (typeof IntersectionObserver > "u")
      return;
    const { props: e, prevProps: n } = this.node;
    ["amount", "margin", "root"].some(zr(e, n)) && this.startObserver();
  }
  unmount() {
  }
}
function zr({ viewport: t = {} }, { viewport: e = {} } = {}) {
  return (n) => t[n] !== e[n];
}
const Kr = {
  inView: {
    Feature: Hr
  },
  tap: {
    Feature: Ir
  },
  focus: {
    Feature: Or
  },
  hover: {
    Feature: jr
  }
};
function Us(t, e) {
  if (!Array.isArray(e))
    return !1;
  const n = e.length;
  if (n !== t.length)
    return !1;
  for (let s = 0; s < n; s++)
    if (e[s] !== t[s])
      return !1;
  return !0;
}
function _r(t) {
  const e = {};
  return t.values.forEach((n, s) => e[s] = n.get()), e;
}
function Yr(t) {
  const e = {};
  return t.values.forEach((n, s) => e[s] = n.getVelocity()), e;
}
function oe(t, e, n) {
  const s = t.getProps();
  return ze(s, e, n !== void 0 ? n : s.custom, _r(t), Yr(t));
}
const Xr = "framerAppearId", qr = "data-" + We(Xr);
let kt = M, G = M;
process.env.NODE_ENV !== "production" && (kt = (t, e) => {
  !t && typeof console < "u" && console.warn(e);
}, G = (t, e) => {
  if (!t)
    throw new Error(e);
});
const et = (t) => t * 1e3, _ = (t) => t / 1e3, Zr = {
  current: !1
}, $s = (t) => Array.isArray(t) && typeof t[0] == "number";
function Gs(t) {
  return !!(!t || typeof t == "string" && Ws[t] || $s(t) || Array.isArray(t) && t.every(Gs));
}
const St = ([t, e, n, s]) => `cubic-bezier(${t}, ${e}, ${n}, ${s})`, Ws = {
  linear: "linear",
  ease: "ease",
  easeIn: "ease-in",
  easeOut: "ease-out",
  easeInOut: "ease-in-out",
  circIn: St([0, 0.65, 0.55, 1]),
  circOut: St([0.55, 0, 1, 0.45]),
  backIn: St([0.31, 0.01, 0.66, -0.59]),
  backOut: St([0.33, 1.53, 0.69, 0.99])
};
function Hs(t) {
  if (t)
    return $s(t) ? St(t) : Array.isArray(t) ? t.map(Hs) : Ws[t];
}
function Qr(t, e, n, { delay: s = 0, duration: i, repeat: o = 0, repeatType: r = "loop", ease: a, times: c } = {}) {
  const l = { [e]: n };
  c && (l.offset = c);
  const u = Hs(a);
  return Array.isArray(u) && (l.easing = u), t.animate(l, {
    delay: s,
    duration: i,
    easing: Array.isArray(u) ? "linear" : u,
    fill: "both",
    iterations: o + 1,
    direction: r === "reverse" ? "alternate" : "normal"
  });
}
function Jr(t, { repeat: e, repeatType: n = "loop" }) {
  const s = e && n !== "loop" && e % 2 === 1 ? 0 : t.length - 1;
  return t[s];
}
const zs = (t, e, n) => (((1 - 3 * n + 3 * e) * t + (3 * n - 6 * e)) * t + 3 * e) * t, to = 1e-7, eo = 12;
function no(t, e, n, s, i) {
  let o, r, a = 0;
  do
    r = e + (n - e) / 2, o = zs(r, s, i) - t, o > 0 ? n = r : e = r;
  while (Math.abs(o) > to && ++a < eo);
  return r;
}
function jt(t, e, n, s) {
  if (t === e && n === s)
    return M;
  const i = (o) => no(o, 0, 1, t, n);
  return (o) => o === 0 || o === 1 ? o : zs(i(o), e, s);
}
const so = jt(0.42, 0, 1, 1), io = jt(0, 0, 0.58, 1), Ks = jt(0.42, 0, 0.58, 1), ro = (t) => Array.isArray(t) && typeof t[0] != "number", _s = (t) => (e) => e <= 0.5 ? t(2 * e) / 2 : (2 - t(2 * (1 - e))) / 2, Ys = (t) => (e) => 1 - t(1 - e), Xs = (t) => 1 - Math.sin(Math.acos(t)), Ke = Ys(Xs), oo = _s(Ke), qs = jt(0.33, 1.53, 0.69, 0.99), _e = Ys(qs), ao = _s(_e), co = (t) => (t *= 2) < 1 ? 0.5 * _e(t) : 0.5 * (2 - Math.pow(2, -10 * (t - 1))), Pn = {
  linear: M,
  easeIn: so,
  easeInOut: Ks,
  easeOut: io,
  circIn: Xs,
  circInOut: oo,
  circOut: Ke,
  backIn: _e,
  backInOut: ao,
  backOut: qs,
  anticipate: co
}, Tn = (t) => {
  if (Array.isArray(t)) {
    G(t.length === 4, "Cubic bezier arrays must contain four numerical values.");
    const [e, n, s, i] = t;
    return jt(e, n, s, i);
  } else if (typeof t == "string")
    return G(Pn[t] !== void 0, `Invalid easing type '${t}'`), Pn[t];
  return t;
}, Ye = (t, e) => (n) => !!(Et(n) && cr.test(n) && n.startsWith(t) || e && Object.prototype.hasOwnProperty.call(n, e)), Zs = (t, e, n) => (s) => {
  if (!Et(s))
    return s;
  const [i, o, r, a] = s.match(ie);
  return {
    [t]: parseFloat(i),
    [e]: parseFloat(o),
    [n]: parseFloat(r),
    alpha: a !== void 0 ? parseFloat(a) : 1
  };
}, lo = (t) => nt(0, 255, t), he = {
  ...ht,
  transform: (t) => Math.round(lo(t))
}, lt = {
  test: Ye("rgb", "red"),
  parse: Zs("red", "green", "blue"),
  transform: ({ red: t, green: e, blue: n, alpha: s = 1 }) => "rgba(" + he.transform(t) + ", " + he.transform(e) + ", " + he.transform(n) + ", " + wt(At.transform(s)) + ")"
};
function uo(t) {
  let e = "", n = "", s = "", i = "";
  return t.length > 5 ? (e = t.substring(1, 3), n = t.substring(3, 5), s = t.substring(5, 7), i = t.substring(7, 9)) : (e = t.substring(1, 2), n = t.substring(2, 3), s = t.substring(3, 4), i = t.substring(4, 5), e += e, n += n, s += s, i += i), {
    red: parseInt(e, 16),
    green: parseInt(n, 16),
    blue: parseInt(s, 16),
    alpha: i ? parseInt(i, 16) / 255 : 1
  };
}
const Ve = {
  test: Ye("#"),
  parse: uo,
  transform: lt.transform
}, mt = {
  test: Ye("hsl", "hue"),
  parse: Zs("hue", "saturation", "lightness"),
  transform: ({ hue: t, saturation: e, lightness: n, alpha: s = 1 }) => "hsla(" + Math.round(t) + ", " + $.transform(wt(e)) + ", " + $.transform(wt(n)) + ", " + wt(At.transform(s)) + ")"
}, k = {
  test: (t) => lt.test(t) || Ve.test(t) || mt.test(t),
  parse: (t) => lt.test(t) ? lt.parse(t) : mt.test(t) ? mt.parse(t) : Ve.parse(t),
  transform: (t) => Et(t) ? t : t.hasOwnProperty("red") ? lt.transform(t) : mt.transform(t)
}, C = (t, e, n) => -n * t + n * e + t;
function fe(t, e, n) {
  return n < 0 && (n += 1), n > 1 && (n -= 1), n < 1 / 6 ? t + (e - t) * 6 * n : n < 1 / 2 ? e : n < 2 / 3 ? t + (e - t) * (2 / 3 - n) * 6 : t;
}
function ho({ hue: t, saturation: e, lightness: n, alpha: s }) {
  t /= 360, e /= 100, n /= 100;
  let i = 0, o = 0, r = 0;
  if (!e)
    i = o = r = n;
  else {
    const a = n < 0.5 ? n * (1 + e) : n + e - n * e, c = 2 * n - a;
    i = fe(c, a, t + 1 / 3), o = fe(c, a, t), r = fe(c, a, t - 1 / 3);
  }
  return {
    red: Math.round(i * 255),
    green: Math.round(o * 255),
    blue: Math.round(r * 255),
    alpha: s
  };
}
const de = (t, e, n) => {
  const s = t * t;
  return Math.sqrt(Math.max(0, n * (e * e - s) + s));
}, fo = [Ve, lt, mt], mo = (t) => fo.find((e) => e.test(t));
function bn(t) {
  const e = mo(t);
  G(!!e, `'${t}' is not an animatable color. Use the equivalent color code instead.`);
  let n = e.parse(t);
  return e === mt && (n = ho(n)), n;
}
const Qs = (t, e) => {
  const n = bn(t), s = bn(e), i = { ...n };
  return (o) => (i.red = de(n.red, s.red, o), i.green = de(n.green, s.green, o), i.blue = de(n.blue, s.blue, o), i.alpha = C(n.alpha, s.alpha, o), lt.transform(i));
};
function po(t) {
  var e, n;
  return isNaN(t) && Et(t) && (((e = t.match(ie)) === null || e === void 0 ? void 0 : e.length) || 0) + (((n = t.match(As)) === null || n === void 0 ? void 0 : n.length) || 0) > 0;
}
const Js = {
  regex: or,
  countKey: "Vars",
  token: "${v}",
  parse: M
}, ti = {
  regex: As,
  countKey: "Colors",
  token: "${c}",
  parse: k.parse
}, ei = {
  regex: ie,
  countKey: "Numbers",
  token: "${n}",
  parse: ht.parse
};
function me(t, { regex: e, countKey: n, token: s, parse: i }) {
  const o = t.tokenised.match(e);
  o && (t["num" + n] = o.length, t.tokenised = t.tokenised.replace(e, s), t.values.push(...o.map(i)));
}
function Yt(t) {
  const e = t.toString(), n = {
    value: e,
    tokenised: e,
    values: [],
    numVars: 0,
    numColors: 0,
    numNumbers: 0
  };
  return n.value.includes("var(--") && me(n, Js), me(n, ti), me(n, ei), n;
}
function ni(t) {
  return Yt(t).values;
}
function si(t) {
  const { values: e, numColors: n, numVars: s, tokenised: i } = Yt(t), o = e.length;
  return (r) => {
    let a = i;
    for (let c = 0; c < o; c++)
      c < s ? a = a.replace(Js.token, r[c]) : c < s + n ? a = a.replace(ti.token, k.transform(r[c])) : a = a.replace(ei.token, wt(r[c]));
    return a;
  };
}
const go = (t) => typeof t == "number" ? 0 : t;
function yo(t) {
  const e = ni(t);
  return si(t)(e.map(go));
}
const st = {
  test: po,
  parse: ni,
  createTransformer: si,
  getAnimatableNone: yo
}, ii = (t, e) => (n) => `${n > 0 ? e : t}`;
function ri(t, e) {
  return typeof t == "number" ? (n) => C(t, e, n) : k.test(t) ? Qs(t, e) : t.startsWith("var(") ? ii(t, e) : ai(t, e);
}
const oi = (t, e) => {
  const n = [...t], s = n.length, i = t.map((o, r) => ri(o, e[r]));
  return (o) => {
    for (let r = 0; r < s; r++)
      n[r] = i[r](o);
    return n;
  };
}, vo = (t, e) => {
  const n = { ...t, ...e }, s = {};
  for (const i in n)
    t[i] !== void 0 && e[i] !== void 0 && (s[i] = ri(t[i], e[i]));
  return (i) => {
    for (const o in s)
      n[o] = s[o](i);
    return n;
  };
}, ai = (t, e) => {
  const n = st.createTransformer(e), s = Yt(t), i = Yt(e);
  return s.numVars === i.numVars && s.numColors === i.numColors && s.numNumbers >= i.numNumbers ? tt(oi(s.values, i.values), n) : (kt(!0, `Complex values '${t}' and '${e}' too different to mix. Ensure all colors are of the same type, and that each contains the same quantity of number and color values. Falling back to instant transition.`), ii(t, e));
}, Rt = (t, e, n) => {
  const s = e - t;
  return s === 0 ? 1 : (n - t) / s;
}, Vn = (t, e) => (n) => C(t, e, n);
function xo(t) {
  return typeof t == "number" ? Vn : typeof t == "string" ? k.test(t) ? Qs : ai : Array.isArray(t) ? oi : typeof t == "object" ? vo : Vn;
}
function Po(t, e, n) {
  const s = [], i = n || xo(t[0]), o = t.length - 1;
  for (let r = 0; r < o; r++) {
    let a = i(t[r], t[r + 1]);
    if (e) {
      const c = Array.isArray(e) ? e[r] || M : e;
      a = tt(c, a);
    }
    s.push(a);
  }
  return s;
}
function ci(t, e, { clamp: n = !0, ease: s, mixer: i } = {}) {
  const o = t.length;
  if (G(o === e.length, "Both input and output ranges must be the same length"), o === 1)
    return () => e[0];
  t[0] > t[o - 1] && (t = [...t].reverse(), e = [...e].reverse());
  const r = Po(e, s, i), a = r.length, c = (l) => {
    let u = 0;
    if (a > 1)
      for (; u < t.length - 2 && !(l < t[u + 1]); u++)
        ;
    const h = Rt(t[u], t[u + 1], l);
    return r[u](h);
  };
  return n ? (l) => c(nt(t[0], t[o - 1], l)) : c;
}
function To(t, e) {
  const n = t[t.length - 1];
  for (let s = 1; s <= e; s++) {
    const i = Rt(0, e, s);
    t.push(C(n, 1, i));
  }
}
function bo(t) {
  const e = [0];
  return To(e, t.length - 1), e;
}
function Vo(t, e) {
  return t.map((n) => n * e);
}
function So(t, e) {
  return t.map(() => e || Ks).splice(0, t.length - 1);
}
function Xt({ duration: t = 300, keyframes: e, times: n, ease: s = "easeInOut" }) {
  const i = ro(s) ? s.map(Tn) : Tn(s), o = {
    done: !1,
    value: e[0]
  }, r = Vo(
    // Only use the provided offsets if they're the correct length
    // TODO Maybe we should warn here if there's a length mismatch
    n && n.length === e.length ? n : bo(e),
    t
  ), a = ci(r, e, {
    ease: Array.isArray(i) ? i : So(e, i)
  });
  return {
    calculatedDuration: t,
    next: (c) => (o.value = a(c), o.done = c >= t, o)
  };
}
function li(t, e) {
  return e ? t * (1e3 / e) : 0;
}
const Ao = 5;
function ui(t, e, n) {
  const s = Math.max(e - Ao, 0);
  return li(n - t(s), e - s);
}
const pe = 1e-3, wo = 0.01, Sn = 10, Co = 0.05, Do = 1;
function Mo({ duration: t = 800, bounce: e = 0.25, velocity: n = 0, mass: s = 1 }) {
  let i, o;
  kt(t <= et(Sn), "Spring duration must be 10 seconds or less");
  let r = 1 - e;
  r = nt(Co, Do, r), t = nt(wo, Sn, _(t)), r < 1 ? (i = (l) => {
    const u = l * r, h = u * t, f = u - n, d = Se(l, r), m = Math.exp(-h);
    return pe - f / d * m;
  }, o = (l) => {
    const h = l * r * t, f = h * n + n, d = Math.pow(r, 2) * Math.pow(l, 2) * t, m = Math.exp(-h), p = Se(Math.pow(l, 2), r);
    return (-i(l) + pe > 0 ? -1 : 1) * ((f - d) * m) / p;
  }) : (i = (l) => {
    const u = Math.exp(-l * t), h = (l - n) * t + 1;
    return -pe + u * h;
  }, o = (l) => {
    const u = Math.exp(-l * t), h = (n - l) * (t * t);
    return u * h;
  });
  const a = 5 / t, c = Ro(i, o, a);
  if (t = et(t), isNaN(c))
    return {
      stiffness: 100,
      damping: 10,
      duration: t
    };
  {
    const l = Math.pow(c, 2) * s;
    return {
      stiffness: l,
      damping: r * 2 * Math.sqrt(s * l),
      duration: t
    };
  }
}
const Lo = 12;
function Ro(t, e, n) {
  let s = n;
  for (let i = 1; i < Lo; i++)
    s = s - t(s) / e(s);
  return s;
}
function Se(t, e) {
  return t * Math.sqrt(1 - e * e);
}
const Bo = ["duration", "bounce"], Eo = ["stiffness", "damping", "mass"];
function An(t, e) {
  return e.some((n) => t[n] !== void 0);
}
function Fo(t) {
  let e = {
    velocity: 0,
    stiffness: 100,
    damping: 10,
    mass: 1,
    isResolvedFromDuration: !1,
    ...t
  };
  if (!An(t, Eo) && An(t, Bo)) {
    const n = Mo(t);
    e = {
      ...e,
      ...n,
      velocity: 0,
      mass: 1
    }, e.isResolvedFromDuration = !0;
  }
  return e;
}
function hi({ keyframes: t, restDelta: e, restSpeed: n, ...s }) {
  const i = t[0], o = t[t.length - 1], r = { done: !1, value: i }, { stiffness: a, damping: c, mass: l, velocity: u, duration: h, isResolvedFromDuration: f } = Fo(s), d = u ? -_(u) : 0, m = c / (2 * Math.sqrt(a * l)), p = o - i, x = _(Math.sqrt(a / l)), b = Math.abs(p) < 5;
  n || (n = b ? 0.01 : 2), e || (e = b ? 5e-3 : 0.5);
  let y;
  if (m < 1) {
    const g = Se(x, m);
    y = (P) => {
      const T = Math.exp(-m * x * P);
      return o - T * ((d + m * x * p) / g * Math.sin(g * P) + p * Math.cos(g * P));
    };
  } else if (m === 1)
    y = (g) => o - Math.exp(-x * g) * (p + (d + x * p) * g);
  else {
    const g = x * Math.sqrt(m * m - 1);
    y = (P) => {
      const T = Math.exp(-m * x * P), F = Math.min(g * P, 300);
      return o - T * ((d + m * x * p) * Math.sinh(F) + g * p * Math.cosh(F)) / g;
    };
  }
  return {
    calculatedDuration: f && h || null,
    next: (g) => {
      const P = y(g);
      if (f)
        r.done = g >= h;
      else {
        let T = d;
        g !== 0 && (m < 1 ? T = ui(y, g, P) : T = 0);
        const F = Math.abs(T) <= n, A = Math.abs(o - P) <= e;
        r.done = F && A;
      }
      return r.value = r.done ? o : P, r;
    }
  };
}
function wn({ keyframes: t, velocity: e = 0, power: n = 0.8, timeConstant: s = 325, bounceDamping: i = 10, bounceStiffness: o = 500, modifyTarget: r, min: a, max: c, restDelta: l = 0.5, restSpeed: u }) {
  const h = t[0], f = {
    done: !1,
    value: h
  }, d = (V) => a !== void 0 && V < a || c !== void 0 && V > c, m = (V) => a === void 0 ? c : c === void 0 || Math.abs(a - V) < Math.abs(c - V) ? a : c;
  let p = n * e;
  const x = h + p, b = r === void 0 ? x : r(x);
  b !== x && (p = b - h);
  const y = (V) => -p * Math.exp(-V / s), g = (V) => b + y(V), P = (V) => {
    const D = y(V), W = g(V);
    f.done = Math.abs(D) <= l, f.value = f.done ? b : W;
  };
  let T, F;
  const A = (V) => {
    d(f.value) && (T = V, F = hi({
      keyframes: [f.value, m(f.value)],
      velocity: ui(g, V, f.value),
      damping: i,
      stiffness: o,
      restDelta: l,
      restSpeed: u
    }));
  };
  return A(0), {
    calculatedDuration: null,
    next: (V) => {
      let D = !1;
      return !F && T === void 0 && (D = !0, P(V), A(V)), T !== void 0 && V > T ? F.next(V - T) : (!D && P(V), f);
    }
  };
}
const ko = (t) => {
  const e = ({ timestamp: n }) => t(n);
  return {
    start: () => S.update(e, !0),
    stop: () => Y(e),
    /**
     * If we're processing this frame we can use the
     * framelocked timestamp to keep things in sync.
     */
    now: () => E.isProcessing ? E.timestamp : performance.now()
  };
}, Cn = 2e4;
function Dn(t) {
  let e = 0;
  const n = 50;
  let s = t.next(e);
  for (; !s.done && e < Cn; )
    e += n, s = t.next(e);
  return e >= Cn ? 1 / 0 : e;
}
const jo = {
  decay: wn,
  inertia: wn,
  tween: Xt,
  keyframes: Xt,
  spring: hi
};
function qt({ autoplay: t = !0, delay: e = 0, driver: n = ko, keyframes: s, type: i = "keyframes", repeat: o = 0, repeatDelay: r = 0, repeatType: a = "loop", onPlay: c, onStop: l, onComplete: u, onUpdate: h, ...f }) {
  let d = 1, m = !1, p, x;
  const b = () => {
    x = new Promise((w) => {
      p = w;
    });
  };
  b();
  let y;
  const g = jo[i] || Xt;
  let P;
  g !== Xt && typeof s[0] != "number" && (P = ci([0, 100], s, {
    clamp: !1
  }), s = [0, 100]);
  const T = g({ ...f, keyframes: s });
  let F;
  a === "mirror" && (F = g({
    ...f,
    keyframes: [...s].reverse(),
    velocity: -(f.velocity || 0)
  }));
  let A = "idle", V = null, D = null, W = null;
  T.calculatedDuration === null && o && (T.calculatedDuration = Dn(T));
  const { calculatedDuration: H } = T;
  let X = 1 / 0, q = 1 / 0;
  H !== null && (X = H + r, q = X * (o + 1) - r);
  let B = 0;
  const R = (w) => {
    if (D === null)
      return;
    d > 0 && (D = Math.min(D, w)), d < 0 && (D = Math.min(w - q / d, D)), V !== null ? B = V : B = Math.round(w - D) * d;
    const Pt = B - e * (d >= 0 ? 1 : -1), sn = d >= 0 ? Pt < 0 : Pt > q;
    B = Math.max(Pt, 0), A === "finished" && V === null && (B = q);
    let rn = B, on = T;
    if (o) {
      const ae = B / X;
      let Ot = Math.floor(ae), rt = ae % 1;
      !rt && ae >= 1 && (rt = 1), rt === 1 && Ot--, Ot = Math.min(Ot, o + 1);
      const cn = !!(Ot % 2);
      cn && (a === "reverse" ? (rt = 1 - rt, r && (rt -= r / X)) : a === "mirror" && (on = F));
      let ln = nt(0, 1, rt);
      B > q && (ln = a === "reverse" && cn ? 1 : 0), rn = ln * X;
    }
    const Tt = sn ? { done: !1, value: s[0] } : on.next(rn);
    P && (Tt.value = P(Tt.value));
    let { done: an } = Tt;
    !sn && H !== null && (an = d >= 0 ? B >= q : B <= 0);
    const Ii = V === null && (A === "finished" || A === "running" && an);
    return h && h(Tt.value), Ii && Oi(), Tt;
  }, Z = () => {
    y && y.stop(), y = void 0;
  }, ft = () => {
    A = "idle", Z(), p(), b(), D = W = null;
  }, Oi = () => {
    A = "finished", u && u(), Z(), p();
  }, en = () => {
    if (m)
      return;
    y || (y = n(R));
    const w = y.now();
    c && c(), V !== null ? D = w - V : (!D || A === "finished") && (D = w), A === "finished" && b(), W = D, V = null, A = "running", y.start();
  };
  t && en();
  const nn = {
    then(w, Pt) {
      return x.then(w, Pt);
    },
    get time() {
      return _(B);
    },
    set time(w) {
      w = et(w), B = w, V !== null || !y || d === 0 ? V = w : D = y.now() - w / d;
    },
    get duration() {
      const w = T.calculatedDuration === null ? Dn(T) : T.calculatedDuration;
      return _(w);
    },
    get speed() {
      return d;
    },
    set speed(w) {
      w === d || !y || (d = w, nn.time = _(B));
    },
    get state() {
      return A;
    },
    play: en,
    pause: () => {
      A = "paused", V = B;
    },
    stop: () => {
      m = !0, A !== "idle" && (A = "idle", l && l(), ft());
    },
    cancel: () => {
      W !== null && R(W), ft();
    },
    complete: () => {
      A = "finished";
    },
    sample: (w) => (D = 0, R(w))
  };
  return nn;
}
function Oo(t) {
  let e;
  return () => (e === void 0 && (e = t()), e);
}
const Io = Oo(() => Object.hasOwnProperty.call(Element.prototype, "animate")), No = /* @__PURE__ */ new Set([
  "opacity",
  "clipPath",
  "filter",
  "transform",
  "backgroundColor"
]), Ut = 10, Uo = 2e4, $o = (t, e) => e.type === "spring" || t === "backgroundColor" || !Gs(e.ease);
function Go(t, e, { onUpdate: n, onComplete: s, ...i }) {
  if (!(Io() && No.has(e) && !i.repeatDelay && i.repeatType !== "mirror" && i.damping !== 0 && i.type !== "inertia"))
    return !1;
  let r = !1, a, c;
  const l = () => {
    c = new Promise((y) => {
      a = y;
    });
  };
  l();
  let { keyframes: u, duration: h = 300, ease: f, times: d } = i;
  if ($o(e, i)) {
    const y = qt({
      ...i,
      repeat: 0,
      delay: 0
    });
    let g = { done: !1, value: u[0] };
    const P = [];
    let T = 0;
    for (; !g.done && T < Uo; )
      g = y.sample(T), P.push(g.value), T += Ut;
    d = void 0, u = P, h = T - Ut, f = "linear";
  }
  const m = Qr(t.owner.current, e, u, {
    ...i,
    duration: h,
    /**
     * This function is currently not called if ease is provided
     * as a function so the cast is safe.
     *
     * However it would be possible for a future refinement to port
     * in easing pregeneration from Motion One for browsers that
     * support the upcoming `linear()` easing function.
     */
    ease: f,
    times: d
  });
  i.syncStart && (m.startTime = E.isProcessing ? E.timestamp : document.timeline ? document.timeline.currentTime : performance.now());
  const p = () => m.cancel(), x = () => {
    S.update(p), a(), l();
  };
  return m.onfinish = () => {
    t.set(Jr(u, i)), s && s(), x();
  }, {
    then(y, g) {
      return c.then(y, g);
    },
    attachTimeline(y) {
      return m.timeline = y, m.onfinish = null, M;
    },
    get time() {
      return _(m.currentTime || 0);
    },
    set time(y) {
      m.currentTime = et(y);
    },
    get speed() {
      return m.playbackRate;
    },
    set speed(y) {
      m.playbackRate = y;
    },
    get duration() {
      return _(h);
    },
    play: () => {
      r || (m.play(), Y(p));
    },
    pause: () => m.pause(),
    stop: () => {
      if (r = !0, m.playState === "idle")
        return;
      const { currentTime: y } = m;
      if (y) {
        const g = qt({
          ...i,
          autoplay: !1
        });
        t.setWithVelocity(g.sample(y - Ut).value, g.sample(y).value, Ut);
      }
      x();
    },
    complete: () => m.finish(),
    cancel: x
  };
}
function Wo({ keyframes: t, delay: e, onUpdate: n, onComplete: s }) {
  const i = () => (n && n(t[t.length - 1]), s && s(), {
    time: 0,
    speed: 1,
    duration: 0,
    play: M,
    pause: M,
    stop: M,
    then: (o) => (o(), Promise.resolve()),
    cancel: M,
    complete: M
  });
  return e ? qt({
    keyframes: [0, 1],
    duration: 0,
    delay: e,
    onComplete: i
  }) : i();
}
const Ho = {
  type: "spring",
  stiffness: 500,
  damping: 25,
  restSpeed: 10
}, zo = (t) => ({
  type: "spring",
  stiffness: 550,
  damping: t === 0 ? 2 * Math.sqrt(550) : 30,
  restSpeed: 10
}), Ko = {
  type: "keyframes",
  duration: 0.8
}, _o = {
  type: "keyframes",
  ease: [0.25, 0.1, 0.35, 1],
  duration: 0.3
}, Yo = (t, { keyframes: e }) => e.length > 2 ? Ko : ut.has(t) ? t.startsWith("scale") ? zo(e[1]) : Ho : _o, Ae = (t, e) => t === "zIndex" ? !1 : !!(typeof e == "number" || Array.isArray(e) || typeof e == "string" && // It's animatable if we have a string
(st.test(e) || e === "0") && // And it contains numbers and/or colors
!e.startsWith("url(")), Xo = /* @__PURE__ */ new Set(["brightness", "contrast", "saturate", "opacity"]);
function qo(t) {
  const [e, n] = t.slice(0, -1).split("(");
  if (e === "drop-shadow")
    return t;
  const [s] = n.match(ie) || [];
  if (!s)
    return t;
  const i = n.replace(s, "");
  let o = Xo.has(e) ? 1 : 0;
  return s !== n && (o *= 100), e + "(" + o + i + ")";
}
const Zo = /([a-z-]*)\(.*?\)/g, we = {
  ...st,
  getAnimatableNone: (t) => {
    const e = t.match(Zo);
    return e ? e.map(qo).join(" ") : t;
  }
}, Qo = {
  ...ws,
  // Color props
  color: k,
  backgroundColor: k,
  outlineColor: k,
  fill: k,
  stroke: k,
  // Border props
  borderColor: k,
  borderTopColor: k,
  borderRightColor: k,
  borderBottomColor: k,
  borderLeftColor: k,
  filter: we,
  WebkitFilter: we
}, Xe = (t) => Qo[t];
function fi(t, e) {
  let n = Xe(t);
  return n !== we && (n = st), n.getAnimatableNone ? n.getAnimatableNone(e) : void 0;
}
const di = (t) => /^0[^.\s]+$/.test(t);
function Jo(t) {
  if (typeof t == "number")
    return t === 0;
  if (t !== null)
    return t === "none" || t === "0" || di(t);
}
function ta(t, e, n, s) {
  const i = Ae(e, n);
  let o;
  Array.isArray(n) ? o = [...n] : o = [null, n];
  const r = s.from !== void 0 ? s.from : t.get();
  let a;
  const c = [];
  for (let l = 0; l < o.length; l++)
    o[l] === null && (o[l] = l === 0 ? r : o[l - 1]), Jo(o[l]) && c.push(l), typeof o[l] == "string" && o[l] !== "none" && o[l] !== "0" && (a = o[l]);
  if (i && c.length && a)
    for (let l = 0; l < c.length; l++) {
      const u = c[l];
      o[u] = fi(e, a);
    }
  return o;
}
function ea({ when: t, delay: e, delayChildren: n, staggerChildren: s, staggerDirection: i, repeat: o, repeatType: r, repeatDelay: a, from: c, elapsed: l, ...u }) {
  return !!Object.keys(u).length;
}
function mi(t, e) {
  return t[e] || t.default || t;
}
const qe = (t, e, n, s = {}) => (i) => {
  const o = mi(s, t) || {}, r = o.delay || s.delay || 0;
  let { elapsed: a = 0 } = s;
  a = a - et(r);
  const c = ta(e, t, n, o), l = c[0], u = c[c.length - 1], h = Ae(t, l), f = Ae(t, u);
  kt(h === f, `You are trying to animate ${t} from "${l}" to "${u}". ${l} is not an animatable value - to enable this animation set ${l} to a value animatable to ${u} via the \`style\` property.`);
  let d = {
    keyframes: c,
    velocity: e.getVelocity(),
    ease: "easeOut",
    ...o,
    delay: -a,
    onUpdate: (m) => {
      e.set(m), o.onUpdate && o.onUpdate(m);
    },
    onComplete: () => {
      i(), o.onComplete && o.onComplete();
    }
  };
  if (ea(o) || (d = {
    ...d,
    ...Yo(t, d)
  }), d.duration && (d.duration = et(d.duration)), d.repeatDelay && (d.repeatDelay = et(d.repeatDelay)), !h || !f || Zr.current || o.type === !1)
    return Wo(d);
  if (e.owner && e.owner.current instanceof HTMLElement && !e.owner.getProps().onUpdate) {
    const m = Go(e, t, d);
    if (m)
      return m;
  }
  return qt(d);
};
function Zt(t) {
  return !!(O(t) && t.add);
}
const pi = (t) => /^\-?\d*\.?\d+$/.test(t);
function Ze(t, e) {
  t.indexOf(e) === -1 && t.push(e);
}
function Qe(t, e) {
  const n = t.indexOf(e);
  n > -1 && t.splice(n, 1);
}
class Je {
  constructor() {
    this.subscriptions = [];
  }
  add(e) {
    return Ze(this.subscriptions, e), () => Qe(this.subscriptions, e);
  }
  notify(e, n, s) {
    const i = this.subscriptions.length;
    if (i)
      if (i === 1)
        this.subscriptions[0](e, n, s);
      else
        for (let o = 0; o < i; o++) {
          const r = this.subscriptions[o];
          r && r(e, n, s);
        }
  }
  getSize() {
    return this.subscriptions.length;
  }
  clear() {
    this.subscriptions.length = 0;
  }
}
const Mn = /* @__PURE__ */ new Set();
function tn(t, e, n) {
  t || Mn.has(e) || (console.warn(e), n && console.warn(n), Mn.add(e));
}
const na = (t) => !isNaN(parseFloat(t));
class sa {
  /**
   * @param init - The initiating value
   * @param config - Optional configuration options
   *
   * -  `transformer`: A function to transform incoming values with.
   *
   * @internal
   */
  constructor(e, n = {}) {
    this.version = "10.16.4", this.timeDelta = 0, this.lastUpdated = 0, this.canTrackVelocity = !1, this.events = {}, this.updateAndNotify = (s, i = !0) => {
      this.prev = this.current, this.current = s;
      const { delta: o, timestamp: r } = E;
      this.lastUpdated !== r && (this.timeDelta = o, this.lastUpdated = r, S.postRender(this.scheduleVelocityCheck)), this.prev !== this.current && this.events.change && this.events.change.notify(this.current), this.events.velocityChange && this.events.velocityChange.notify(this.getVelocity()), i && this.events.renderRequest && this.events.renderRequest.notify(this.current);
    }, this.scheduleVelocityCheck = () => S.postRender(this.velocityCheck), this.velocityCheck = ({ timestamp: s }) => {
      s !== this.lastUpdated && (this.prev = this.current, this.events.velocityChange && this.events.velocityChange.notify(this.getVelocity()));
    }, this.hasAnimated = !1, this.prev = this.current = e, this.canTrackVelocity = na(this.current), this.owner = n.owner;
  }
  /**
   * Adds a function that will be notified when the `MotionValue` is updated.
   *
   * It returns a function that, when called, will cancel the subscription.
   *
   * When calling `onChange` inside a React component, it should be wrapped with the
   * `useEffect` hook. As it returns an unsubscribe function, this should be returned
   * from the `useEffect` function to ensure you don't add duplicate subscribers..
   *
   * ```jsx
   * export const MyComponent = () => {
   *   const x = useMotionValue(0)
   *   const y = useMotionValue(0)
   *   const opacity = useMotionValue(1)
   *
   *   useEffect(() => {
   *     function updateOpacity() {
   *       const maxXY = Math.max(x.get(), y.get())
   *       const newOpacity = transform(maxXY, [0, 100], [1, 0])
   *       opacity.set(newOpacity)
   *     }
   *
   *     const unsubscribeX = x.on("change", updateOpacity)
   *     const unsubscribeY = y.on("change", updateOpacity)
   *
   *     return () => {
   *       unsubscribeX()
   *       unsubscribeY()
   *     }
   *   }, [])
   *
   *   return <motion.div style={{ x }} />
   * }
   * ```
   *
   * @param subscriber - A function that receives the latest value.
   * @returns A function that, when called, will cancel this subscription.
   *
   * @deprecated
   */
  onChange(e) {
    return process.env.NODE_ENV !== "production" && tn(!1, 'value.onChange(callback) is deprecated. Switch to value.on("change", callback).'), this.on("change", e);
  }
  on(e, n) {
    this.events[e] || (this.events[e] = new Je());
    const s = this.events[e].add(n);
    return e === "change" ? () => {
      s(), S.read(() => {
        this.events.change.getSize() || this.stop();
      });
    } : s;
  }
  clearListeners() {
    for (const e in this.events)
      this.events[e].clear();
  }
  /**
   * Attaches a passive effect to the `MotionValue`.
   *
   * @internal
   */
  attach(e, n) {
    this.passiveEffect = e, this.stopPassiveEffect = n;
  }
  /**
   * Sets the state of the `MotionValue`.
   *
   * @remarks
   *
   * ```jsx
   * const x = useMotionValue(0)
   * x.set(10)
   * ```
   *
   * @param latest - Latest value to set.
   * @param render - Whether to notify render subscribers. Defaults to `true`
   *
   * @public
   */
  set(e, n = !0) {
    !n || !this.passiveEffect ? this.updateAndNotify(e, n) : this.passiveEffect(e, this.updateAndNotify);
  }
  setWithVelocity(e, n, s) {
    this.set(n), this.prev = e, this.timeDelta = s;
  }
  /**
   * Set the state of the `MotionValue`, stopping any active animations,
   * effects, and resets velocity to `0`.
   */
  jump(e) {
    this.updateAndNotify(e), this.prev = e, this.stop(), this.stopPassiveEffect && this.stopPassiveEffect();
  }
  /**
   * Returns the latest state of `MotionValue`
   *
   * @returns - The latest state of `MotionValue`
   *
   * @public
   */
  get() {
    return this.current;
  }
  /**
   * @public
   */
  getPrevious() {
    return this.prev;
  }
  /**
   * Returns the latest velocity of `MotionValue`
   *
   * @returns - The latest velocity of `MotionValue`. Returns `0` if the state is non-numerical.
   *
   * @public
   */
  getVelocity() {
    return this.canTrackVelocity ? (
      // These casts could be avoided if parseFloat would be typed better
      li(parseFloat(this.current) - parseFloat(this.prev), this.timeDelta)
    ) : 0;
  }
  /**
   * Registers a new animation to control this `MotionValue`. Only one
   * animation can drive a `MotionValue` at one time.
   *
   * ```jsx
   * value.start()
   * ```
   *
   * @param animation - A function that starts the provided animation
   *
   * @internal
   */
  start(e) {
    return this.stop(), new Promise((n) => {
      this.hasAnimated = !0, this.animation = e(n), this.events.animationStart && this.events.animationStart.notify();
    }).then(() => {
      this.events.animationComplete && this.events.animationComplete.notify(), this.clearAnimation();
    });
  }
  /**
   * Stop the currently active animation.
   *
   * @public
   */
  stop() {
    this.animation && (this.animation.stop(), this.events.animationCancel && this.events.animationCancel.notify()), this.clearAnimation();
  }
  /**
   * Returns `true` if this value is currently animating.
   *
   * @public
   */
  isAnimating() {
    return !!this.animation;
  }
  clearAnimation() {
    delete this.animation;
  }
  /**
   * Destroy and clean up subscribers to this `MotionValue`.
   *
   * The `MotionValue` hooks like `useMotionValue` and `useTransform` automatically
   * handle the lifecycle of the returned `MotionValue`, so this method is only necessary if you've manually
   * created a `MotionValue` via the `motionValue` function.
   *
   * @public
   */
  destroy() {
    this.clearListeners(), this.stop(), this.stopPassiveEffect && this.stopPassiveEffect();
  }
}
function yt(t, e) {
  return new sa(t, e);
}
const gi = (t) => (e) => e.test(t), ia = {
  test: (t) => t === "auto",
  parse: (t) => t
}, yi = [ht, v, $, Q, ur, lr, ia], bt = (t) => yi.find(gi(t)), ra = [...yi, k, st], oa = (t) => ra.find(gi(t));
function aa(t, e, n) {
  t.hasValue(e) ? t.getValue(e).set(n) : t.addValue(e, yt(n));
}
function ca(t, e) {
  const n = oe(t, e);
  let { transitionEnd: s = {}, transition: i = {}, ...o } = n ? t.makeTargetAnimatable(n, !1) : {};
  o = { ...o, ...s };
  for (const r in o) {
    const a = Ar(o[r]);
    aa(t, r, a);
  }
}
function la(t, e, n) {
  var s, i;
  const o = Object.keys(e).filter((a) => !t.hasValue(a)), r = o.length;
  if (r)
    for (let a = 0; a < r; a++) {
      const c = o[a], l = e[c];
      let u = null;
      Array.isArray(l) && (u = l[0]), u === null && (u = (i = (s = n[c]) !== null && s !== void 0 ? s : t.readValue(c)) !== null && i !== void 0 ? i : e[c]), u != null && (typeof u == "string" && (pi(u) || di(u)) ? u = parseFloat(u) : !oa(u) && st.test(l) && (u = fi(c, l)), t.addValue(c, yt(u, { owner: t })), n[c] === void 0 && (n[c] = u), u !== null && t.setBaseTarget(c, u));
    }
}
function ua(t, e) {
  return e ? (e[t] || e.default || e).from : void 0;
}
function ha(t, e, n) {
  const s = {};
  for (const i in t) {
    const o = ua(i, e);
    if (o !== void 0)
      s[i] = o;
    else {
      const r = n.getValue(i);
      r && (s[i] = r.get());
    }
  }
  return s;
}
function fa({ protectedKeys: t, needsAnimating: e }, n) {
  const s = t.hasOwnProperty(n) && e[n] !== !0;
  return e[n] = !1, s;
}
function vi(t, e, { delay: n = 0, transitionOverride: s, type: i } = {}) {
  let { transition: o = t.getDefaultTransition(), transitionEnd: r, ...a } = t.makeTargetAnimatable(e);
  const c = t.getValue("willChange");
  s && (o = s);
  const l = [], u = i && t.animationState && t.animationState.getState()[i];
  for (const h in a) {
    const f = t.getValue(h), d = a[h];
    if (!f || d === void 0 || u && fa(u, h))
      continue;
    const m = {
      delay: n,
      elapsed: 0,
      ...o
    };
    if (window.HandoffAppearAnimations && !f.hasAnimated) {
      const x = t.getProps()[qr];
      x && (m.elapsed = window.HandoffAppearAnimations(x, h, f, S), m.syncStart = !0);
    }
    f.start(qe(h, f, d, t.shouldReduceMotion && ut.has(h) ? { type: !1 } : m));
    const p = f.animation;
    Zt(c) && (c.add(h), p.then(() => c.remove(h))), l.push(p);
  }
  return r && Promise.all(l).then(() => {
    r && ca(t, r);
  }), l;
}
function Ce(t, e, n = {}) {
  const s = oe(t, e, n.custom);
  let { transition: i = t.getDefaultTransition() || {} } = s || {};
  n.transitionOverride && (i = n.transitionOverride);
  const o = s ? () => Promise.all(vi(t, s, n)) : () => Promise.resolve(), r = t.variantChildren && t.variantChildren.size ? (c = 0) => {
    const { delayChildren: l = 0, staggerChildren: u, staggerDirection: h } = i;
    return da(t, e, l + c, u, h, n);
  } : () => Promise.resolve(), { when: a } = i;
  if (a) {
    const [c, l] = a === "beforeChildren" ? [o, r] : [r, o];
    return c().then(() => l());
  } else
    return Promise.all([o(), r(n.delay)]);
}
function da(t, e, n = 0, s = 0, i = 1, o) {
  const r = [], a = (t.variantChildren.size - 1) * s, c = i === 1 ? (l = 0) => l * s : (l = 0) => a - l * s;
  return Array.from(t.variantChildren).sort(ma).forEach((l, u) => {
    l.notify("AnimationStart", e), r.push(Ce(l, e, {
      ...o,
      delay: n + c(u)
    }).then(() => l.notify("AnimationComplete", e)));
  }), Promise.all(r);
}
function ma(t, e) {
  return t.sortNodePosition(e);
}
function pa(t, e, n = {}) {
  t.notify("AnimationStart", e);
  let s;
  if (Array.isArray(e)) {
    const i = e.map((o) => Ce(t, o, n));
    s = Promise.all(i);
  } else if (typeof e == "string")
    s = Ce(t, e, n);
  else {
    const i = typeof e == "function" ? oe(t, e, n.custom) : e;
    s = Promise.all(vi(t, i, n));
  }
  return s.then(() => t.notify("AnimationComplete", e));
}
const ga = [...je].reverse(), ya = je.length;
function va(t) {
  return (e) => Promise.all(e.map(({ animation: n, options: s }) => pa(t, n, s)));
}
function xa(t) {
  let e = va(t);
  const n = Ta();
  let s = !0;
  const i = (c, l) => {
    const u = oe(t, l);
    if (u) {
      const { transition: h, transitionEnd: f, ...d } = u;
      c = { ...c, ...d, ...f };
    }
    return c;
  };
  function o(c) {
    e = c(t);
  }
  function r(c, l) {
    const u = t.getProps(), h = t.getVariantContext(!0) || {}, f = [], d = /* @__PURE__ */ new Set();
    let m = {}, p = 1 / 0;
    for (let b = 0; b < ya; b++) {
      const y = ga[b], g = n[y], P = u[y] !== void 0 ? u[y] : h[y], T = Mt(P), F = y === l ? g.isActive : null;
      F === !1 && (p = b);
      let A = P === h[y] && P !== u[y] && T;
      if (A && s && t.manuallyAnimateOnMount && (A = !1), g.protectedKeys = { ...m }, // If it isn't active and hasn't *just* been set as inactive
      !g.isActive && F === null || // If we didn't and don't have any defined prop for this animation type
      !P && !g.prevProp || // Or if the prop doesn't define an animation
      ne(P) || typeof P == "boolean")
        continue;
      const V = Pa(g.prevProp, P);
      let D = V || // If we're making this variant active, we want to always make it active
      y === l && g.isActive && !A && T || // If we removed a higher-priority variant (i is in reverse order)
      b > p && T;
      const W = Array.isArray(P) ? P : [P];
      let H = W.reduce(i, {});
      F === !1 && (H = {});
      const { prevResolvedValues: X = {} } = g, q = {
        ...X,
        ...H
      }, B = (R) => {
        D = !0, d.delete(R), g.needsAnimating[R] = !0;
      };
      for (const R in q) {
        const Z = H[R], ft = X[R];
        m.hasOwnProperty(R) || (Z !== ft ? _t(Z) && _t(ft) ? !Us(Z, ft) || V ? B(R) : g.protectedKeys[R] = !0 : Z !== void 0 ? B(R) : d.add(R) : Z !== void 0 && d.has(R) ? B(R) : g.protectedKeys[R] = !0);
      }
      g.prevProp = P, g.prevResolvedValues = H, g.isActive && (m = { ...m, ...H }), s && t.blockInitialAnimation && (D = !1), D && !A && f.push(...W.map((R) => ({
        animation: R,
        options: { type: y, ...c }
      })));
    }
    if (d.size) {
      const b = {};
      d.forEach((y) => {
        const g = t.getBaseTarget(y);
        g !== void 0 && (b[y] = g);
      }), f.push({ animation: b });
    }
    let x = !!f.length;
    return s && u.initial === !1 && !t.manuallyAnimateOnMount && (x = !1), s = !1, x ? e(f) : Promise.resolve();
  }
  function a(c, l, u) {
    var h;
    if (n[c].isActive === l)
      return Promise.resolve();
    (h = t.variantChildren) === null || h === void 0 || h.forEach((d) => {
      var m;
      return (m = d.animationState) === null || m === void 0 ? void 0 : m.setActive(c, l);
    }), n[c].isActive = l;
    const f = r(u, c);
    for (const d in n)
      n[d].protectedKeys = {};
    return f;
  }
  return {
    animateChanges: r,
    setActive: a,
    setAnimateFunction: o,
    getState: () => n
  };
}
function Pa(t, e) {
  return typeof e == "string" ? e !== t : Array.isArray(e) ? !Us(e, t) : !1;
}
function ot(t = !1) {
  return {
    isActive: t,
    protectedKeys: {},
    needsAnimating: {},
    prevResolvedValues: {}
  };
}
function Ta() {
  return {
    animate: ot(!0),
    whileInView: ot(),
    whileHover: ot(),
    whileTap: ot(),
    whileDrag: ot(),
    whileFocus: ot(),
    exit: ot()
  };
}
class ba extends it {
  /**
   * We dynamically generate the AnimationState manager as it contains a reference
   * to the underlying animation library. We only want to load that if we load this,
   * so people can optionally code split it out using the `m` component.
   */
  constructor(e) {
    super(e), e.animationState || (e.animationState = xa(e));
  }
  updateAnimationControlsSubscription() {
    const { animate: e } = this.node.getProps();
    this.unmount(), ne(e) && (this.unmount = e.subscribe(this.node));
  }
  /**
   * Subscribe any provided AnimationControls to the component's VisualElement
   */
  mount() {
    this.updateAnimationControlsSubscription();
  }
  update() {
    const { animate: e } = this.node.getProps(), { animate: n } = this.node.prevProps || {};
    e !== n && this.updateAnimationControlsSubscription();
  }
  unmount() {
  }
}
let Va = 0;
class Sa extends it {
  constructor() {
    super(...arguments), this.id = Va++;
  }
  update() {
    if (!this.node.presenceContext)
      return;
    const { isPresent: e, onExitComplete: n, custom: s } = this.node.presenceContext, { isPresent: i } = this.node.prevPresenceContext || {};
    if (!this.node.animationState || e === i)
      return;
    const o = this.node.animationState.setActive("exit", !e, { custom: s ?? this.node.getProps().custom });
    n && !e && o.then(() => n(this.id));
  }
  mount() {
    const { register: e } = this.node.presenceContext || {};
    e && (this.unmount = e(this.id));
  }
  unmount() {
  }
}
const Aa = {
  animation: {
    Feature: ba
  },
  exit: {
    Feature: Sa
  }
}, Ln = (t, e) => Math.abs(t - e);
function wa(t, e) {
  const n = Ln(t.x, e.x), s = Ln(t.y, e.y);
  return Math.sqrt(n ** 2 + s ** 2);
}
class xi {
  constructor(e, n, { transformPagePoint: s } = {}) {
    if (this.startEvent = null, this.lastMoveEvent = null, this.lastMoveEventInfo = null, this.handlers = {}, this.updatePoint = () => {
      if (!(this.lastMoveEvent && this.lastMoveEventInfo))
        return;
      const l = ye(this.lastMoveEventInfo, this.history), u = this.startEvent !== null, h = wa(l.offset, { x: 0, y: 0 }) >= 3;
      if (!u && !h)
        return;
      const { point: f } = l, { timestamp: d } = E;
      this.history.push({ ...f, timestamp: d });
      const { onStart: m, onMove: p } = this.handlers;
      u || (m && m(this.lastMoveEvent, l), this.startEvent = this.lastMoveEvent), p && p(this.lastMoveEvent, l);
    }, this.handlePointerMove = (l, u) => {
      this.lastMoveEvent = l, this.lastMoveEventInfo = ge(u, this.transformPagePoint), S.update(this.updatePoint, !0);
    }, this.handlePointerUp = (l, u) => {
      if (this.end(), !(this.lastMoveEvent && this.lastMoveEventInfo))
        return;
      const { onEnd: h, onSessionEnd: f } = this.handlers, d = ye(l.type === "pointercancel" ? this.lastMoveEventInfo : ge(u, this.transformPagePoint), this.history);
      this.startEvent && h && h(l, d), f && f(l, d);
    }, !ks(e))
      return;
    this.handlers = n, this.transformPagePoint = s;
    const i = re(e), o = ge(i, this.transformPagePoint), { point: r } = o, { timestamp: a } = E;
    this.history = [{ ...r, timestamp: a }];
    const { onSessionStart: c } = n;
    c && c(e, ye(o, this.history)), this.removeListeners = tt(K(window, "pointermove", this.handlePointerMove), K(window, "pointerup", this.handlePointerUp), K(window, "pointercancel", this.handlePointerUp));
  }
  updateHandlers(e) {
    this.handlers = e;
  }
  end() {
    this.removeListeners && this.removeListeners(), Y(this.updatePoint);
  }
}
function ge(t, e) {
  return e ? { point: e(t.point) } : t;
}
function Rn(t, e) {
  return { x: t.x - e.x, y: t.y - e.y };
}
function ye({ point: t }, e) {
  return {
    point: t,
    delta: Rn(t, Pi(e)),
    offset: Rn(t, Ca(e)),
    velocity: Da(e, 0.1)
  };
}
function Ca(t) {
  return t[0];
}
function Pi(t) {
  return t[t.length - 1];
}
function Da(t, e) {
  if (t.length < 2)
    return { x: 0, y: 0 };
  let n = t.length - 1, s = null;
  const i = Pi(t);
  for (; n >= 0 && (s = t[n], !(i.timestamp - s.timestamp > et(e))); )
    n--;
  if (!s)
    return { x: 0, y: 0 };
  const o = _(i.timestamp - s.timestamp);
  if (o === 0)
    return { x: 0, y: 0 };
  const r = {
    x: (i.x - s.x) / o,
    y: (i.y - s.y) / o
  };
  return r.x === 1 / 0 && (r.x = 0), r.y === 1 / 0 && (r.y = 0), r;
}
function I(t) {
  return t.max - t.min;
}
function De(t, e = 0, n = 0.01) {
  return Math.abs(t - e) <= n;
}
function Bn(t, e, n, s = 0.5) {
  t.origin = s, t.originPoint = C(e.min, e.max, t.origin), t.scale = I(n) / I(e), (De(t.scale, 1, 1e-4) || isNaN(t.scale)) && (t.scale = 1), t.translate = C(n.min, n.max, t.origin) - t.originPoint, (De(t.translate) || isNaN(t.translate)) && (t.translate = 0);
}
function Ct(t, e, n, s) {
  Bn(t.x, e.x, n.x, s ? s.originX : void 0), Bn(t.y, e.y, n.y, s ? s.originY : void 0);
}
function En(t, e, n) {
  t.min = n.min + e.min, t.max = t.min + I(e);
}
function Ma(t, e, n) {
  En(t.x, e.x, n.x), En(t.y, e.y, n.y);
}
function Fn(t, e, n) {
  t.min = e.min - n.min, t.max = t.min + I(e);
}
function Dt(t, e, n) {
  Fn(t.x, e.x, n.x), Fn(t.y, e.y, n.y);
}
function La(t, { min: e, max: n }, s) {
  return e !== void 0 && t < e ? t = s ? C(e, t, s.min) : Math.max(t, e) : n !== void 0 && t > n && (t = s ? C(n, t, s.max) : Math.min(t, n)), t;
}
function kn(t, e, n) {
  return {
    min: e !== void 0 ? t.min + e : void 0,
    max: n !== void 0 ? t.max + n - (t.max - t.min) : void 0
  };
}
function Ra(t, { top: e, left: n, bottom: s, right: i }) {
  return {
    x: kn(t.x, n, i),
    y: kn(t.y, e, s)
  };
}
function jn(t, e) {
  let n = e.min - t.min, s = e.max - t.max;
  return e.max - e.min < t.max - t.min && ([n, s] = [s, n]), { min: n, max: s };
}
function Ba(t, e) {
  return {
    x: jn(t.x, e.x),
    y: jn(t.y, e.y)
  };
}
function Ea(t, e) {
  let n = 0.5;
  const s = I(t), i = I(e);
  return i > s ? n = Rt(e.min, e.max - s, t.min) : s > i && (n = Rt(t.min, t.max - i, e.min)), nt(0, 1, n);
}
function Fa(t, e) {
  const n = {};
  return e.min !== void 0 && (n.min = e.min - t.min), e.max !== void 0 && (n.max = e.max - t.min), n;
}
const Me = 0.35;
function ka(t = Me) {
  return t === !1 ? t = 0 : t === !0 && (t = Me), {
    x: On(t, "left", "right"),
    y: On(t, "top", "bottom")
  };
}
function On(t, e, n) {
  return {
    min: In(t, e),
    max: In(t, n)
  };
}
function In(t, e) {
  return typeof t == "number" ? t : t[e] || 0;
}
const Nn = () => ({
  translate: 0,
  scale: 1,
  origin: 0,
  originPoint: 0
}), pt = () => ({
  x: Nn(),
  y: Nn()
}), Un = () => ({ min: 0, max: 0 }), L = () => ({
  x: Un(),
  y: Un()
});
function U(t) {
  return [t("x"), t("y")];
}
function Ti({ top: t, left: e, right: n, bottom: s }) {
  return {
    x: { min: e, max: n },
    y: { min: t, max: s }
  };
}
function ja({ x: t, y: e }) {
  return { top: e.min, right: t.max, bottom: e.max, left: t.min };
}
function Oa(t, e) {
  if (!e)
    return t;
  const n = e({ x: t.left, y: t.top }), s = e({ x: t.right, y: t.bottom });
  return {
    top: n.y,
    left: n.x,
    bottom: s.y,
    right: s.x
  };
}
function ve(t) {
  return t === void 0 || t === 1;
}
function Le({ scale: t, scaleX: e, scaleY: n }) {
  return !ve(t) || !ve(e) || !ve(n);
}
function at(t) {
  return Le(t) || bi(t) || t.z || t.rotate || t.rotateX || t.rotateY;
}
function bi(t) {
  return $n(t.x) || $n(t.y);
}
function $n(t) {
  return t && t !== "0%";
}
function Qt(t, e, n) {
  const s = t - n, i = e * s;
  return n + i;
}
function Gn(t, e, n, s, i) {
  return i !== void 0 && (t = Qt(t, i, s)), Qt(t, n, s) + e;
}
function Re(t, e = 0, n = 1, s, i) {
  t.min = Gn(t.min, e, n, s, i), t.max = Gn(t.max, e, n, s, i);
}
function Vi(t, { x: e, y: n }) {
  Re(t.x, e.translate, e.scale, e.originPoint), Re(t.y, n.translate, n.scale, n.originPoint);
}
function Ia(t, e, n, s = !1) {
  const i = n.length;
  if (!i)
    return;
  e.x = e.y = 1;
  let o, r;
  for (let a = 0; a < i; a++) {
    o = n[a], r = o.projectionDelta;
    const c = o.instance;
    c && c.style && c.style.display === "contents" || (s && o.options.layoutScroll && o.scroll && o !== o.root && gt(t, {
      x: -o.scroll.offset.x,
      y: -o.scroll.offset.y
    }), r && (e.x *= r.x.scale, e.y *= r.y.scale, Vi(t, r)), s && at(o.latestValues) && gt(t, o.latestValues));
  }
  e.x = Wn(e.x), e.y = Wn(e.y);
}
function Wn(t) {
  return Number.isInteger(t) || t > 1.0000000000001 || t < 0.999999999999 ? t : 1;
}
function J(t, e) {
  t.min = t.min + e, t.max = t.max + e;
}
function Hn(t, e, [n, s, i]) {
  const o = e[i] !== void 0 ? e[i] : 0.5, r = C(t.min, t.max, o);
  Re(t, e[n], e[s], r, e.scale);
}
const Na = ["x", "scaleX", "originX"], Ua = ["y", "scaleY", "originY"];
function gt(t, e) {
  Hn(t.x, e, Na), Hn(t.y, e, Ua);
}
function Si(t, e) {
  return Ti(Oa(t.getBoundingClientRect(), e));
}
function $a(t, e, n) {
  const s = Si(t, n), { scroll: i } = e;
  return i && (J(s.x, i.offset.x), J(s.y, i.offset.y)), s;
}
const Ga = /* @__PURE__ */ new WeakMap();
class Wa {
  constructor(e) {
    this.openGlobalLock = null, this.isDragging = !1, this.currentDirection = null, this.originPoint = { x: 0, y: 0 }, this.constraints = !1, this.hasMutatedConstraints = !1, this.elastic = L(), this.visualElement = e;
  }
  start(e, { snapToCursor: n = !1 } = {}) {
    const { presenceContext: s } = this.visualElement;
    if (s && s.isPresent === !1)
      return;
    const i = (c) => {
      this.stopAnimation(), n && this.snapToCursor(re(c, "page").point);
    }, o = (c, l) => {
      const { drag: u, dragPropagation: h, onDragStart: f } = this.getProps();
      if (u && !h && (this.openGlobalLock && this.openGlobalLock(), this.openGlobalLock = Os(u), !this.openGlobalLock))
        return;
      this.isDragging = !0, this.currentDirection = null, this.resolveConstraints(), this.visualElement.projection && (this.visualElement.projection.isAnimationBlocked = !0, this.visualElement.projection.target = void 0), U((m) => {
        let p = this.getAxisMotionValue(m).get() || 0;
        if ($.test(p)) {
          const { projection: x } = this.visualElement;
          if (x && x.layout) {
            const b = x.layout.layoutBox[m];
            b && (p = I(b) * (parseFloat(p) / 100));
          }
        }
        this.originPoint[m] = p;
      }), f && S.update(() => f(c, l), !1, !0);
      const { animationState: d } = this.visualElement;
      d && d.setActive("whileDrag", !0);
    }, r = (c, l) => {
      const { dragPropagation: u, dragDirectionLock: h, onDirectionLock: f, onDrag: d } = this.getProps();
      if (!u && !this.openGlobalLock)
        return;
      const { offset: m } = l;
      if (h && this.currentDirection === null) {
        this.currentDirection = Ha(m), this.currentDirection !== null && f && f(this.currentDirection);
        return;
      }
      this.updateAxis("x", l.point, m), this.updateAxis("y", l.point, m), this.visualElement.render(), d && d(c, l);
    }, a = (c, l) => this.stop(c, l);
    this.panSession = new xi(e, {
      onSessionStart: i,
      onStart: o,
      onMove: r,
      onSessionEnd: a
    }, { transformPagePoint: this.visualElement.getTransformPagePoint() });
  }
  stop(e, n) {
    const s = this.isDragging;
    if (this.cancel(), !s)
      return;
    const { velocity: i } = n;
    this.startAnimation(i);
    const { onDragEnd: o } = this.getProps();
    o && S.update(() => o(e, n));
  }
  cancel() {
    this.isDragging = !1;
    const { projection: e, animationState: n } = this.visualElement;
    e && (e.isAnimationBlocked = !1), this.panSession && this.panSession.end(), this.panSession = void 0;
    const { dragPropagation: s } = this.getProps();
    !s && this.openGlobalLock && (this.openGlobalLock(), this.openGlobalLock = null), n && n.setActive("whileDrag", !1);
  }
  updateAxis(e, n, s) {
    const { drag: i } = this.getProps();
    if (!s || !$t(e, i, this.currentDirection))
      return;
    const o = this.getAxisMotionValue(e);
    let r = this.originPoint[e] + s[e];
    this.constraints && this.constraints[e] && (r = La(r, this.constraints[e], this.elastic[e])), o.set(r);
  }
  resolveConstraints() {
    const { dragConstraints: e, dragElastic: n } = this.getProps(), { layout: s } = this.visualElement.projection || {}, i = this.constraints;
    e && dt(e) ? this.constraints || (this.constraints = this.resolveRefConstraints()) : e && s ? this.constraints = Ra(s.layoutBox, e) : this.constraints = !1, this.elastic = ka(n), i !== this.constraints && s && this.constraints && !this.hasMutatedConstraints && U((o) => {
      this.getAxisMotionValue(o) && (this.constraints[o] = Fa(s.layoutBox[o], this.constraints[o]));
    });
  }
  resolveRefConstraints() {
    const { dragConstraints: e, onMeasureDragConstraints: n } = this.getProps();
    if (!e || !dt(e))
      return !1;
    const s = e.current;
    G(s !== null, "If `dragConstraints` is set as a React ref, that ref must be passed to another component's `ref` prop.");
    const { projection: i } = this.visualElement;
    if (!i || !i.layout)
      return !1;
    const o = $a(s, i.root, this.visualElement.getTransformPagePoint());
    let r = Ba(i.layout.layoutBox, o);
    if (n) {
      const a = n(ja(r));
      this.hasMutatedConstraints = !!a, a && (r = Ti(a));
    }
    return r;
  }
  startAnimation(e) {
    const { drag: n, dragMomentum: s, dragElastic: i, dragTransition: o, dragSnapToOrigin: r, onDragTransitionEnd: a } = this.getProps(), c = this.constraints || {}, l = U((u) => {
      if (!$t(u, n, this.currentDirection))
        return;
      let h = c && c[u] || {};
      r && (h = { min: 0, max: 0 });
      const f = i ? 200 : 1e6, d = i ? 40 : 1e7, m = {
        type: "inertia",
        velocity: s ? e[u] : 0,
        bounceStiffness: f,
        bounceDamping: d,
        timeConstant: 750,
        restDelta: 1,
        restSpeed: 10,
        ...o,
        ...h
      };
      return this.startAxisValueAnimation(u, m);
    });
    return Promise.all(l).then(a);
  }
  startAxisValueAnimation(e, n) {
    const s = this.getAxisMotionValue(e);
    return s.start(qe(e, s, 0, n));
  }
  stopAnimation() {
    U((e) => this.getAxisMotionValue(e).stop());
  }
  /**
   * Drag works differently depending on which props are provided.
   *
   * - If _dragX and _dragY are provided, we output the gesture delta directly to those motion values.
   * - Otherwise, we apply the delta to the x/y motion values.
   */
  getAxisMotionValue(e) {
    const n = "_drag" + e.toUpperCase(), s = this.visualElement.getProps(), i = s[n];
    return i || this.visualElement.getValue(e, (s.initial ? s.initial[e] : void 0) || 0);
  }
  snapToCursor(e) {
    U((n) => {
      const { drag: s } = this.getProps();
      if (!$t(n, s, this.currentDirection))
        return;
      const { projection: i } = this.visualElement, o = this.getAxisMotionValue(n);
      if (i && i.layout) {
        const { min: r, max: a } = i.layout.layoutBox[n];
        o.set(e[n] - C(r, a, 0.5));
      }
    });
  }
  /**
   * When the viewport resizes we want to check if the measured constraints
   * have changed and, if so, reposition the element within those new constraints
   * relative to where it was before the resize.
   */
  scalePositionWithinConstraints() {
    if (!this.visualElement.current)
      return;
    const { drag: e, dragConstraints: n } = this.getProps(), { projection: s } = this.visualElement;
    if (!dt(n) || !s || !this.constraints)
      return;
    this.stopAnimation();
    const i = { x: 0, y: 0 };
    U((r) => {
      const a = this.getAxisMotionValue(r);
      if (a) {
        const c = a.get();
        i[r] = Ea({ min: c, max: c }, this.constraints[r]);
      }
    });
    const { transformTemplate: o } = this.visualElement.getProps();
    this.visualElement.current.style.transform = o ? o({}, "") : "none", s.root && s.root.updateScroll(), s.updateLayout(), this.resolveConstraints(), U((r) => {
      if (!$t(r, e, null))
        return;
      const a = this.getAxisMotionValue(r), { min: c, max: l } = this.constraints[r];
      a.set(C(c, l, i[r]));
    });
  }
  addListeners() {
    if (!this.visualElement.current)
      return;
    Ga.set(this.visualElement, this);
    const e = this.visualElement.current, n = K(e, "pointerdown", (c) => {
      const { drag: l, dragListener: u = !0 } = this.getProps();
      l && u && this.start(c);
    }), s = () => {
      const { dragConstraints: c } = this.getProps();
      dt(c) && (this.constraints = this.resolveRefConstraints());
    }, { projection: i } = this.visualElement, o = i.addEventListener("measure", s);
    i && !i.layout && (i.root && i.root.updateScroll(), i.updateLayout()), s();
    const r = z(window, "resize", () => this.scalePositionWithinConstraints()), a = i.addEventListener("didUpdate", ({ delta: c, hasLayoutChanged: l }) => {
      this.isDragging && l && (U((u) => {
        const h = this.getAxisMotionValue(u);
        h && (this.originPoint[u] += c[u].translate, h.set(h.get() + c[u].translate));
      }), this.visualElement.render());
    });
    return () => {
      r(), n(), o(), a && a();
    };
  }
  getProps() {
    const e = this.visualElement.getProps(), { drag: n = !1, dragDirectionLock: s = !1, dragPropagation: i = !1, dragConstraints: o = !1, dragElastic: r = Me, dragMomentum: a = !0 } = e;
    return {
      ...e,
      drag: n,
      dragDirectionLock: s,
      dragPropagation: i,
      dragConstraints: o,
      dragElastic: r,
      dragMomentum: a
    };
  }
}
function $t(t, e, n) {
  return (e === !0 || e === t) && (n === null || n === t);
}
function Ha(t, e = 10) {
  let n = null;
  return Math.abs(t.y) > e ? n = "y" : Math.abs(t.x) > e && (n = "x"), n;
}
class za extends it {
  constructor(e) {
    super(e), this.removeGroupControls = M, this.removeListeners = M, this.controls = new Wa(e);
  }
  mount() {
    const { dragControls: e } = this.node.getProps();
    e && (this.removeGroupControls = e.subscribe(this.controls)), this.removeListeners = this.controls.addListeners() || M;
  }
  unmount() {
    this.removeGroupControls(), this.removeListeners();
  }
}
const zn = (t) => (e, n) => {
  t && S.update(() => t(e, n));
};
class Ka extends it {
  constructor() {
    super(...arguments), this.removePointerDownListener = M;
  }
  onPointerDown(e) {
    this.session = new xi(e, this.createPanHandlers(), { transformPagePoint: this.node.getTransformPagePoint() });
  }
  createPanHandlers() {
    const { onPanSessionStart: e, onPanStart: n, onPan: s, onPanEnd: i } = this.node.getProps();
    return {
      onSessionStart: zn(e),
      onStart: zn(n),
      onMove: s,
      onEnd: (o, r) => {
        delete this.session, i && S.update(() => i(o, r));
      }
    };
  }
  mount() {
    this.removePointerDownListener = K(this.node.current, "pointerdown", (e) => this.onPointerDown(e));
  }
  update() {
    this.session && this.session.updateHandlers(this.createPanHandlers());
  }
  unmount() {
    this.removePointerDownListener(), this.session && this.session.end();
  }
}
function _a() {
  const t = j(ke);
  if (t === null)
    return [!0, null];
  const { isPresent: e, onExitComplete: n, register: s } = t, i = Hi();
  return Fe(() => s(i), []), !e && n ? [!1, () => n && n(i)] : [!0];
}
const Ht = {
  /**
   * Global flag as to whether the tree has animated since the last time
   * we resized the window
   */
  hasAnimatedSinceResize: !0,
  /**
   * We set this to true once, on the first update. Any nodes added to the tree beyond that
   * update will be given a `data-projection-id` attribute.
   */
  hasEverUpdated: !1
};
function Kn(t, e) {
  return e.max === e.min ? 0 : t / (e.max - e.min) * 100;
}
const Vt = {
  correct: (t, e) => {
    if (!e.target)
      return t;
    if (typeof t == "string")
      if (v.test(t))
        t = parseFloat(t);
      else
        return t;
    const n = Kn(t, e.target.x), s = Kn(t, e.target.y);
    return `${n}% ${s}%`;
  }
}, Ya = {
  correct: (t, { treeScale: e, projectionDelta: n }) => {
    const s = t, i = st.parse(t);
    if (i.length > 5)
      return s;
    const o = st.createTransformer(t), r = typeof i[0] != "number" ? 1 : 0, a = n.x.scale * e.x, c = n.y.scale * e.y;
    i[0 + r] /= a, i[1 + r] /= c;
    const l = C(a, c, 0.5);
    return typeof i[2 + r] == "number" && (i[2 + r] /= l), typeof i[3 + r] == "number" && (i[3 + r] /= l), o(i);
  }
};
class Xa extends gs.Component {
  /**
   * This only mounts projection nodes for components that
   * need measuring, we might want to do it for all components
   * in order to incorporate transforms
   */
  componentDidMount() {
    const { visualElement: e, layoutGroup: n, switchLayoutGroup: s, layoutId: i } = this.props, { projection: o } = e;
    nr(qa), o && (n.group && n.group.add(o), s && s.register && i && s.register(o), o.root.didUpdate(), o.addEventListener("animationComplete", () => {
      this.safeToRemove();
    }), o.setOptions({
      ...o.options,
      onExitComplete: () => this.safeToRemove()
    })), Ht.hasEverUpdated = !0;
  }
  getSnapshotBeforeUpdate(e) {
    const { layoutDependency: n, visualElement: s, drag: i, isPresent: o } = this.props, r = s.projection;
    return r && (r.isPresent = o, i || e.layoutDependency !== n || n === void 0 ? r.willUpdate() : this.safeToRemove(), e.isPresent !== o && (o ? r.promote() : r.relegate() || S.postRender(() => {
      const a = r.getStack();
      (!a || !a.members.length) && this.safeToRemove();
    }))), null;
  }
  componentDidUpdate() {
    const { projection: e } = this.props.visualElement;
    e && (e.root.didUpdate(), queueMicrotask(() => {
      !e.currentAnimation && e.isLead() && this.safeToRemove();
    }));
  }
  componentWillUnmount() {
    const { visualElement: e, layoutGroup: n, switchLayoutGroup: s } = this.props, { projection: i } = e;
    i && (i.scheduleCheckAfterUnmount(), n && n.group && n.group.remove(i), s && s.deregister && s.deregister(i));
  }
  safeToRemove() {
    const { safeToRemove: e } = this.props;
    e && e();
  }
  render() {
    return null;
  }
}
function Ai(t) {
  const [e, n] = _a(), s = j(Ps);
  return gs.createElement(Xa, { ...t, layoutGroup: s, switchLayoutGroup: j(Ts), isPresent: e, safeToRemove: n });
}
const qa = {
  borderRadius: {
    ...Vt,
    applyTo: [
      "borderTopLeftRadius",
      "borderTopRightRadius",
      "borderBottomLeftRadius",
      "borderBottomRightRadius"
    ]
  },
  borderTopLeftRadius: Vt,
  borderTopRightRadius: Vt,
  borderBottomLeftRadius: Vt,
  borderBottomRightRadius: Vt,
  boxShadow: Ya
}, wi = ["TopLeft", "TopRight", "BottomLeft", "BottomRight"], Za = wi.length, _n = (t) => typeof t == "string" ? parseFloat(t) : t, Yn = (t) => typeof t == "number" || v.test(t);
function Qa(t, e, n, s, i, o) {
  i ? (t.opacity = C(
    0,
    // TODO Reinstate this if only child
    n.opacity !== void 0 ? n.opacity : 1,
    Ja(s)
  ), t.opacityExit = C(e.opacity !== void 0 ? e.opacity : 1, 0, tc(s))) : o && (t.opacity = C(e.opacity !== void 0 ? e.opacity : 1, n.opacity !== void 0 ? n.opacity : 1, s));
  for (let r = 0; r < Za; r++) {
    const a = `border${wi[r]}Radius`;
    let c = Xn(e, a), l = Xn(n, a);
    if (c === void 0 && l === void 0)
      continue;
    c || (c = 0), l || (l = 0), c === 0 || l === 0 || Yn(c) === Yn(l) ? (t[a] = Math.max(C(_n(c), _n(l), s), 0), ($.test(l) || $.test(c)) && (t[a] += "%")) : t[a] = l;
  }
  (e.rotate || n.rotate) && (t.rotate = C(e.rotate || 0, n.rotate || 0, s));
}
function Xn(t, e) {
  return t[e] !== void 0 ? t[e] : t.borderRadius;
}
const Ja = Ci(0, 0.5, Ke), tc = Ci(0.5, 0.95, M);
function Ci(t, e, n) {
  return (s) => s < t ? 0 : s > e ? 1 : n(Rt(t, e, s));
}
function qn(t, e) {
  t.min = e.min, t.max = e.max;
}
function N(t, e) {
  qn(t.x, e.x), qn(t.y, e.y);
}
function Zn(t, e, n, s, i) {
  return t -= e, t = Qt(t, 1 / n, s), i !== void 0 && (t = Qt(t, 1 / i, s)), t;
}
function ec(t, e = 0, n = 1, s = 0.5, i, o = t, r = t) {
  if ($.test(e) && (e = parseFloat(e), e = C(r.min, r.max, e / 100) - r.min), typeof e != "number")
    return;
  let a = C(o.min, o.max, s);
  t === o && (a -= e), t.min = Zn(t.min, e, n, a, i), t.max = Zn(t.max, e, n, a, i);
}
function Qn(t, e, [n, s, i], o, r) {
  ec(t, e[n], e[s], e[i], e.scale, o, r);
}
const nc = ["x", "scaleX", "originX"], sc = ["y", "scaleY", "originY"];
function Jn(t, e, n, s) {
  Qn(t.x, e, nc, n ? n.x : void 0, s ? s.x : void 0), Qn(t.y, e, sc, n ? n.y : void 0, s ? s.y : void 0);
}
function ts(t) {
  return t.translate === 0 && t.scale === 1;
}
function Di(t) {
  return ts(t.x) && ts(t.y);
}
function ic(t, e) {
  return t.x.min === e.x.min && t.x.max === e.x.max && t.y.min === e.y.min && t.y.max === e.y.max;
}
function Mi(t, e) {
  return Math.round(t.x.min) === Math.round(e.x.min) && Math.round(t.x.max) === Math.round(e.x.max) && Math.round(t.y.min) === Math.round(e.y.min) && Math.round(t.y.max) === Math.round(e.y.max);
}
function es(t) {
  return I(t.x) / I(t.y);
}
class rc {
  constructor() {
    this.members = [];
  }
  add(e) {
    Ze(this.members, e), e.scheduleRender();
  }
  remove(e) {
    if (Qe(this.members, e), e === this.prevLead && (this.prevLead = void 0), e === this.lead) {
      const n = this.members[this.members.length - 1];
      n && this.promote(n);
    }
  }
  relegate(e) {
    const n = this.members.findIndex((i) => e === i);
    if (n === 0)
      return !1;
    let s;
    for (let i = n; i >= 0; i--) {
      const o = this.members[i];
      if (o.isPresent !== !1) {
        s = o;
        break;
      }
    }
    return s ? (this.promote(s), !0) : !1;
  }
  promote(e, n) {
    const s = this.lead;
    if (e !== s && (this.prevLead = s, this.lead = e, e.show(), s)) {
      s.instance && s.scheduleRender(), e.scheduleRender(), e.resumeFrom = s, n && (e.resumeFrom.preserveOpacity = !0), s.snapshot && (e.snapshot = s.snapshot, e.snapshot.latestValues = s.animationValues || s.latestValues), e.root && e.root.isUpdating && (e.isLayoutDirty = !0);
      const { crossfade: i } = e.options;
      i === !1 && s.hide();
    }
  }
  exitAnimationComplete() {
    this.members.forEach((e) => {
      const { options: n, resumingFrom: s } = e;
      n.onExitComplete && n.onExitComplete(), s && s.options.onExitComplete && s.options.onExitComplete();
    });
  }
  scheduleRender() {
    this.members.forEach((e) => {
      e.instance && e.scheduleRender(!1);
    });
  }
  /**
   * Clear any leads that have been removed this render to prevent them from being
   * used in future animations and to prevent memory leaks
   */
  removeLeadSnapshot() {
    this.lead && this.lead.snapshot && (this.lead.snapshot = void 0);
  }
}
function ns(t, e, n) {
  let s = "";
  const i = t.x.translate / e.x, o = t.y.translate / e.y;
  if ((i || o) && (s = `translate3d(${i}px, ${o}px, 0) `), (e.x !== 1 || e.y !== 1) && (s += `scale(${1 / e.x}, ${1 / e.y}) `), n) {
    const { rotate: c, rotateX: l, rotateY: u } = n;
    c && (s += `rotate(${c}deg) `), l && (s += `rotateX(${l}deg) `), u && (s += `rotateY(${u}deg) `);
  }
  const r = t.x.scale * e.x, a = t.y.scale * e.y;
  return (r !== 1 || a !== 1) && (s += `scale(${r}, ${a})`), s || "none";
}
const oc = (t, e) => t.depth - e.depth;
class ac {
  constructor() {
    this.children = [], this.isDirty = !1;
  }
  add(e) {
    Ze(this.children, e), this.isDirty = !0;
  }
  remove(e) {
    Qe(this.children, e), this.isDirty = !0;
  }
  forEach(e) {
    this.isDirty && this.children.sort(oc), this.isDirty = !1, this.children.forEach(e);
  }
}
function cc(t, e) {
  const n = performance.now(), s = ({ timestamp: i }) => {
    const o = i - n;
    o >= e && (Y(s), t(o - e));
  };
  return S.read(s, !0), () => Y(s);
}
function lc(t) {
  window.MotionDebug && window.MotionDebug.record(t);
}
function uc(t) {
  return t instanceof SVGElement && t.tagName !== "svg";
}
function hc(t, e, n) {
  const s = O(t) ? t : yt(t);
  return s.start(qe("", s, e, n)), s.animation;
}
const ss = ["", "X", "Y", "Z"], is = 1e3;
let fc = 0;
const ct = {
  type: "projectionFrame",
  totalNodes: 0,
  resolvedTargetDeltas: 0,
  recalculatedProjection: 0
};
function Li({ attachResizeListener: t, defaultParent: e, measureScroll: n, checkIsScrollRoot: s, resetTransform: i }) {
  return class {
    constructor(r = {}, a = e == null ? void 0 : e()) {
      this.id = fc++, this.animationId = 0, this.children = /* @__PURE__ */ new Set(), this.options = {}, this.isTreeAnimating = !1, this.isAnimationBlocked = !1, this.isLayoutDirty = !1, this.isProjectionDirty = !1, this.isSharedProjectionDirty = !1, this.isTransformDirty = !1, this.updateManuallyBlocked = !1, this.updateBlockedByResize = !1, this.isUpdating = !1, this.isSVG = !1, this.needsReset = !1, this.shouldResetTransform = !1, this.treeScale = { x: 1, y: 1 }, this.eventHandlers = /* @__PURE__ */ new Map(), this.hasTreeAnimated = !1, this.updateScheduled = !1, this.checkUpdateFailed = () => {
        this.isUpdating && (this.isUpdating = !1, this.clearAllSnapshots());
      }, this.updateProjection = () => {
        ct.totalNodes = ct.resolvedTargetDeltas = ct.recalculatedProjection = 0, this.nodes.forEach(pc), this.nodes.forEach(Pc), this.nodes.forEach(Tc), this.nodes.forEach(gc), lc(ct);
      }, this.hasProjected = !1, this.isVisible = !0, this.animationProgress = 0, this.sharedNodes = /* @__PURE__ */ new Map(), this.latestValues = r, this.root = a ? a.root || a : this, this.path = a ? [...a.path, a] : [], this.parent = a, this.depth = a ? a.depth + 1 : 0;
      for (let c = 0; c < this.path.length; c++)
        this.path[c].shouldResetTransform = !0;
      this.root === this && (this.nodes = new ac());
    }
    addEventListener(r, a) {
      return this.eventHandlers.has(r) || this.eventHandlers.set(r, new Je()), this.eventHandlers.get(r).add(a);
    }
    notifyListeners(r, ...a) {
      const c = this.eventHandlers.get(r);
      c && c.notify(...a);
    }
    hasListeners(r) {
      return this.eventHandlers.has(r);
    }
    /**
     * Lifecycles
     */
    mount(r, a = this.root.hasTreeAnimated) {
      if (this.instance)
        return;
      this.isSVG = uc(r), this.instance = r;
      const { layoutId: c, layout: l, visualElement: u } = this.options;
      if (u && !u.current && u.mount(r), this.root.nodes.add(this), this.parent && this.parent.children.add(this), a && (l || c) && (this.isLayoutDirty = !0), t) {
        let h;
        const f = () => this.root.updateBlockedByResize = !1;
        t(r, () => {
          this.root.updateBlockedByResize = !0, h && h(), h = cc(f, 250), Ht.hasAnimatedSinceResize && (Ht.hasAnimatedSinceResize = !1, this.nodes.forEach(os));
        });
      }
      c && this.root.registerSharedNode(c, this), this.options.animate !== !1 && u && (c || l) && this.addEventListener("didUpdate", ({ delta: h, hasLayoutChanged: f, hasRelativeTargetChanged: d, layout: m }) => {
        if (this.isTreeAnimationBlocked()) {
          this.target = void 0, this.relativeTarget = void 0;
          return;
        }
        const p = this.options.transition || u.getDefaultTransition() || wc, { onLayoutAnimationStart: x, onLayoutAnimationComplete: b } = u.getProps(), y = !this.targetLayout || !Mi(this.targetLayout, m) || d, g = !f && d;
        if (this.options.layoutRoot || this.resumeFrom && this.resumeFrom.instance || g || f && (y || !this.currentAnimation)) {
          this.resumeFrom && (this.resumingFrom = this.resumeFrom, this.resumingFrom.resumingFrom = void 0), this.setAnimationOrigin(h, g);
          const P = {
            ...mi(p, "layout"),
            onPlay: x,
            onComplete: b
          };
          (u.shouldReduceMotion || this.options.layoutRoot) && (P.delay = 0, P.type = !1), this.startAnimation(P);
        } else
          f || os(this), this.isLead() && this.options.onExitComplete && this.options.onExitComplete();
        this.targetLayout = m;
      });
    }
    unmount() {
      this.options.layoutId && this.willUpdate(), this.root.nodes.remove(this);
      const r = this.getStack();
      r && r.remove(this), this.parent && this.parent.children.delete(this), this.instance = void 0, Y(this.updateProjection);
    }
    // only on the root
    blockUpdate() {
      this.updateManuallyBlocked = !0;
    }
    unblockUpdate() {
      this.updateManuallyBlocked = !1;
    }
    isUpdateBlocked() {
      return this.updateManuallyBlocked || this.updateBlockedByResize;
    }
    isTreeAnimationBlocked() {
      return this.isAnimationBlocked || this.parent && this.parent.isTreeAnimationBlocked() || !1;
    }
    // Note: currently only running on root node
    startUpdate() {
      this.isUpdateBlocked() || (this.isUpdating = !0, this.nodes && this.nodes.forEach(bc), this.animationId++);
    }
    getTransformTemplate() {
      const { visualElement: r } = this.options;
      return r && r.getProps().transformTemplate;
    }
    willUpdate(r = !0) {
      if (this.root.hasTreeAnimated = !0, this.root.isUpdateBlocked()) {
        this.options.onExitComplete && this.options.onExitComplete();
        return;
      }
      if (!this.root.isUpdating && this.root.startUpdate(), this.isLayoutDirty)
        return;
      this.isLayoutDirty = !0;
      for (let u = 0; u < this.path.length; u++) {
        const h = this.path[u];
        h.shouldResetTransform = !0, h.updateScroll("snapshot"), h.options.layoutRoot && h.willUpdate(!1);
      }
      const { layoutId: a, layout: c } = this.options;
      if (a === void 0 && !c)
        return;
      const l = this.getTransformTemplate();
      this.prevTransformTemplateValue = l ? l(this.latestValues, "") : void 0, this.updateSnapshot(), r && this.notifyListeners("willUpdate");
    }
    update() {
      if (this.updateScheduled = !1, this.isUpdateBlocked()) {
        this.unblockUpdate(), this.clearAllSnapshots(), this.nodes.forEach(rs);
        return;
      }
      this.isUpdating || this.nodes.forEach(vc), this.isUpdating = !1, this.nodes.forEach(xc), this.nodes.forEach(dc), this.nodes.forEach(mc), this.clearAllSnapshots();
      const a = performance.now();
      E.delta = nt(0, 1e3 / 60, a - E.timestamp), E.timestamp = a, E.isProcessing = !0, ce.update.process(E), ce.preRender.process(E), ce.render.process(E), E.isProcessing = !1;
    }
    didUpdate() {
      this.updateScheduled || (this.updateScheduled = !0, queueMicrotask(() => this.update()));
    }
    clearAllSnapshots() {
      this.nodes.forEach(yc), this.sharedNodes.forEach(Vc);
    }
    scheduleUpdateProjection() {
      S.preRender(this.updateProjection, !1, !0);
    }
    scheduleCheckAfterUnmount() {
      S.postRender(() => {
        this.isLayoutDirty ? this.root.didUpdate() : this.root.checkUpdateFailed();
      });
    }
    /**
     * Update measurements
     */
    updateSnapshot() {
      this.snapshot || !this.instance || (this.snapshot = this.measure());
    }
    updateLayout() {
      if (!this.instance || (this.updateScroll(), !(this.options.alwaysMeasureLayout && this.isLead()) && !this.isLayoutDirty))
        return;
      if (this.resumeFrom && !this.resumeFrom.instance)
        for (let c = 0; c < this.path.length; c++)
          this.path[c].updateScroll();
      const r = this.layout;
      this.layout = this.measure(!1), this.layoutCorrected = L(), this.isLayoutDirty = !1, this.projectionDelta = void 0, this.notifyListeners("measure", this.layout.layoutBox);
      const { visualElement: a } = this.options;
      a && a.notify("LayoutMeasure", this.layout.layoutBox, r ? r.layoutBox : void 0);
    }
    updateScroll(r = "measure") {
      let a = !!(this.options.layoutScroll && this.instance);
      this.scroll && this.scroll.animationId === this.root.animationId && this.scroll.phase === r && (a = !1), a && (this.scroll = {
        animationId: this.root.animationId,
        phase: r,
        isRoot: s(this.instance),
        offset: n(this.instance)
      });
    }
    resetTransform() {
      if (!i)
        return;
      const r = this.isLayoutDirty || this.shouldResetTransform, a = this.projectionDelta && !Di(this.projectionDelta), c = this.getTransformTemplate(), l = c ? c(this.latestValues, "") : void 0, u = l !== this.prevTransformTemplateValue;
      r && (a || at(this.latestValues) || u) && (i(this.instance, l), this.shouldResetTransform = !1, this.scheduleRender());
    }
    measure(r = !0) {
      const a = this.measurePageBox();
      let c = this.removeElementScroll(a);
      return r && (c = this.removeTransform(c)), Cc(c), {
        animationId: this.root.animationId,
        measuredBox: a,
        layoutBox: c,
        latestValues: {},
        source: this.id
      };
    }
    measurePageBox() {
      const { visualElement: r } = this.options;
      if (!r)
        return L();
      const a = r.measureViewportBox(), { scroll: c } = this.root;
      return c && (J(a.x, c.offset.x), J(a.y, c.offset.y)), a;
    }
    removeElementScroll(r) {
      const a = L();
      N(a, r);
      for (let c = 0; c < this.path.length; c++) {
        const l = this.path[c], { scroll: u, options: h } = l;
        if (l !== this.root && u && h.layoutScroll) {
          if (u.isRoot) {
            N(a, r);
            const { scroll: f } = this.root;
            f && (J(a.x, -f.offset.x), J(a.y, -f.offset.y));
          }
          J(a.x, u.offset.x), J(a.y, u.offset.y);
        }
      }
      return a;
    }
    applyTransform(r, a = !1) {
      const c = L();
      N(c, r);
      for (let l = 0; l < this.path.length; l++) {
        const u = this.path[l];
        !a && u.options.layoutScroll && u.scroll && u !== u.root && gt(c, {
          x: -u.scroll.offset.x,
          y: -u.scroll.offset.y
        }), at(u.latestValues) && gt(c, u.latestValues);
      }
      return at(this.latestValues) && gt(c, this.latestValues), c;
    }
    removeTransform(r) {
      const a = L();
      N(a, r);
      for (let c = 0; c < this.path.length; c++) {
        const l = this.path[c];
        if (!l.instance || !at(l.latestValues))
          continue;
        Le(l.latestValues) && l.updateSnapshot();
        const u = L(), h = l.measurePageBox();
        N(u, h), Jn(a, l.latestValues, l.snapshot ? l.snapshot.layoutBox : void 0, u);
      }
      return at(this.latestValues) && Jn(a, this.latestValues), a;
    }
    setTargetDelta(r) {
      this.targetDelta = r, this.root.scheduleUpdateProjection(), this.isProjectionDirty = !0;
    }
    setOptions(r) {
      this.options = {
        ...this.options,
        ...r,
        crossfade: r.crossfade !== void 0 ? r.crossfade : !0
      };
    }
    clearMeasurements() {
      this.scroll = void 0, this.layout = void 0, this.snapshot = void 0, this.prevTransformTemplateValue = void 0, this.targetDelta = void 0, this.target = void 0, this.isLayoutDirty = !1;
    }
    forceRelativeParentToResolveTarget() {
      this.relativeParent && this.relativeParent.resolvedRelativeTargetAt !== E.timestamp && this.relativeParent.resolveTargetDelta(!0);
    }
    resolveTargetDelta(r = !1) {
      var a;
      const c = this.getLead();
      this.isProjectionDirty || (this.isProjectionDirty = c.isProjectionDirty), this.isTransformDirty || (this.isTransformDirty = c.isTransformDirty), this.isSharedProjectionDirty || (this.isSharedProjectionDirty = c.isSharedProjectionDirty);
      const l = !!this.resumingFrom || this !== c;
      if (!(r || l && this.isSharedProjectionDirty || this.isProjectionDirty || !((a = this.parent) === null || a === void 0) && a.isProjectionDirty || this.attemptToResolveRelativeTarget))
        return;
      const { layout: h, layoutId: f } = this.options;
      if (!(!this.layout || !(h || f))) {
        if (this.resolvedRelativeTargetAt = E.timestamp, !this.targetDelta && !this.relativeTarget) {
          const d = this.getClosestProjectingParent();
          d && d.layout && this.animationProgress !== 1 ? (this.relativeParent = d, this.forceRelativeParentToResolveTarget(), this.relativeTarget = L(), this.relativeTargetOrigin = L(), Dt(this.relativeTargetOrigin, this.layout.layoutBox, d.layout.layoutBox), N(this.relativeTarget, this.relativeTargetOrigin)) : this.relativeParent = this.relativeTarget = void 0;
        }
        if (!(!this.relativeTarget && !this.targetDelta)) {
          if (this.target || (this.target = L(), this.targetWithTransforms = L()), this.relativeTarget && this.relativeTargetOrigin && this.relativeParent && this.relativeParent.target ? (this.forceRelativeParentToResolveTarget(), Ma(this.target, this.relativeTarget, this.relativeParent.target)) : this.targetDelta ? (this.resumingFrom ? this.target = this.applyTransform(this.layout.layoutBox) : N(this.target, this.layout.layoutBox), Vi(this.target, this.targetDelta)) : N(this.target, this.layout.layoutBox), this.attemptToResolveRelativeTarget) {
            this.attemptToResolveRelativeTarget = !1;
            const d = this.getClosestProjectingParent();
            d && !!d.resumingFrom == !!this.resumingFrom && !d.options.layoutScroll && d.target && this.animationProgress !== 1 ? (this.relativeParent = d, this.forceRelativeParentToResolveTarget(), this.relativeTarget = L(), this.relativeTargetOrigin = L(), Dt(this.relativeTargetOrigin, this.target, d.target), N(this.relativeTarget, this.relativeTargetOrigin)) : this.relativeParent = this.relativeTarget = void 0;
          }
          ct.resolvedTargetDeltas++;
        }
      }
    }
    getClosestProjectingParent() {
      if (!(!this.parent || Le(this.parent.latestValues) || bi(this.parent.latestValues)))
        return this.parent.isProjecting() ? this.parent : this.parent.getClosestProjectingParent();
    }
    isProjecting() {
      return !!((this.relativeTarget || this.targetDelta || this.options.layoutRoot) && this.layout);
    }
    calcProjection() {
      var r;
      const a = this.getLead(), c = !!this.resumingFrom || this !== a;
      let l = !0;
      if ((this.isProjectionDirty || !((r = this.parent) === null || r === void 0) && r.isProjectionDirty) && (l = !1), c && (this.isSharedProjectionDirty || this.isTransformDirty) && (l = !1), this.resolvedRelativeTargetAt === E.timestamp && (l = !1), l)
        return;
      const { layout: u, layoutId: h } = this.options;
      if (this.isTreeAnimating = !!(this.parent && this.parent.isTreeAnimating || this.currentAnimation || this.pendingAnimation), this.isTreeAnimating || (this.targetDelta = this.relativeTarget = void 0), !this.layout || !(u || h))
        return;
      N(this.layoutCorrected, this.layout.layoutBox);
      const f = this.treeScale.x, d = this.treeScale.y;
      Ia(this.layoutCorrected, this.treeScale, this.path, c), a.layout && !a.target && (this.treeScale.x !== 1 || this.treeScale.y !== 1) && (a.target = a.layout.layoutBox);
      const { target: m } = a;
      if (!m) {
        this.projectionTransform && (this.projectionDelta = pt(), this.projectionTransform = "none", this.scheduleRender());
        return;
      }
      this.projectionDelta || (this.projectionDelta = pt(), this.projectionDeltaWithTransform = pt());
      const p = this.projectionTransform;
      Ct(this.projectionDelta, this.layoutCorrected, m, this.latestValues), this.projectionTransform = ns(this.projectionDelta, this.treeScale), (this.projectionTransform !== p || this.treeScale.x !== f || this.treeScale.y !== d) && (this.hasProjected = !0, this.scheduleRender(), this.notifyListeners("projectionUpdate", m)), ct.recalculatedProjection++;
    }
    hide() {
      this.isVisible = !1;
    }
    show() {
      this.isVisible = !0;
    }
    scheduleRender(r = !0) {
      if (this.options.scheduleRender && this.options.scheduleRender(), r) {
        const a = this.getStack();
        a && a.scheduleRender();
      }
      this.resumingFrom && !this.resumingFrom.instance && (this.resumingFrom = void 0);
    }
    setAnimationOrigin(r, a = !1) {
      const c = this.snapshot, l = c ? c.latestValues : {}, u = { ...this.latestValues }, h = pt();
      (!this.relativeParent || !this.relativeParent.options.layoutRoot) && (this.relativeTarget = this.relativeTargetOrigin = void 0), this.attemptToResolveRelativeTarget = !a;
      const f = L(), d = c ? c.source : void 0, m = this.layout ? this.layout.source : void 0, p = d !== m, x = this.getStack(), b = !x || x.members.length <= 1, y = !!(p && !b && this.options.crossfade === !0 && !this.path.some(Ac));
      this.animationProgress = 0;
      let g;
      this.mixTargetDelta = (P) => {
        const T = P / 1e3;
        as(h.x, r.x, T), as(h.y, r.y, T), this.setTargetDelta(h), this.relativeTarget && this.relativeTargetOrigin && this.layout && this.relativeParent && this.relativeParent.layout && (Dt(f, this.layout.layoutBox, this.relativeParent.layout.layoutBox), Sc(this.relativeTarget, this.relativeTargetOrigin, f, T), g && ic(this.relativeTarget, g) && (this.isProjectionDirty = !1), g || (g = L()), N(g, this.relativeTarget)), p && (this.animationValues = u, Qa(u, l, this.latestValues, T, y, b)), this.root.scheduleUpdateProjection(), this.scheduleRender(), this.animationProgress = T;
      }, this.mixTargetDelta(this.options.layoutRoot ? 1e3 : 0);
    }
    startAnimation(r) {
      this.notifyListeners("animationStart"), this.currentAnimation && this.currentAnimation.stop(), this.resumingFrom && this.resumingFrom.currentAnimation && this.resumingFrom.currentAnimation.stop(), this.pendingAnimation && (Y(this.pendingAnimation), this.pendingAnimation = void 0), this.pendingAnimation = S.update(() => {
        Ht.hasAnimatedSinceResize = !0, this.currentAnimation = hc(0, is, {
          ...r,
          onUpdate: (a) => {
            this.mixTargetDelta(a), r.onUpdate && r.onUpdate(a);
          },
          onComplete: () => {
            r.onComplete && r.onComplete(), this.completeAnimation();
          }
        }), this.resumingFrom && (this.resumingFrom.currentAnimation = this.currentAnimation), this.pendingAnimation = void 0;
      });
    }
    completeAnimation() {
      this.resumingFrom && (this.resumingFrom.currentAnimation = void 0, this.resumingFrom.preserveOpacity = void 0);
      const r = this.getStack();
      r && r.exitAnimationComplete(), this.resumingFrom = this.currentAnimation = this.animationValues = void 0, this.notifyListeners("animationComplete");
    }
    finishAnimation() {
      this.currentAnimation && (this.mixTargetDelta && this.mixTargetDelta(is), this.currentAnimation.stop()), this.completeAnimation();
    }
    applyTransformsToTarget() {
      const r = this.getLead();
      let { targetWithTransforms: a, target: c, layout: l, latestValues: u } = r;
      if (!(!a || !c || !l)) {
        if (this !== r && this.layout && l && Ri(this.options.animationType, this.layout.layoutBox, l.layoutBox)) {
          c = this.target || L();
          const h = I(this.layout.layoutBox.x);
          c.x.min = r.target.x.min, c.x.max = c.x.min + h;
          const f = I(this.layout.layoutBox.y);
          c.y.min = r.target.y.min, c.y.max = c.y.min + f;
        }
        N(a, c), gt(a, u), Ct(this.projectionDeltaWithTransform, this.layoutCorrected, a, u);
      }
    }
    registerSharedNode(r, a) {
      this.sharedNodes.has(r) || this.sharedNodes.set(r, new rc()), this.sharedNodes.get(r).add(a);
      const l = a.options.initialPromotionConfig;
      a.promote({
        transition: l ? l.transition : void 0,
        preserveFollowOpacity: l && l.shouldPreserveFollowOpacity ? l.shouldPreserveFollowOpacity(a) : void 0
      });
    }
    isLead() {
      const r = this.getStack();
      return r ? r.lead === this : !0;
    }
    getLead() {
      var r;
      const { layoutId: a } = this.options;
      return a ? ((r = this.getStack()) === null || r === void 0 ? void 0 : r.lead) || this : this;
    }
    getPrevLead() {
      var r;
      const { layoutId: a } = this.options;
      return a ? (r = this.getStack()) === null || r === void 0 ? void 0 : r.prevLead : void 0;
    }
    getStack() {
      const { layoutId: r } = this.options;
      if (r)
        return this.root.sharedNodes.get(r);
    }
    promote({ needsReset: r, transition: a, preserveFollowOpacity: c } = {}) {
      const l = this.getStack();
      l && l.promote(this, c), r && (this.projectionDelta = void 0, this.needsReset = !0), a && this.setOptions({ transition: a });
    }
    relegate() {
      const r = this.getStack();
      return r ? r.relegate(this) : !1;
    }
    resetRotation() {
      const { visualElement: r } = this.options;
      if (!r)
        return;
      let a = !1;
      const { latestValues: c } = r;
      if ((c.rotate || c.rotateX || c.rotateY || c.rotateZ) && (a = !0), !a)
        return;
      const l = {};
      for (let u = 0; u < ss.length; u++) {
        const h = "rotate" + ss[u];
        c[h] && (l[h] = c[h], r.setStaticValue(h, 0));
      }
      r.render();
      for (const u in l)
        r.setStaticValue(u, l[u]);
      r.scheduleRender();
    }
    getProjectionStyles(r = {}) {
      var a, c;
      const l = {};
      if (!this.instance || this.isSVG)
        return l;
      if (this.isVisible)
        l.visibility = "";
      else
        return { visibility: "hidden" };
      const u = this.getTransformTemplate();
      if (this.needsReset)
        return this.needsReset = !1, l.opacity = "", l.pointerEvents = Wt(r.pointerEvents) || "", l.transform = u ? u(this.latestValues, "") : "none", l;
      const h = this.getLead();
      if (!this.projectionDelta || !this.layout || !h.target) {
        const p = {};
        return this.options.layoutId && (p.opacity = this.latestValues.opacity !== void 0 ? this.latestValues.opacity : 1, p.pointerEvents = Wt(r.pointerEvents) || ""), this.hasProjected && !at(this.latestValues) && (p.transform = u ? u({}, "") : "none", this.hasProjected = !1), p;
      }
      const f = h.animationValues || h.latestValues;
      this.applyTransformsToTarget(), l.transform = ns(this.projectionDeltaWithTransform, this.treeScale, f), u && (l.transform = u(f, l.transform));
      const { x: d, y: m } = this.projectionDelta;
      l.transformOrigin = `${d.origin * 100}% ${m.origin * 100}% 0`, h.animationValues ? l.opacity = h === this ? (c = (a = f.opacity) !== null && a !== void 0 ? a : this.latestValues.opacity) !== null && c !== void 0 ? c : 1 : this.preserveOpacity ? this.latestValues.opacity : f.opacityExit : l.opacity = h === this ? f.opacity !== void 0 ? f.opacity : "" : f.opacityExit !== void 0 ? f.opacityExit : 0;
      for (const p in zt) {
        if (f[p] === void 0)
          continue;
        const { correct: x, applyTo: b } = zt[p], y = l.transform === "none" ? f[p] : x(f[p], h);
        if (b) {
          const g = b.length;
          for (let P = 0; P < g; P++)
            l[b[P]] = y;
        } else
          l[p] = y;
      }
      return this.options.layoutId && (l.pointerEvents = h === this ? Wt(r.pointerEvents) || "" : "none"), l;
    }
    clearSnapshot() {
      this.resumeFrom = this.snapshot = void 0;
    }
    // Only run on root
    resetTree() {
      this.root.nodes.forEach((r) => {
        var a;
        return (a = r.currentAnimation) === null || a === void 0 ? void 0 : a.stop();
      }), this.root.nodes.forEach(rs), this.root.sharedNodes.clear();
    }
  };
}
function dc(t) {
  t.updateLayout();
}
function mc(t) {
  var e;
  const n = ((e = t.resumeFrom) === null || e === void 0 ? void 0 : e.snapshot) || t.snapshot;
  if (t.isLead() && t.layout && n && t.hasListeners("didUpdate")) {
    const { layoutBox: s, measuredBox: i } = t.layout, { animationType: o } = t.options, r = n.source !== t.layout.source;
    o === "size" ? U((h) => {
      const f = r ? n.measuredBox[h] : n.layoutBox[h], d = I(f);
      f.min = s[h].min, f.max = f.min + d;
    }) : Ri(o, n.layoutBox, s) && U((h) => {
      const f = r ? n.measuredBox[h] : n.layoutBox[h], d = I(s[h]);
      f.max = f.min + d, t.relativeTarget && !t.currentAnimation && (t.isProjectionDirty = !0, t.relativeTarget[h].max = t.relativeTarget[h].min + d);
    });
    const a = pt();
    Ct(a, s, n.layoutBox);
    const c = pt();
    r ? Ct(c, t.applyTransform(i, !0), n.measuredBox) : Ct(c, s, n.layoutBox);
    const l = !Di(a);
    let u = !1;
    if (!t.resumeFrom) {
      const h = t.getClosestProjectingParent();
      if (h && !h.resumeFrom) {
        const { snapshot: f, layout: d } = h;
        if (f && d) {
          const m = L();
          Dt(m, n.layoutBox, f.layoutBox);
          const p = L();
          Dt(p, s, d.layoutBox), Mi(m, p) || (u = !0), h.options.layoutRoot && (t.relativeTarget = p, t.relativeTargetOrigin = m, t.relativeParent = h);
        }
      }
    }
    t.notifyListeners("didUpdate", {
      layout: s,
      snapshot: n,
      delta: c,
      layoutDelta: a,
      hasLayoutChanged: l,
      hasRelativeTargetChanged: u
    });
  } else if (t.isLead()) {
    const { onExitComplete: s } = t.options;
    s && s();
  }
  t.options.transition = void 0;
}
function pc(t) {
  ct.totalNodes++, t.parent && (t.isProjecting() || (t.isProjectionDirty = t.parent.isProjectionDirty), t.isSharedProjectionDirty || (t.isSharedProjectionDirty = !!(t.isProjectionDirty || t.parent.isProjectionDirty || t.parent.isSharedProjectionDirty)), t.isTransformDirty || (t.isTransformDirty = t.parent.isTransformDirty));
}
function gc(t) {
  t.isProjectionDirty = t.isSharedProjectionDirty = t.isTransformDirty = !1;
}
function yc(t) {
  t.clearSnapshot();
}
function rs(t) {
  t.clearMeasurements();
}
function vc(t) {
  t.isLayoutDirty = !1;
}
function xc(t) {
  const { visualElement: e } = t.options;
  e && e.getProps().onBeforeLayoutMeasure && e.notify("BeforeLayoutMeasure"), t.resetTransform();
}
function os(t) {
  t.finishAnimation(), t.targetDelta = t.relativeTarget = t.target = void 0, t.isProjectionDirty = !0;
}
function Pc(t) {
  t.resolveTargetDelta();
}
function Tc(t) {
  t.calcProjection();
}
function bc(t) {
  t.resetRotation();
}
function Vc(t) {
  t.removeLeadSnapshot();
}
function as(t, e, n) {
  t.translate = C(e.translate, 0, n), t.scale = C(e.scale, 1, n), t.origin = e.origin, t.originPoint = e.originPoint;
}
function cs(t, e, n, s) {
  t.min = C(e.min, n.min, s), t.max = C(e.max, n.max, s);
}
function Sc(t, e, n, s) {
  cs(t.x, e.x, n.x, s), cs(t.y, e.y, n.y, s);
}
function Ac(t) {
  return t.animationValues && t.animationValues.opacityExit !== void 0;
}
const wc = {
  duration: 0.45,
  ease: [0.4, 0, 0.1, 1]
}, ls = (t) => typeof navigator < "u" && navigator.userAgent.toLowerCase().includes(t), us = ls("applewebkit/") && !ls("chrome/") ? Math.round : M;
function hs(t) {
  t.min = us(t.min), t.max = us(t.max);
}
function Cc(t) {
  hs(t.x), hs(t.y);
}
function Ri(t, e, n) {
  return t === "position" || t === "preserve-aspect" && !De(es(e), es(n), 0.2);
}
const Dc = Li({
  attachResizeListener: (t, e) => z(t, "resize", e),
  measureScroll: () => ({
    x: document.documentElement.scrollLeft || document.body.scrollLeft,
    y: document.documentElement.scrollTop || document.body.scrollTop
  }),
  checkIsScrollRoot: () => !0
}), xe = {
  current: void 0
}, Bi = Li({
  measureScroll: (t) => ({
    x: t.scrollLeft,
    y: t.scrollTop
  }),
  defaultParent: () => {
    if (!xe.current) {
      const t = new Dc({});
      t.mount(window), t.setOptions({ layoutScroll: !0 }), xe.current = t;
    }
    return xe.current;
  },
  resetTransform: (t, e) => {
    t.style.transform = e !== void 0 ? e : "none";
  },
  checkIsScrollRoot: (t) => window.getComputedStyle(t).position === "fixed"
}), Mc = {
  pan: {
    Feature: Ka
  },
  drag: {
    Feature: za,
    ProjectionNode: Bi,
    MeasureLayout: Ai
  }
}, Lc = /var\((--[a-zA-Z0-9-_]+),? ?([a-zA-Z0-9 ()%#.,-]+)?\)/;
function Rc(t) {
  const e = Lc.exec(t);
  if (!e)
    return [,];
  const [, n, s] = e;
  return [n, s];
}
const Bc = 4;
function Be(t, e, n = 1) {
  G(n <= Bc, `Max CSS variable fallback depth detected in property "${t}". This may indicate a circular fallback dependency.`);
  const [s, i] = Rc(t);
  if (!s)
    return;
  const o = window.getComputedStyle(e).getPropertyValue(s);
  if (o) {
    const r = o.trim();
    return pi(r) ? parseFloat(r) : r;
  } else
    return Te(i) ? Be(i, e, n + 1) : i;
}
function Ec(t, { ...e }, n) {
  const s = t.current;
  if (!(s instanceof Element))
    return { target: e, transitionEnd: n };
  n && (n = { ...n }), t.values.forEach((i) => {
    const o = i.get();
    if (!Te(o))
      return;
    const r = Be(o, s);
    r && i.set(r);
  });
  for (const i in e) {
    const o = e[i];
    if (!Te(o))
      continue;
    const r = Be(o, s);
    r && (e[i] = r, n || (n = {}), n[i] === void 0 && (n[i] = o));
  }
  return { target: e, transitionEnd: n };
}
const Fc = /* @__PURE__ */ new Set([
  "width",
  "height",
  "top",
  "left",
  "right",
  "bottom",
  "x",
  "y",
  "translateX",
  "translateY"
]), Ei = (t) => Fc.has(t), kc = (t) => Object.keys(t).some(Ei), Gt = (t) => t === ht || t === v, fs = (t, e) => parseFloat(t.split(", ")[e]), ds = (t, e) => (n, { transform: s }) => {
  if (s === "none" || !s)
    return 0;
  const i = s.match(/^matrix3d\((.+)\)$/);
  if (i)
    return fs(i[1], e);
  {
    const o = s.match(/^matrix\((.+)\)$/);
    return o ? fs(o[1], t) : 0;
  }
}, jc = /* @__PURE__ */ new Set(["x", "y", "z"]), Oc = Bt.filter((t) => !jc.has(t));
function Ic(t) {
  const e = [];
  return Oc.forEach((n) => {
    const s = t.getValue(n);
    s !== void 0 && (e.push([n, s.get()]), s.set(n.startsWith("scale") ? 1 : 0));
  }), e.length && t.render(), e;
}
const vt = {
  // Dimensions
  width: ({ x: t }, { paddingLeft: e = "0", paddingRight: n = "0" }) => t.max - t.min - parseFloat(e) - parseFloat(n),
  height: ({ y: t }, { paddingTop: e = "0", paddingBottom: n = "0" }) => t.max - t.min - parseFloat(e) - parseFloat(n),
  top: (t, { top: e }) => parseFloat(e),
  left: (t, { left: e }) => parseFloat(e),
  bottom: ({ y: t }, { top: e }) => parseFloat(e) + (t.max - t.min),
  right: ({ x: t }, { left: e }) => parseFloat(e) + (t.max - t.min),
  // Transform
  x: ds(4, 13),
  y: ds(5, 14)
};
vt.translateX = vt.x;
vt.translateY = vt.y;
const Nc = (t, e, n) => {
  const s = e.measureViewportBox(), i = e.current, o = getComputedStyle(i), { display: r } = o, a = {};
  r === "none" && e.setStaticValue("display", t.display || "block"), n.forEach((l) => {
    a[l] = vt[l](s, o);
  }), e.render();
  const c = e.measureViewportBox();
  return n.forEach((l) => {
    const u = e.getValue(l);
    u && u.jump(a[l]), t[l] = vt[l](c, o);
  }), t;
}, Uc = (t, e, n = {}, s = {}) => {
  e = { ...e }, s = { ...s };
  const i = Object.keys(e).filter(Ei);
  let o = [], r = !1;
  const a = [];
  if (i.forEach((c) => {
    const l = t.getValue(c);
    if (!t.hasValue(c))
      return;
    let u = n[c], h = bt(u);
    const f = e[c];
    let d;
    if (_t(f)) {
      const m = f.length, p = f[0] === null ? 1 : 0;
      u = f[p], h = bt(u);
      for (let x = p; x < m && f[x] !== null; x++)
        d ? G(bt(f[x]) === d, "All keyframes must be of the same type") : (d = bt(f[x]), G(d === h || Gt(h) && Gt(d), "Keyframes must be of the same dimension as the current value"));
    } else
      d = bt(f);
    if (h !== d)
      if (Gt(h) && Gt(d)) {
        const m = l.get();
        typeof m == "string" && l.set(parseFloat(m)), typeof f == "string" ? e[c] = parseFloat(f) : Array.isArray(f) && d === v && (e[c] = f.map(parseFloat));
      } else
        h != null && h.transform && (d != null && d.transform) && (u === 0 || f === 0) ? u === 0 ? l.set(d.transform(u)) : e[c] = h.transform(f) : (r || (o = Ic(t), r = !0), a.push(c), s[c] = s[c] !== void 0 ? s[c] : e[c], l.jump(f));
  }), a.length) {
    const c = a.indexOf("height") >= 0 ? window.pageYOffset : null, l = Nc(e, t, a);
    return o.length && o.forEach(([u, h]) => {
      t.getValue(u).set(h);
    }), t.render(), ee && c !== null && window.scrollTo({ top: c }), { target: l, transitionEnd: s };
  } else
    return { target: e, transitionEnd: s };
};
function $c(t, e, n, s) {
  return kc(e) ? Uc(t, e, n, s) : { target: e, transitionEnd: s };
}
const Gc = (t, e, n, s) => {
  const i = Ec(t, e, s);
  return e = i.target, s = i.transitionEnd, $c(t, e, n, s);
}, Ee = { current: null }, Fi = { current: !1 };
function Wc() {
  if (Fi.current = !0, !!ee)
    if (window.matchMedia) {
      const t = window.matchMedia("(prefers-reduced-motion)"), e = () => Ee.current = t.matches;
      t.addListener(e), e();
    } else
      Ee.current = !1;
}
function Hc(t, e, n) {
  const { willChange: s } = e;
  for (const i in e) {
    const o = e[i], r = n[i];
    if (O(o))
      t.addValue(i, o), Zt(s) && s.add(i), process.env.NODE_ENV === "development" && tn(o.version === "10.16.4", `Attempting to mix Framer Motion versions ${o.version} with 10.16.4 may not work as expected.`);
    else if (O(r))
      t.addValue(i, yt(o, { owner: t })), Zt(s) && s.remove(i);
    else if (r !== o)
      if (t.hasValue(i)) {
        const a = t.getValue(i);
        !a.hasAnimated && a.set(o);
      } else {
        const a = t.getStaticValue(i);
        t.addValue(i, yt(a !== void 0 ? a : o, { owner: t }));
      }
  }
  for (const i in n)
    e[i] === void 0 && t.removeValue(i);
  return e;
}
const ms = /* @__PURE__ */ new WeakMap(), ki = Object.keys(Lt), zc = ki.length, ps = [
  "AnimationStart",
  "AnimationComplete",
  "Update",
  "BeforeLayoutMeasure",
  "LayoutMeasure",
  "LayoutAnimationStart",
  "LayoutAnimationComplete"
], Kc = Oe.length;
class _c {
  constructor({ parent: e, props: n, presenceContext: s, reducedMotionConfig: i, visualState: o }, r = {}) {
    this.current = null, this.children = /* @__PURE__ */ new Set(), this.isVariantNode = !1, this.isControllingVariants = !1, this.shouldReduceMotion = null, this.values = /* @__PURE__ */ new Map(), this.features = {}, this.valueSubscriptions = /* @__PURE__ */ new Map(), this.prevMotionValues = {}, this.events = {}, this.propEventSubscriptions = {}, this.notifyUpdate = () => this.notify("Update", this.latestValues), this.render = () => {
      this.current && (this.triggerBuild(), this.renderInstance(this.current, this.renderState, this.props.style, this.projection));
    }, this.scheduleRender = () => S.render(this.render, !1, !0);
    const { latestValues: a, renderState: c } = o;
    this.latestValues = a, this.baseTarget = { ...a }, this.initialValues = n.initial ? { ...a } : {}, this.renderState = c, this.parent = e, this.props = n, this.presenceContext = s, this.depth = e ? e.depth + 1 : 0, this.reducedMotionConfig = i, this.options = r, this.isControllingVariants = se(n), this.isVariantNode = xs(n), this.isVariantNode && (this.variantChildren = /* @__PURE__ */ new Set()), this.manuallyAnimateOnMount = !!(e && e.current);
    const { willChange: l, ...u } = this.scrapeMotionValuesFromProps(n, {});
    for (const h in u) {
      const f = u[h];
      a[h] !== void 0 && O(f) && (f.set(a[h], !1), Zt(l) && l.add(h));
    }
  }
  /**
   * This method takes React props and returns found MotionValues. For example, HTML
   * MotionValues will be found within the style prop, whereas for Three.js within attribute arrays.
   *
   * This isn't an abstract method as it needs calling in the constructor, but it is
   * intended to be one.
   */
  scrapeMotionValuesFromProps(e, n) {
    return {};
  }
  mount(e) {
    this.current = e, ms.set(e, this), this.projection && !this.projection.instance && this.projection.mount(e), this.parent && this.isVariantNode && !this.isControllingVariants && (this.removeFromVariantTree = this.parent.addVariantChild(this)), this.values.forEach((n, s) => this.bindToMotionValue(s, n)), Fi.current || Wc(), this.shouldReduceMotion = this.reducedMotionConfig === "never" ? !1 : this.reducedMotionConfig === "always" ? !0 : Ee.current, process.env.NODE_ENV !== "production" && tn(this.shouldReduceMotion !== !0, "You have Reduced Motion enabled on your device. Animations may not appear as expected."), this.parent && this.parent.children.add(this), this.update(this.props, this.presenceContext);
  }
  unmount() {
    ms.delete(this.current), this.projection && this.projection.unmount(), Y(this.notifyUpdate), Y(this.render), this.valueSubscriptions.forEach((e) => e()), this.removeFromVariantTree && this.removeFromVariantTree(), this.parent && this.parent.children.delete(this);
    for (const e in this.events)
      this.events[e].clear();
    for (const e in this.features)
      this.features[e].unmount();
    this.current = null;
  }
  bindToMotionValue(e, n) {
    const s = ut.has(e), i = n.on("change", (r) => {
      this.latestValues[e] = r, this.props.onUpdate && S.update(this.notifyUpdate, !1, !0), s && this.projection && (this.projection.isTransformDirty = !0);
    }), o = n.on("renderRequest", this.scheduleRender);
    this.valueSubscriptions.set(e, () => {
      i(), o();
    });
  }
  sortNodePosition(e) {
    return !this.current || !this.sortInstanceNodePosition || this.type !== e.type ? 0 : this.sortInstanceNodePosition(this.current, e.current);
  }
  loadFeatures({ children: e, ...n }, s, i, o) {
    let r, a;
    if (process.env.NODE_ENV !== "production" && i && s) {
      const c = "You have rendered a `motion` component within a `LazyMotion` component. This will break tree shaking. Import and render a `m` component instead.";
      n.ignoreStrict ? kt(!1, c) : G(!1, c);
    }
    for (let c = 0; c < zc; c++) {
      const l = ki[c], { isEnabled: u, Feature: h, ProjectionNode: f, MeasureLayout: d } = Lt[l];
      f && (r = f), u(n) && (!this.features[l] && h && (this.features[l] = new h(this)), d && (a = d));
    }
    if (!this.projection && r) {
      this.projection = new r(this.latestValues, this.parent && this.parent.projection);
      const { layoutId: c, layout: l, drag: u, dragConstraints: h, layoutScroll: f, layoutRoot: d } = n;
      this.projection.setOptions({
        layoutId: c,
        layout: l,
        alwaysMeasureLayout: !!u || h && dt(h),
        visualElement: this,
        scheduleRender: () => this.scheduleRender(),
        /**
         * TODO: Update options in an effect. This could be tricky as it'll be too late
         * to update by the time layout animations run.
         * We also need to fix this safeToRemove by linking it up to the one returned by usePresence,
         * ensuring it gets called if there's no potential layout animations.
         *
         */
        animationType: typeof l == "string" ? l : "both",
        initialPromotionConfig: o,
        layoutScroll: f,
        layoutRoot: d
      });
    }
    return a;
  }
  updateFeatures() {
    for (const e in this.features) {
      const n = this.features[e];
      n.isMounted ? n.update() : (n.mount(), n.isMounted = !0);
    }
  }
  triggerBuild() {
    this.build(this.renderState, this.latestValues, this.options, this.props);
  }
  /**
   * Measure the current viewport box with or without transforms.
   * Only measures axis-aligned boxes, rotate and skew must be manually
   * removed with a re-render to work.
   */
  measureViewportBox() {
    return this.current ? this.measureInstanceViewportBox(this.current, this.props) : L();
  }
  getStaticValue(e) {
    return this.latestValues[e];
  }
  setStaticValue(e, n) {
    this.latestValues[e] = n;
  }
  /**
   * Make a target animatable by Popmotion. For instance, if we're
   * trying to animate width from 100px to 100vw we need to measure 100vw
   * in pixels to determine what we really need to animate to. This is also
   * pluggable to support Framer's custom value types like Color,
   * and CSS variables.
   */
  makeTargetAnimatable(e, n = !0) {
    return this.makeTargetAnimatableFromInstance(e, this.props, n);
  }
  /**
   * Update the provided props. Ensure any newly-added motion values are
   * added to our map, old ones removed, and listeners updated.
   */
  update(e, n) {
    (e.transformTemplate || this.props.transformTemplate) && this.scheduleRender(), this.prevProps = this.props, this.props = e, this.prevPresenceContext = this.presenceContext, this.presenceContext = n;
    for (let s = 0; s < ps.length; s++) {
      const i = ps[s];
      this.propEventSubscriptions[i] && (this.propEventSubscriptions[i](), delete this.propEventSubscriptions[i]);
      const o = e["on" + i];
      o && (this.propEventSubscriptions[i] = this.on(i, o));
    }
    this.prevMotionValues = Hc(this, this.scrapeMotionValuesFromProps(e, this.prevProps), this.prevMotionValues), this.handleChildMotionValue && this.handleChildMotionValue();
  }
  getProps() {
    return this.props;
  }
  /**
   * Returns the variant definition with a given name.
   */
  getVariant(e) {
    return this.props.variants ? this.props.variants[e] : void 0;
  }
  /**
   * Returns the defined default transition on this component.
   */
  getDefaultTransition() {
    return this.props.transition;
  }
  getTransformPagePoint() {
    return this.props.transformPagePoint;
  }
  getClosestVariantNode() {
    return this.isVariantNode ? this : this.parent ? this.parent.getClosestVariantNode() : void 0;
  }
  getVariantContext(e = !1) {
    if (e)
      return this.parent ? this.parent.getVariantContext() : void 0;
    if (!this.isControllingVariants) {
      const s = this.parent ? this.parent.getVariantContext() || {} : {};
      return this.props.initial !== void 0 && (s.initial = this.props.initial), s;
    }
    const n = {};
    for (let s = 0; s < Kc; s++) {
      const i = Oe[s], o = this.props[i];
      (Mt(o) || o === !1) && (n[i] = o);
    }
    return n;
  }
  /**
   * Add a child visual element to our set of children.
   */
  addVariantChild(e) {
    const n = this.getClosestVariantNode();
    if (n)
      return n.variantChildren && n.variantChildren.add(e), () => n.variantChildren.delete(e);
  }
  /**
   * Add a motion value and bind it to this visual element.
   */
  addValue(e, n) {
    n !== this.values.get(e) && (this.removeValue(e), this.bindToMotionValue(e, n)), this.values.set(e, n), this.latestValues[e] = n.get();
  }
  /**
   * Remove a motion value and unbind any active subscriptions.
   */
  removeValue(e) {
    this.values.delete(e);
    const n = this.valueSubscriptions.get(e);
    n && (n(), this.valueSubscriptions.delete(e)), delete this.latestValues[e], this.removeValueFromRenderState(e, this.renderState);
  }
  /**
   * Check whether we have a motion value for this key
   */
  hasValue(e) {
    return this.values.has(e);
  }
  getValue(e, n) {
    if (this.props.values && this.props.values[e])
      return this.props.values[e];
    let s = this.values.get(e);
    return s === void 0 && n !== void 0 && (s = yt(n, { owner: this }), this.addValue(e, s)), s;
  }
  /**
   * If we're trying to animate to a previously unencountered value,
   * we need to check for it in our state and as a last resort read it
   * directly from the instance (which might have performance implications).
   */
  readValue(e) {
    var n;
    return this.latestValues[e] !== void 0 || !this.current ? this.latestValues[e] : (n = this.getBaseTargetFromProps(this.props, e)) !== null && n !== void 0 ? n : this.readValueFromInstance(this.current, e, this.options);
  }
  /**
   * Set the base target to later animate back to. This is currently
   * only hydrated on creation and when we first read a value.
   */
  setBaseTarget(e, n) {
    this.baseTarget[e] = n;
  }
  /**
   * Find the base target for a value thats been removed from all animation
   * props.
   */
  getBaseTarget(e) {
    var n;
    const { initial: s } = this.props, i = typeof s == "string" || typeof s == "object" ? (n = ze(this.props, s)) === null || n === void 0 ? void 0 : n[e] : void 0;
    if (s && i !== void 0)
      return i;
    const o = this.getBaseTargetFromProps(this.props, e);
    return o !== void 0 && !O(o) ? o : this.initialValues[e] !== void 0 && i === void 0 ? void 0 : this.baseTarget[e];
  }
  on(e, n) {
    return this.events[e] || (this.events[e] = new Je()), this.events[e].add(n);
  }
  notify(e, ...n) {
    this.events[e] && this.events[e].notify(...n);
  }
}
class ji extends _c {
  sortInstanceNodePosition(e, n) {
    return e.compareDocumentPosition(n) & 2 ? 1 : -1;
  }
  getBaseTargetFromProps(e, n) {
    return e.style ? e.style[n] : void 0;
  }
  removeValueFromRenderState(e, { vars: n, style: s }) {
    delete n[e], delete s[e];
  }
  makeTargetAnimatableFromInstance({ transition: e, transitionEnd: n, ...s }, { transformValues: i }, o) {
    let r = ha(s, e || {}, this);
    if (i && (n && (n = i(n)), s && (s = i(s)), r && (r = i(r))), o) {
      la(this, s, r);
      const a = Gc(this, s, r, n);
      n = a.transitionEnd, s = a.target;
    }
    return {
      transition: e,
      transitionEnd: n,
      ...s
    };
  }
}
function Yc(t) {
  return window.getComputedStyle(t);
}
class Xc extends ji {
  readValueFromInstance(e, n) {
    if (ut.has(n)) {
      const s = Xe(n);
      return s && s.default || 0;
    } else {
      const s = Yc(e), i = (Ss(n) ? s.getPropertyValue(n) : s[n]) || 0;
      return typeof i == "string" ? i.trim() : i;
    }
  }
  measureInstanceViewportBox(e, { transformPagePoint: n }) {
    return Si(e, n);
  }
  build(e, n, s, i) {
    Ne(e, n, s, i.transformTemplate);
  }
  scrapeMotionValuesFromProps(e, n) {
    return He(e, n);
  }
  handleChildMotionValue() {
    this.childSubscription && (this.childSubscription(), delete this.childSubscription);
    const { children: e } = this.props;
    O(e) && (this.childSubscription = e.on("change", (n) => {
      this.current && (this.current.textContent = `${n}`);
    }));
  }
  renderInstance(e, n, s, i) {
    Ls(e, n, s, i);
  }
}
class qc extends ji {
  constructor() {
    super(...arguments), this.isSVGTag = !1;
  }
  getBaseTargetFromProps(e, n) {
    return e[n];
  }
  readValueFromInstance(e, n) {
    if (ut.has(n)) {
      const s = Xe(n);
      return s && s.default || 0;
    }
    return n = Rs.has(n) ? n : We(n), e.getAttribute(n);
  }
  measureInstanceViewportBox() {
    return L();
  }
  scrapeMotionValuesFromProps(e, n) {
    return Es(e, n);
  }
  build(e, n, s, i) {
    $e(e, n, s, this.isSVGTag, i.transformTemplate);
  }
  renderInstance(e, n, s, i) {
    Bs(e, n, s, i);
  }
  mount(e) {
    this.isSVGTag = Ge(e.tagName), super.mount(e);
  }
}
const Zc = (t, e) => Ie(t) ? new qc(e, { enableHardwareAcceleration: !1 }) : new Xc(e, { enableHardwareAcceleration: !0 }), Qc = {
  layout: {
    ProjectionNode: Bi,
    MeasureLayout: Ai
  }
}, Jc = {
  ...Aa,
  ...Kr,
  ...Mc,
  ...Qc
}, nl = /* @__PURE__ */ tr((t, e) => Er(t, e, Jc, Zc));
export {
  Ps as L,
  ke as P,
  Vr as a,
  S as f,
  G as i,
  nl as m,
  zi as u
};
