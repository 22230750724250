import { useEffect } from 'react';
import { getTrackingEmail, setTrackingEmail } from 'services/recordEvent';
import { captureError } from 'services/errors';
import { poller } from 'utils/poller';

type ThirdPartyEventHandlerProps = React.PropsWithChildren;

type WisepopsFormElements = {
  email: HTMLInputElement;
} & HTMLFormControlsCollection;

type WisepopsForm = {
  readonly elements: WisepopsFormElements;
} & HTMLFormElement;

const MAX_RETRIES = 50;

const useWisepopsFormSubmitHandler = () => {
  useEffect(() => {
    const checkIfWisepopsEnabled = () =>
      poller({
        retries: MAX_RETRIES,
        execute: () => {
          window.wisepops?.('listen', 'after-form-submit', (event: SubmitEvent) => {
            const trackingEmail = getTrackingEmail();

            if (!trackingEmail) {
              const wisepopsEmail = (event.target as WisepopsForm).elements.email.value;

              if (wisepopsEmail) {
                setTrackingEmail(wisepopsEmail);
              }
            }

            return Promise.resolve();
          });
        },
        until: () => !!window.wisepops,
      });

    try {
      checkIfWisepopsEnabled();
    } catch (err) {
      captureError(err);
    }
  }, []);
};

const ThirdPartyEventHandler = ({ children }: ThirdPartyEventHandlerProps) => {
  useWisepopsFormSubmitHandler();

  return children;
};

export { ThirdPartyEventHandler };
export type { ThirdPartyEventHandlerProps };
