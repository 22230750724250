import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';

type UseQueryParamsPaginationProps = {
  perPage: number;
  path?: string;
};

const PAGE_URL_REGEX = /^(\/[^?]+)/;

const useQueryParamsPagination = ({ perPage, path }: UseQueryParamsPaginationProps) => {
  const { push, asPath } = useRouter();
  const searchParams = useSearchParams();

  const pageFromParams = Number(searchParams?.get('page'));
  const [page, setPage] = useState(pageFromParams || 1);

  const matchedPath = asPath.match(PAGE_URL_REGEX);
  const pathname = matchedPath ? matchedPath[0] : path;

  const changePage = useCallback(
    (currentPage: number) => {
      setPage(currentPage);
      push({
        pathname,
        query: { page: currentPage, perPage },
      });
    },
    [perPage, push, pathname],
  );

  const currentPage = pageFromParams || page || 1;
  const pageIndexToFetch = currentPage - 1;

  return {
    pageIndexToFetch,
    currentPage,
    changePage,
  };
};

export { useQueryParamsPagination };
