import { j as y } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as _ } from "react";
import { c as V } from "./chunks/clsx.0d472180.js";
import { g as a, a as C } from "./chunks/utils.60e8c32c.js";
import { u as n } from "./chunks/styles.294d3f14.js";
import "./chunks/lodash.14d7cb6d.js";
const k = {
  horizontal: "row",
  vertical: "column",
  horizontalReversed: "row-reverse",
  verticalReversed: "column-reverse"
}, R = {
  flexStart: "flex-start",
  flexEnd: "flex-end",
  center: "center",
  stretch: "stretch"
}, b = {
  flexStart: "flex-start",
  flexEnd: "flex-end",
  center: "center",
  stretch: "stretch",
  spaceBetween: "space-between",
  spaceAround: "space-around"
}, t = (e) => (s) => e[s], r = _(
  ({
    as: e = "div",
    type: s = "vertical",
    mainAxisAlign: i = "flexStart",
    crossAxisAlign: l = "flexStart",
    gap: o = 1,
    wrap: c = !1,
    className: p,
    children: f,
    style: m,
    ...u
  }, d) => {
    const A = a(o), g = a(s, t(k)), x = a(
      i,
      t(b)
    ), S = a(
      l,
      t(R)
    ), v = C([
      { name: "stackGap", config: A },
      { name: "stackType", config: g },
      { name: "stackMainAxisAlign", config: x },
      { name: "stackCrossAxisAlign", config: S }
    ]);
    return /* @__PURE__ */ y.jsx(
      e,
      {
        ref: d,
        className: V(
          n.root,
          {
            [n.wrap]: c
          },
          p
        ),
        style: {
          ...m,
          ...v
        },
        ...u,
        children: f
      }
    );
  }
);
try {
  r.displayName = "Stack", r.__docgenInfo = { description: "", displayName: "Stack", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, type: { defaultValue: { value: "vertical" }, description: "", name: "type", required: !1, type: { name: "ValueOrResponsiveConfig<StackDirection>" } }, mainAxisAlign: { defaultValue: { value: "flexStart" }, description: "", name: "mainAxisAlign", required: !1, type: { name: "ValueOrResponsiveConfig<MainAxisAlign>" } }, crossAxisAlign: { defaultValue: { value: "flexStart" }, description: "", name: "crossAxisAlign", required: !1, type: { name: "ValueOrResponsiveConfig<CrossAxisAlign>" } }, gap: { defaultValue: null, description: `Space between items multiplied by **$sizeBaseline** value.

Can be numeric value OR responsive configuration object, based on mobile-first order.`, name: "gap", required: !1, type: { name: "ValueOrResponsiveConfig" } }, wrap: { defaultValue: { value: !1 }, description: "", name: "wrap", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  r as Stack
};
