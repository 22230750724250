import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Button, Text, Stack, Modal, IconButton, ModalTitle } from '@carvertical/ui';
import { AnimatePresence, type AnimationProps, motion } from 'framer-motion';
import { ArrowLeftIconM } from '@carvertical/icons/react';
import VinWhatIllustration from './assets/vin-what.svg';
import VinWhereIllustration from './assets/vin-where.svg';
import VinHowIllustration from './assets/vin-how.svg';
import styles from './VinExplanation.module.scss';

type VinExplanationProps = {
  open?: boolean;
  onClose: () => void;
};

type Explanation = 'what' | 'where' | 'how';
type NavigationType = 'forward' | 'back';

const EXPLANATIONS: Explanation[] = ['what', 'where', 'how'];

const ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
  transition: { ease: 'easeOut', duration: 0.2 },
  variants: {
    hidden: (type: NavigationType) => ({
      x: type === 'forward' ? '50%' : '-50%',
      opacity: 0,
    }),
    visible: {
      x: 0,
      opacity: 1,
    },
    exit: (type: NavigationType) => ({
      x: type === 'back' ? '50%' : '-50%',
      opacity: 0,
    }),
  },
};

const VinExplanation = ({ open, onClose }: VinExplanationProps) => {
  const { t } = useTranslation();
  const [explanation, setExplanation] = useState<Explanation>('what');
  const [navigationType, setNavigationType] = useState('forward');

  const Illustration = {
    what: VinWhatIllustration,
    where: VinWhereIllustration,
    how: VinHowIllustration,
  }[explanation];

  const currentIndex = EXPLANATIONS.indexOf(explanation);
  const nextIndex = currentIndex + 1;
  const isLast = currentIndex >= EXPLANATIONS.length - 1;

  const goBack = () => {
    setNavigationType('back');
    setExplanation(EXPLANATIONS[currentIndex - 1]);
  };

  const goNext = () => {
    if (isLast) {
      onClose();
    } else {
      setNavigationType('forward');
      setExplanation(EXPLANATIONS[nextIndex]);
    }
  };

  useEffect(() => {
    if (open) {
      setExplanation('what');
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      boxClassName={styles.root}
      contentClassName={styles.content}
      size="s"
      footer={
        <Stack
          type="horizontal"
          crossAxisAlign="center"
          mainAxisAlign="spaceBetween"
          className={styles.footer}
        >
          <Button
            variant="black"
            trailingIcon={!isLast ? 'arrow-right' : undefined}
            onClick={goNext}
          >
            {!isLast ? t(`vin.${EXPLANATIONS[nextIndex]}Question`) : t('general.closeAction')}
          </Button>

          <Text variant="xs+">
            {currentIndex + 1}/{EXPLANATIONS.length}
          </Text>
        </Stack>
      }
    >
      {currentIndex > 0 && (
        <IconButton
          icon={ArrowLeftIconM}
          label={t('general.backAction')}
          variant="transparent"
          onClick={goBack}
          size="s"
          className={styles.backButton}
        />
      )}

      <Stack
        as={AnimatePresence}
        // @ts-expect-error TS(2322)
        initial={false}
        mode="wait"
        custom={navigationType}
      >
        <Stack
          as={motion.div}
          key={currentIndex}
          // @ts-expect-error TS(2322)
          custom={navigationType}
          crossAxisAlign="center"
          {...ANIMATION_PROPS}
        >
          <Illustration className={styles.illustration} />

          <ModalTitle>{t(`vin.${explanation}Question`)}</ModalTitle>

          <Stack gap={2}>
            {t(`vin.${explanation}Answer`)
              .split('\n')
              .map((line) => (
                <Text key={line} as="p" variant="m">
                  {line}
                </Text>
              ))}
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export { VinExplanation };
export type { VinExplanationProps };
