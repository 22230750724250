import { l as c } from "./lodash.14d7cb6d.js";
const U = (s, o = (t) => t) => {
  const t = typeof s == "object" ? s : { mobileUp: s }, { mobileUp: p } = t, a = t.tabletPortraitUp || t.mobileUp, e = t.tabletLandscapeUp || a, n = t.desktopUp || e, l = t.desktopBigUp || n, i = { ...t, ...{
    mobileUp: p,
    tabletPortraitUp: a,
    tabletLandscapeUp: e,
    desktopUp: n,
    desktopBigUp: l
  } };
  return c.mapValues(i, o);
}, d = (s) => s.reduce(
  (o, { name: t, config: p }) => ({
    ...o,
    ...c.mapKeys(p, (a, e) => `--${t}${c.upperFirst(e)}`)
  }),
  {}
);
export {
  d as a,
  U as g
};
