import { isEmpty } from 'lodash';
import qs from 'querystringify';
import type { Report } from 'modules/reports';
import storageKeys from '../constants/storageKeys';
import { createInstance } from './store';

type LicensePlate = { number: string };

type PrecheckStateProps = {
  vin?: string;
  countryState?: string;
  vehicleName?: string;
  existingReport?: Report;
  licensePlate?: LicensePlate;
  recordCount?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  spec?: any;
};

const store = createInstance('session');
const storageKey = storageKeys.precheckState;

function get() {
  const state: PrecheckStateProps | undefined = store.get(storageKey);

  return state && !isEmpty(state) ? state : undefined;
}

function getQueryString() {
  const state = get();

  const query: {
    state?: string;
    vin?: string;
  } = {};

  if (state?.countryState) {
    query.state = state.countryState;
  }

  if (state?.vin) {
    query.vin = state.vin;
  }

  return state ? qs.stringify(query) : '';
}

function set(state: PrecheckStateProps) {
  store.set(storageKey, JSON.stringify(state));
}

function remove() {
  store.remove(storageKey);
}

const PrecheckState = {
  get,
  set,
  remove,
  getQueryString,
};

export { PrecheckState, type PrecheckStateProps };
